import React from "react";
import { Header } from "../components/employerLanding/Header";
import Comparison from "../components/employerLanding/Comparison";
import { Categories } from "../components/portal/Categories";
import Parameters from "../components/employerLanding/Parameters";
import { Footer } from "../components/Footer";
import EmployerNavbar from "../components/NavBar/EmployerNavbar";
import Plans from "../components/employerLanding/Plans";
import Features from "../components/employerLanding/Features";
import Categoriess from "../components/portal/Categoriess";
import Challenges from "../components/portal/Challenges";
import DemoTutorial from "../components/employerLanding/DemoTutorial";

export default function EmployerLanding() {
  return (
    <div className="bg-gray-100">
      <EmployerNavbar />
      <Header />
      <DemoTutorial />
      <Comparison />
      <Plans />
      <Features />
      <Categoriess />
      <Challenges />
      <Parameters />
      <Footer showBookingSlot={true} />
    </div>
  );
}
