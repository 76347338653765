import React, { useState } from 'react';

const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border rounded-md shadow-md mb-4">
      <div
        className="px-4 py-3 bg-white cursor-pointer"
        onClick={toggleAccordion}
      >
        <div className="flex justify-between items-center">
          <span className="font-semibold">{title}</span>
          <span className={`transform ${isOpen ? 'rotate-0' : 'rotate-180'} transition-transform duration-250 text-gray-600`}>
            &#9651;
          </span>
        </div>
      </div>
      {isOpen && (
        <div className="p-4">{children}</div>
      )}
    </div>
  );
};

export default Accordion;
