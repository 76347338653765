// NotificationComponent.js
import React, { useState, useEffect } from 'react';
import bell from "../../images/bell.png";
import message from "../../images/message2.png";

const sampleNotifications = [
    {
        id: 1,
        image: message,
        title: 'Notification 1 title',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        timestamp: '2022-02-08 12:34:56',
        seen: false,
    },
    {
        id: 2,
        image: message,
        title: 'Notification 2 title',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        timestamp: '2022-02-08 12:35:00',
        seen: true,
    },
    {
        id: 3,
        image: message,
        title: 'Notification 3 title',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        timestamp: '2022-02-08 12:35:00',
        seen: false,
    },
    {
        id: 4,
        image: message,
        title: 'Notification 4 title',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        timestamp: '2022-02-08 12:35:00',
        seen: true,
    },
    {
        id: 5,
        image: message,
        title: 'Notification 5 title',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        timestamp: '2022-02-08 12:35:00',
        seen: false,
    },
    // Add more notifications as needed
];

const NotificationComponent = () => {
    const [showNotifications, setShowNotifications] = useState(false);
    const [notificationCount, setNotificationCount] = useState(0);

    useEffect(() => {
        setNotificationCount(sampleNotifications.filter((notification) => !notification.seen).length);
    }, [sampleNotifications]);

    const toggleNotifications = () => {
        if (showNotifications) {
            // If notifications are being closed, reset the count and mark all notifications as seen
            setNotificationCount(0);
            sampleNotifications.forEach((notification) => {
                notification.seen = true;
            });
        } else {
            // If notifications are being opened, count only unseen notifications
            setNotificationCount(sampleNotifications.filter((notification) => !notification.seen).length);
        }
    
        // Toggle the visibility of notifications
        setShowNotifications(!showNotifications);
    };

    return (
        <div className="relative">
            <button className="p-4" onClick={toggleNotifications}>
                <div className="relative">
                    <img
                        src={bell}
                        alt="Bell Icon"
                        className="h-6 w-6 text-gray-600"
                    />
                    {notificationCount > 0 && (
                        <div className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center">
                            {notificationCount}
                        </div>
                    )}
                </div>
            </button>
            {showNotifications && (
                <div className="absolute top-0 right-0 mt-10 mr-4 p-4 bg-white border shadow-lg w-64 lg:w-96">
                    <div className="flex items-center">
                        <h2 className="text-lg font-semibold">Notifications</h2>
                    </div>
                    <div className="border-b-2 my-2"></div>
                    {sampleNotifications.map((notification) => (
                        <div
                            key={notification.id}
                            className={`flex items-center mb-2 ${notification.seen ? 'bg-gray-100' : ''} rounded shadow-sm transition-transform transform hover:scale-105 hover:shadow-lg cursor-pointer border-2 p-1`}
                        >
                            <img
                                src={notification.image}
                                alt="User Avatar"
                                className="h-6 w-4 rounded-full mr-4"
                            />
                            <div>
                                <p className="font-semibold">{notification.title}</p>
                                <p className="text-sm text-gray-600">{notification.description}</p>
                                <p className="text-xs text-gray-400">{notification.timestamp}</p>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default NotificationComponent;
