import React, { useState, useEffect } from "react";
import "../customCss/landing.css";
import { Link } from "react-router-dom";
import YouTube from "../YouTube";
import image from "../../images/nn (1).png";

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const youtubeVideoId = "rxq16QfcCuM";

  const openModal = () => {
    setIsModalOpen(true);
    //console.log("Open MOdal CLicked");
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="bg-blue-50">
      <div
        className={`flex flex-col items-center justify-center h-[38rem] relative ${
          windowWidth <= 768 ? "" : "backgroundImage"
        }`}
      >
        <div className=" m-4 py-2 px-3 bg-blue-100 text-blue-800 rounded-full text-sm  flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            id="job"
            className="w-6 h-6 mr-2"
          >
            <path
              fill="#a78966"
              d="M58,51H6a2,2,0,0,1-2-2V23H60V49A2,2,0,0,1,58,51Z"
            ></path>
            <path
              fill="#d9ccbc"
              d="M61,14v9H3V14a2.006,2.006,0,0,1,2-2H59A2.006,2.006,0,0,1,61,14Z"
            ></path>
            <rect width="20" height="6" x="22" y="3" fill="#acabb1"></rect>
            <rect width="6" height="6" x="45" y="21" fill="#e0e0e2"></rect>
            <rect width="6" height="6" x="13" y="21" fill="#e0e0e2"></rect>
            <path fill="#787680" d="M61,36V57a4,4,0,0,1-8,0V36Z"></path>
            <path
              fill="#e0e0e2"
              d="M57,61H23a3,3,0,0,1-3-3V29H53V57A4,4,0,0,0,57,61Z"
            ></path>
            <rect width="10" height="8" x="24" y="48" fill="#2488ff"></rect>
            <rect width="25" height="4" x="24" y="32" fill="#ffda44"></rect>
            <path
              fill="#231f20"
              d="M62,23V14a3.009,3.009,0,0,0-3-3H47V6a1,1,0,0,0-1-1H43V3a1,1,0,0,0-1-1H22a1,1,0,0,0-1,1V5H18a1,1,0,0,0-1,1v5H5a3.009,3.009,0,0,0-3,3v9a1,1,0,0,0,1,1V49a3.009,3.009,0,0,0,3,3H19v6a4,4,0,0,0,4,4H57a5,5,0,0,0,5-5V36a1,1,0,0,0-1-1V24A1,1,0,0,0,62,23ZM23,4H41V8H23ZM19,7h2V9a1,1,0,0,0,1,1H42a1,1,0,0,0,1-1V7h2v4H19Zm1,21a1,1,0,0,0-1,1V50H6a1,1,0,0,1-1-1V24h7v3a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V24H44v3a1,1,0,0,0,1,1Zm-6-2V22h4v4Zm9,34a2.006,2.006,0,0,1-2-2V30H52V57a4.924,4.924,0,0,0,1.03,3ZM46,26V22h4v4Zm7,2H51a1,1,0,0,0,1-1V24h7V35H54V29A1,1,0,0,0,53,28Zm7,29a3,3,0,0,1-6,0V37h2V48h2V37h2Zm0-35H52V21a1,1,0,0,0-1-1H45a1,1,0,0,0-1,1v1H20V21a1,1,0,0,0-1-1H13a1,1,0,0,0-1,1v1H4V14a1,1,0,0,1,1-1H59a1,1,0,0,1,1,1Z"
            ></path>
            <rect width="2" height="2" x="56" y="50" fill="#231f20"></rect>
            <path
              fill="#231f20"
              d="M49,31H24a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H49a1,1,0,0,0,1-1V32A1,1,0,0,0,49,31Zm-1,4H25V33H48Z"
            ></path>
            <rect width="10" height="2" x="23" y="39" fill="#231f20"></rect>
            <rect width="15" height="2" x="35" y="39" fill="#231f20"></rect>
            <rect width="4" height="2" x="23" y="43" fill="#231f20"></rect>
            <rect width="21" height="2" x="29" y="43" fill="#231f20"></rect>
            <path
              fill="#231f20"
              d="M34,47H24a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1H34a1,1,0,0,0,1-1V48A1,1,0,0,0,34,47Zm-1,8H25V49h8Z"
            ></path>
            <rect width="13" height="2" x="37" y="47" fill="#231f20"></rect>
            <rect width="4" height="2" x="37" y="51" fill="#231f20"></rect>
            <rect width="7" height="2" x="43" y="51" fill="#231f20"></rect>
          </svg>
          <span className="font-bold">AI-Powered Job Platform</span>
        </div>
        <h1 className="text-6xl main-heading font-bold  text-center text-black mt-2">
          Search, Apply &amp;
        </h1>
        <h1 className="text-6xl main-heading font-bold text-center text-black mb-6">
          Get Your <span className="text-blue-600"> Dream Job</span>
        </h1>
        <p className="text-sm text-black mb-2 font-bold bg-white rounded-3xl p-1 px-2">
          Find the best job opportunities.
        </p>
        <p className="text-sm text-black mb-8 font-bold bg-white rounded-3xl p-1 px-2">
          Join us and make your career shine.
        </p>
        <div className="flex items-center space-x-4">
          <button className="px-4 py-2 transition ease-in-out delay-150 text-white font-semibold rounded-md bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-blue-200 duration-300">
            <Link to="/jobs">Search Jobs</Link>
          </button>
          <div className="flex space-x-2 items-center">
            <div>
              <button
                className="p-2 transition ease-in-out delay-150 rounded-full bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-blue-200 duration-300"
                onClick={openModal}
              >
                {/* YouTube Play Button Icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 3l14 9-14 9V3z"
                  />
                </svg>
              </button>
              {isModalOpen && (
                <YouTube videoId={youtubeVideoId} onClose={closeModal} />
              )}
            </div>
            
            <span className="text-black text-sm font-semibold bg-white rounded-3xl p-1 px-2">
              How it works?
            </span>
          </div>
        </div>
      </div>
      {windowWidth <= 768 && (
        <img
          src={image}
          alt="Mobile Background"
          className="object-cover h-80 mx-auto block"
        />
      )}
    </div>
  );
};

export default Header;
