import favicon from "../images/favicon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faYoutube, faTelegram } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

export const Footer = ({ showBookingSlot }) => {
  return (
    <div className="px-4 pt-8  mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
      <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
        <div className="sm:col-span-2">
          <Link
            to="/"
            aria-label="Go home"
            title="Company"
            className="inline-flex items-center"
          >
            <img src={favicon} alt="Logo" className="h-11 w-11" />
            <span className="ml-2 text-xl font-bold tracking-wide text-gray-800 font-custom">
              Hireme<span className="text-blue-500">Club</span>
            </span>
          </Link>
          <div className="flex flex-col text-lg font-semibold ml-12">  
            <Link
              to="/policy"
              aria-label="Policy Rules"
              title="Policy Rules"
              className="transition-colors duration-300 text-blue-700 hover:text-blue-800 cursor-pointer"
            >
            Privacy Policy
            </Link>
            <Link
              to="/tnc"
              aria-label="Terms & Conditions"
              title="Terms & Conditions"
              className="transition-colors duration-300 text-blue-700 hover:text-blue-800 cursor-pointer"
            >
            Terms & Conditions
            </Link>
            <Link
              to="/refunds"
              aria-label="Refund Policy"
              title="Refund Policy"
              className="transition-colors duration-300 text-blue-700 hover:text-blue-800 cursor-pointer"
            >
            Refund Policy
            </Link>
          </div> 
        </div>
        <div className="space-y-2 text-sm">
          <p className="text-base font-bold tracking-wide text-gray-900">
            Contacts
          </p>
          {showBookingSlot && (
            <div className="my-4  rounded-lg">
              <p className="text-gray-800 font-semibold">
                <span className="font-bold">Want to hire from us?</span><br /> Let's discuss this over a call,<br /><a href="https://calendly.com/hiremeclub" className="text-blue-700 font-bold"> Book on your preferable slot.</a>
              </p>
            </div>
          )}
          <div className="flex flex-col font-semibold">
          <Link
              to="/about-us"
              aria-label="About us"
              title="About us"
              className="transition-colors duration-300 text-blue-700 hover:text-blue-800 cursor-pointer text-lg"
            >
            About us
            </Link>
            <p className="mr-1 text-gray-800 font-semibold">Email:</p>
            <a
              href="mailto:support@hiremeclub.com"
              aria-label="Our email"
              title="Our email"
              className="transition-colors duration-300 text-blue-700 hover:text-blue-800"
            >
              support@hiremeclub.com
            </a>
          </div>
        </div>
        <div>
          <span className="text-base font-bold tracking-wide text-gray-900">
            Social
          </span>
          <div className="flex items-center mt-1 space-x-3">
            <a
              href="https://twitter.com/HiremeClub"
              className="text-gray-500 transition-colors duration-300 hover:text-blue-accent-400"
            >
              <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-5 text-blue-500"
              >
                <path d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z" />
              </svg>
            </a>
            <a
              href="https://www.youtube.com/@rithikrajyt"
              className="text-red-600 transition-colors duration-300 hover:text-blue-accent-400"
            >
              <FontAwesomeIcon icon={faYoutube} size="lg" />
            </a>
            <a
              href="https://instagram.com/hiremeclub"
              className="text-blue-700 transition-colors duration-300 hover:text-blue-accent-400"
            >
              <FontAwesomeIcon icon={faInstagram} size="lg" />
            </a>
            <a
              href="https://www.linkedin.com/company/hiremeclub/"
              className="text-gray-500 transition-colors duration-300 hover:text-blue-accent-400"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-5 w-5 text-blue-800"
              >
                <path d="M20.96 20.96h-4.02v-6.31c0-1.55-0.03-3.54-2.17-3.54c-2.18 0-2.51 1.71-2.51 3.47v6.38h-4.03v-13.76h3.87v1.84h0.06c0.54-1.03 1.88-2.12 3.86-2.12c4.13 0 4.89 2.72 4.89 6.26v7.78z" />
                <path d="M2.08 7.04h4.04v13.76h-4.04zM4.12 2.47c1.34 0 2.43 1.09 2.43 2.43s-1.09 2.43-2.43 2.43s-2.43-1.09-2.43-2.43s1.09-2.43 2.43-2.43z" />
              </svg>
            </a>
          </div>
          <p className="mt-4 text-sm text-gray-500">
            Stay connected with us on social media for the latest updates,
            industry insights, and exciting opportunities in the world of
            AI-driven hiring.
          </p>
        </div>
      </div>
      <div className="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
        <p className="text-sm text-gray-600">
          © Copyright 2023 | Hirepreneurs Technologies Private Limited | All
          rights reserved.
        </p>
        <p>Made with ❤️ in Bharat</p>
      </div>
    </div>
  );
};
