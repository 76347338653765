import React from "react";
import TopNavigation from "../components/NavBar/TopNavigation";
import { Footer } from "../components/Footer";
import aboutUsImage from "../images/6.png";
export default function AboutUs() {
  return (
    <div>
      <TopNavigation />
      <div className="flex">
        <div className="flex-1 w-full flex justify-center flex-col p-6">
          <h1 className="mb-2">
            Hireme<span className="text-blue-500">Club</span>
          </h1>
          <p className="font-medium mb-1">
            HiremeClub is a GenAI-based Hiring portal with pre-screened
            candidates after screening using Artificial Intelligence technology
            on 8 different parameters, creating a verified resume called
            “Proof-of-Work” used by HRs during hiring. It helps in a faster
            hiring process by removing the complete initial screening process.
          </p>
          <p p className="font-medium mb-8">
            A unit of{" "}
            <span className="font-bold">
              &quot;Hirepreneurs Technologies Private Limited, Bengaluru,
              Karnataka - 560 111&quot;
            </span>
          </p>
          <div className="flex flex-nowrap gap-2 items-center mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-mail align-middle"
            >
              <rect width="20" height="16" x="2" y="4" rx="2" />
              <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
            </svg>
            <a href="mailto:support@hiremeclub.com" className="pb-1">
              <em className="font-medium align-middle">
                support@hiremeclub.com
              </em>
            </a>
          </div>
          <div className="flex flex-nowrap gap-2 items-center mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-phone"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
            <a
              href="tel:+916230279417"
              className="pb-1 font-medium align-middle"
            >
              +91 6230279417
            </a>
          </div>
          <div className="flex flex-nowrap gap-2 items-center mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="lucide lucide-map-pin min-w-[20px] min-h-[20px] align-middle"
            >
              <path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z" />
              <circle cx="12" cy="10" r="3" />
            </svg>
            <a
              href="https://www.google.com/maps/search/?api=1&query=1st+Floor,+H+No+39.+2nd+Cross+Road,+Kumaraswamy+Layout,+Bangalore,+Karnataka+-+560111"
              target="_blank"
              rel="noopener noreferrer"
              className="font-medium"
            >
              1st Floor, H No 39. 2nd Cross Road, Kumaraswamy Layout, Bangalore,
              Karnataka - 560 111
            </a>
          </div>
        </div>
        <div className="lg:flex-1 lg:w-full lg:h-full lg:block hidden">
          <img src={aboutUsImage} className="object-contain" />
        </div>
      </div>
      <Footer />
    </div>
  );
}
