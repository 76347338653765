import React from "react";
import TopNavigation from "../components/NavBar/TopNavigation";
import { Footer } from "../components/Footer";

export default function RefundPolicy() {
  return (
    <div>
      <TopNavigation />
      <div className="container mx-auto px-4 py-2 lg:px-8 lg:py-4">
        <h1 className="text-3xl font-bold mb-2 text-blue-500">
          Refund and Cancellation Policy
        </h1>
        <p className="mb-4">
          Welcome to HiremeClub. We understand that plans can change and
          unforeseen circumstances may occur, affecting your use of our
          services. The following Refund and Cancellation Policy outlines the
          terms under which you can request a refund or cancel your use of
          HiremeClub services. This policy is applicable to all users of the
          HiremeClub platform, including job posters and job seekers.
        </p>
        <div className="w-full">
          <ol className="list-decimal lg:ml-6 lg:mb-8 mb-2">
            <li className="mb-6">
              <h2 className="text-xl font-bold mb-2">
                Subscription Cancellation
              </h2>

              <ul>
                <li>
                  <strong>Job Posters:</strong> If you have subscribed to any of
                  HiremeClub&apos;s paid services, you may cancel your
                  subscription at any time. Please note the following:
                </li>
                <li>
                  <strong>Monthly Subscriptions:</strong> You must cancel your
                  subscription at least 48 hours before the next billing cycle
                  to avoid being charged for the next month. Cancellations made
                  less than 48 hours before the billing date will result in a
                  charge for the next month.
                </li>
                <li>
                  <strong>Annual Subscriptions:</strong> For annual
                  subscriptions, you may cancel anytime during the year to
                  prevent renewal in the next year. However, refunds for the
                  remaining months of the current subscription term are not
                  provided.
                </li>
              </ul>
            </li>
            <li className="mb-6">
              <h2 className="text-xl font-bold mb-2">Refunds</h2>
              <ul>
                <li>
                  <strong>General Policy:</strong> All purchases made on
                  HiremeClub are generally non-refundable except as specifically
                  provided in this policy.
                </li>
                <li>
                  <strong>Service Non-performance:</strong> If any of our
                  services fail to perform as advertised, or if you encounter
                  technical issues that prevent the normal use of our service,
                  you may request a pro-rated refund for the unused portion of
                  the service. Please provide adequate details, and allow us to
                  first attempt to resolve the issue.
                </li>
                <li>
                  <strong>Double Payments:</strong> In the case of a billing
                  error or duplicate billing, HiremeClub will investigate the
                  matter and refund the duplicate amount promptly upon
                  verification.
                </li>
              </ul>
            </li>
            <li className="mb-6">
              <h2 className="text-xl font-bold mb-2">Refund Process</h2>
              <ul>
                <li>
                  To request a refund, please contact our customer support team
                  at{" "}
                  <a
                    href="mailto:hritvik@hiremeclub.com"
                    className="font-bold underline"
                  >
                    hritvik@hiremeclub.com
                  </a>{" "}
                  with the details of your issue, your account information, and
                  any relevant documentation supporting your claim.
                </li>
                <li>
                  Our team will review your request and generally respond within
                  7 business days. If your refund request is approved, the
                  amount will be credited back to your original method of
                  payment within 14 business days.
                </li>
              </ul>
            </li>
            <li className="mb-6">
              <h2 className="text-xl font-bold mb-2">Subscription Renewals</h2>
              <p>
                Subscriptions are automatically renewed unless cancelled. If you
                do not wish your subscription to renew automatically, please
                inform us at least 48 hours before your renewal date.
              </p>
            </li>
            <li className="mb-6">
              <h2 className="text-xl font-bold mb-2">
                Cancellations by HiremeClub
              </h2>
              <p>
                HiremeClub reserves the right to suspend or terminate your
                account or specific services at any time without notice if we
                determine that your account activity violates our Terms of
                Service. In such cases, HiremeClub will not refund any fees paid
                in advance for the subscription period during which the user
                account was active before such termination.
              </p>
            </li>
            <li className="mb-6">
              <h2 className="text-xl font-bold mb-2">
                Modifications to Services
              </h2>
              <p>
                HiremeClub reserves the right to modify or discontinue,
                temporarily or permanently, the services (or any part thereof)
                with or without notice. Such notice may be provided at any time
                by posting the changes to the HiremeClub site or the service
                itself.
              </p>
            </li>
            <li className="mb-6">
              <h2 className="text-xl font-bold mb-2">Contact Us</h2>
              <p>
                For any questions or concerns regarding your subscription or
                this Refund and Cancellation Policy, please contact us at{" "}
                <a href="mailto:hritvik@hiremeclub.com">
                  hritvik@hiremeclub.com
                </a>
              </p>
            </li>
          </ol>
          <p className="pb-4 font-semibold text-md">
            By subscribing to and using the services of HiremeClub, you
            acknowledge and agree to this Refund and Cancellation Policy. We
            recommend reviewing this policy periodically as it is subject to
            change at our discretion.
          </p>
          <p className="pb-4 font-semibold text-md">
            Thank you for choosing HiremeClub. We look forward to assisting you
            in finding the right opportunities or the right talent for your
            organization.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
