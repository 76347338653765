// ProjectCard.js
import React from "react";

function ProjectCard({ image, topic, role, onClick, isSelected }) {
  return (
    <div
      className={`flex flex-col justify-center items-center p-3 border-4 hover:border-blue-600 ${
        isSelected ? "border-blue-600" : "border-gray-200"
      } rounded shadow-sm transition-transform transform hover:scale-105 hover:shadow-lg`}
      onClick={() => onClick(role)}
    >
      <div className="w-full h-32 overflow-hidden rounded-t flex items-center justify-center">
        <img src={image} alt={topic} className="w-24 h-full object-contain" />
      </div>
      <div className="px-2 py-1 text-black">
        <div className="font-bold text-sm mb-1">{topic}</div>
      </div>
    </div>
  );
}

export default ProjectCard;
