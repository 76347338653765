import React from "react";
import GreenTickIcon from "./GreenTickIcon";
import RedCrossIcon from "./RedCrossIcon";

const Comparison = () => {
  return (
    <div className="px-4  py-16 mx-auto  sm:max-w-full md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-8">
      <div className="max-w-xl lg:pt-10 mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <div>
          <p className="inline-block px-3 py-px text-sm font-bold tracking-wider text-blue-800 rounded-full bg-blue-100">
            Why HiremeClub?
          </p>
        </div>
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl sm:ml-10 md:mx-auto">
          Comparison
        </h2>
        <p className="text-base text-gray-700 md:text-lg">
          HiremeClub V/S Other Hiring Portals
        </p>
      </div>
      <div className="flex items-center justify-center relative">
        <div className="">
          <table className=" h-[50vh] bg-white rounded-2xl shadow-2xl">
            <thead className="bg-blue-500 text-xl text-white">
              <tr>
                <th colSpan="2" className="py-2 px-4">
                  Features
                </th>
                <th colSpan="2" className="py-2 px-4">
                  HiremeClub
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="py-2 px-4">
                  <RedCrossIcon />
                </td>
                <td className="text-2xl text-gray-800 font-bold">
                  Manual screening process
                </td>
                <td className="py-2 pl-2 bg-blue-50">
                  <GreenTickIcon className="text-green-500" />
                </td>
                <td className="py-2 pl-2 bg-blue-50 text-2xl text-gray-800 font-bold">
                  AI-Proctered Testing
                </td>
              </tr>
              <tr>
                <td className="py-2 px-4">
                  <RedCrossIcon />
                </td>
                <td className="text-2xl text-gray-800 font-bold">
                  Long and hectic screening process
                </td>
                <td className="py-2 pl-2 bg-blue-50">
                  <GreenTickIcon className="text-green-500" />
                </td>
                <td className="py-2 pl-2 bg-blue-50 text-2xl text-gray-800 font-bold">
                  Review candidates' live testing video
                </td>
              </tr>
              <tr>
                <td className="py-2 px-4">
                  <RedCrossIcon />
                </td>
                <td className="text-2xl text-gray-800 font-bold">
                  No guarantee on candidates
                </td>
                <td className="py-2 pl-2 bg-blue-50">
                  <GreenTickIcon className="text-green-500" />
                </td>
                <td className="py-2 pl-2 bg-blue-50 text-2xl text-gray-800 font-bold">
                  15 Days Candidate Back Guarantee
                </td>
              </tr>
              <tr>
                <td className="py-2 px-4">
                  <RedCrossIcon />
                </td>
                <td className="text-2xl text-gray-800 font-bold">
                  Only Resume as a Proof-of-Work
                </td>
                <td className="py-2 pl-2 bg-blue-50">
                  <GreenTickIcon className="text-green-500" />
                </td>
                <td className="py-2 pl-2 bg-blue-50 text-2xl text-gray-800 font-bold">
                  Get result on 8 parameters
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Comparison;
