import React, { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const getCookieAndRole = () => {
      const cookieValue = document.cookie
        .split("; ")
        .find((row) => row.startsWith("token="));
      if (cookieValue) {
        const tokenWithPrefix = cookieValue.split("=")[1];
        if (tokenWithPrefix.length > 0) {
          const role = tokenWithPrefix[0];
          const token = tokenWithPrefix.slice(1); // Remove the prefix
          return { role, token };
        }
      }
      return { role: null, token: null };
    };

    const fetchData = async () => {
      const { token, role } = getCookieAndRole();
      // //console.log(token);
      // //console.log(role);
      if (token) {
        if (role === "0") {
          setUser("userLoggedIn");
        } else if (role === "1") {
          setUser("EmployerLoggedIn");
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const isLoggedIn = Boolean(user);
  const isLoggedOut = !isLoggedIn;

  return (
    <AuthContext.Provider value={{ user, isLoggedIn, isLoggedOut, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
