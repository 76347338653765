import React from "react";
import TopNavigation from "../components/NavBar/TopNavigation";
import { Footer } from "../components/Footer";

export default function PrivacyPolicy() {
  return (
    <div>
      <TopNavigation />
      <div className="container mx-auto px-4 py-2 lg:px-8 lg:py-4">
        <h1 className="font-bold mb-2 text-3xl text-blue-500">
          Privacy Policy
        </h1>
        <p className="mb-4">
          HiremeClub is a commercial product developed by Hirepreneurs
          Technologies Private Limited <strong>("HiremeClub")</strong>. This
          Privacy Policy explains our policies regarding the collection, use,
          and disclosure of personal information from users of our app and
          website <strong>("HiremeClub Platform")</strong>. By using the
          HiremeClub Platform, you consent to the terms of our Privacy Policy.
          We encourage you to review this Privacy Policy regularly to stay
          informed about any changes we may make.
        </p>
        <h2 className="text-2xl font-bold mb-2">
          Collection and Use of Personal Information
        </h2>
        <div className="lg:px-6 px-4">
          <h3 className="text-xl font-bold">
            Personal Identification Information
          </h3>
          <p>
            If you choose to use our Service, you agree to the collection and
            use of information in relation to this policy. The Personal
            Identification Information we collect is used for providing and
            improving the Service. We will not use or share your information
            with anyone except as described in this Privacy Policy.
          </p>
          <p>
            When you create a profile on HiremeClub, certain information is
            public, such as your name, location, gender, profile picture,
            education, and professional information including your current
            employer. This public profile is accessible on HiremeClub and can:
            <ul className="lg:px-4 py-1 px-2">
              <li>Be associated with you on the internet.</li>
              <li>Show up in search engine results.</li>
            </ul>
          </p>
          <p>
            We may collect personal identification information from various
            sources, including social media platforms and other public sources,
            to improve the personalization of our services.
          </p>
          <h3 className="text-lg font-bold mt-4">
            Non-Personal Identification Information
          </h3>
          <p>
            We may collect non-personal identification information when users
            interact with our site. This may include technical information about
            your device, such as the type of mobile phone, operating system, IP
            address, and internet service providers.
          </p>
          <h3 className="text-lg font-bold mt-4">
            Usage and Technical Information
          </h3>
          <p>
            We collect information about how you interact with our Service,
            including IP address, geographical location, browser type, referral
            source, length of visit, pages viewed, and items clicked.
          </p>
        </div>
        <h2 className="text-2xl font-bold mt-6 mb-1">Information Collection</h2>
        <p>
          We collect certain personally identifiable information for your public
          profile, including but not limited to:
        </p>
        <ul className="lg:px-4 py-1 px-2">
          <li>
            Identity information, such as your first name, last name, gender,
            date of birth, and username.
          </li>
          <li>
            Contact information, such as your mobile number, postal address, and
            email address.
          </li>
          <li>
            Professional information, such as your education, work experience,
            skills, salary, and other relevant details.
          </li>
          <li>
            Feedback and correspondence, such as information you provide when
            responding to surveys, receiving customer support, or otherwise
            engaging with us.
          </li>
          <li>
            Marketing information, such as your preferences for receiving
            marketing communications.
          </li>
        </ul>
        <h2 className="text-2xl font-bold mt-6 mb-1">
          Sharing Your Personal Information
        </h2>
        <p>
          We do not sell, trade, or rent users' personal identification
          information to third parties. However, we may share personal
          information with:
        </p>
        <ul className="lg:px-4 py-1 px-2">
          <li>
            Our affiliates and group companies for internal business and
            administrative purposes.
          </li>
          <li>
            Prospective employers and recruiters, if your resume matches their
            requirements.
          </li>
          <li>
            Third parties, including enforcement, regulatory, and judicial
            authorities, if required to respond to legal obligations or defend
            legal claims.
          </li>
          <li>
            Third-party service providers who assist in providing services on
            our behalf.
          </li>
        </ul>
        <h2 className="text-2xl font-bold mt-6 mb-1">
          How We Protect Your Information
        </h2>
        <p>
          We adopt appropriate data collection, storage, and processing
          practices to protect against unauthorized access, alteration,
          disclosure, or destruction of your personal information, username,
          password, and data stored on our platform. However, no method of
          transmission over the internet or electronic storage is 100% secure,
          and we cannot guarantee absolute security.
        </p>
        <h2 className="text-2xl font-bold mt-6 mb-1">
          Changes to This Privacy Policy
        </h2>
        <p>
          We may update our Privacy Policy from time to time. You are advised to
          review this page periodically for any changes. We will notify you of
          any changes by posting the new Privacy Policy on this page. These
          changes are effective immediately upon posting.
        </p>
        <h2 className="text-2xl font-bold mt-6 mb-1">Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or
          your data, please contact us at{" "}
          <a href="mailto:support@hiremeclub.com">
            <em className="font-bold">support@hiremeclub.com</em>
          </a>
          .
        </p>
      </div>
      <Footer />
    </div>
  );
}
