import React from "react";
import seamlessness from "../../images/features/seamlessness.png";
import precision from "../../images/features/precision.png";
import efficency from "../../images/features/efficency.png";
import comprehensive from "../../images/features/comprehensive.png";
export default function Features() {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-full md:max-w-full lg:max-w-screen-xl md:px-14 lg:px-8 lg:py-8">
      <div className="max-w-xl lg:pt-10 mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <div>
          <p className="inline-block px-3 py-px text-sm font-bold tracking-wider text-blue-800 rounded-full bg-blue-100">
            All new
          </p>
        </div>
        <h2 className="max-w-lg mb-3 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl sm:ml-10 md:mx-auto">
          Features
        </h2>
        <p className="text-base text-gray-700 md:text-lg">
          Streamline Hiring with Next-Gen Recruiter Features!
        </p>
      </div>
      <div className="grid grid-cols-1 grid-rows-3 lg:grid-cols-3 lg:grid-rows-2 gap-4 md:grid-cols-2 md:grid-rows-2 items-center">
        <span className="bg-blue-200 lg:bg-blue-100 md:bg-blue-100 lg:col-start-1 lg:col-end-3 md:col-span-1 md:row-span-1 h-full py-4 lg:h-80 rounded-3xl lg:flex lg:flex-row-reverse flex flex-col flex-1 justify-evenly">
          <div className="lg:flex-1 flex justify-center items-center mb-4 lg:m-0 px-4">
            <img
              src={seamlessness}
              alt="seamlessness"
              className="h-[187px] w-auto lg:h-auto hover:scale-105 transition-all duration-500 ease-in-out delay-0"
            />
          </div>
          <div className="flex-1 font-sans flex justify-center lg:pl-8 px-4 py-2 flex-col gap-1 lg:gap-2 w-full">
            <h1 className="text-3xl lg:text-4xl">Seamlessness</h1>
            <p className="font-medium lg:text-lg">
              Conduct virtual interviews seamlessly within the integrated
              HiremeClub platform, simplifying your hiring journey.
            </p>
          </div>
        </span>
        <span className="bg-blue-200 lg:bg-blue-200 md:bg-blue-200 lg:col-start-3 lg:col-end-4 md:col-span-1 md:row-span-1 h-full py-4 lg:h-80 rounded-3xl flex-1 flex flex-col lg:flex-col-reverse justify-evenly">
          <div className="lg:flex-1 flex justify-center items-center mb-4 lg:m-0">
            <img
              src={precision}
              alt="precision"
              className="w-auto max-h-[187px] lg:h-[11rem] lg:w-[11rem] hover:scale-110 transition-all duration-500 ease-in-out delay-0"
            />
          </div>
          <div className="lg:flex-1 font-sans flex justify-center px-4 flex-col gap-1 w-full">
            <h1 className="text-3xl lg:text-4xl">Precision</h1>
            <p className="font-medium lg:text-md">
              Shortlist candidates swiftly based on verified scores, ensuring
              you focus on the best fits.
            </p>
          </div>
        </span>
        <span className="bg-blue-200 lg:bg-blue-200 md:bg-blue-200 lg:col-start-1 lg:col-end-2 md:col-span-1 md:row-span-1 h-full lg:h-80 rounded-3xl flex-1 flex flex-col lg:flex-col-reverse justify-evenly">
          <div className="lg:flex-1 flex justify-center items-center mb-4 lg:m-0">
            <img
              src={efficency}
              alt="efficency"
              className="max-h-[187px] lg:h-auto w-auto hover:scale-110 transition-all duration-500 ease-in-out delay-0"
            />
          </div>
          <div className="lg:flex-1 font-sans flex justify-center lg:pl-8 px-4 flex-col gap-1 w-full">
            <h1 className="text-3xl lg:text-4xl">Efficency</h1>
            <p className="font-medium lg:text-md">
              Streamline your hiring process with bulk interview scheduling in
              just one click.
            </p>
          </div>
        </span>
        <span className="bg-blue-200 lg:bg-blue-100 md:bg-blue-100 lg:col-start-2 lg:col-end-4 md:col-span-1 md:row-span-1 h-full py-4 lg:h-80 rounded-3xl lg:flex lg:flex-row-reverse flex flex-col flex-1 justify-evenly">
          <div className="lg:flex-1 flex justify-center items-center mb-4 lg:m-0 px-4">
            <img
              src={comprehensive}
              alt="comprehensive"
              className="h-[187px] w-auto lg:h-auto hover:scale-105 transition-all duration-500 ease-in-out delay-0"
            />
          </div>
          <div className="lg:flex-1 font-sans flex justify-center lg:pl-8 px-4 flex-col gap-1 lg:gap-2 w-full">
            <h1 className="text-3xl lg:text-4xl">Comprehensive</h1>
            <p className="font-medium lg:text-lg">
              Gain insights into candidates' capabilities with scores across 8
              parameters and video resumes.
            </p>
          </div>
        </span>
      </div>
    </div>
  );
}
