import React, { useState, useRef, useEffect } from "react";
import problem from "../images/problem.png";
import {
  uploadFile,
  getSignedUrl,
  getConfidence,
  aiProctoringFunc,
  bgRemoverFunc,
} from "../service/mlAPI";
import { completedTest, storeTabSwitchesCount } from "../service/api";
import TabSwitch from "../components/portal/TabSwitch";
import { useNavigate, useLocation } from "react-router-dom";
import { MagnifyingGlass } from "react-loader-spinner";
import TestingNavbar from "../components/NavBar/TestingNavbar";
import { useTabSwitch } from "../context/TabSwitchContext";

const mimeType = 'video/webm; codecs="opus,vp8"';

export default function Stage3() {
  const [retakeTaken, setRetakeTaken] = useState(false);
  const location = useLocation();
  const videoRef = useRef(null);
  const liveVideoFeed = useRef(null);
  const mediaRecorder = useRef(null);
  const [url, setUrl] = useState("");
  const [permission, setPermission] = useState(false);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const streamRef = useRef(null);
  const [recordedVideo, setRecordedVideo] = useState(null);
  const [videoChunks, setVideoChunks] = useState([]);
  const [videoBlob, setVideoBlob] = useState(null);
  const [loading, setLoading] = useState(false);
  const randomQuestion = useRef(null);
  const timerIdRef = useRef(null);
  const localVideoChunksRef = useRef([]);
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(60);
  const { count } = useTabSwitch();
  var storeURL;

  const fetchData = async () => {
    await getCameraPermission();
    await startRecording();
  };

  useEffect(() => {
    fetchData();
  }, []);
  const startTimer = () => {
    // Clear any existing timer
    if (timerIdRef.current) {
      clearTimeout(timerIdRef.current);
    }

    // Start a new timer and store its ID
    const newTimerId = setTimeout(() => {
      ////console.log("Timer expired!");
      stopRecording();
    }, 60000);

    timerIdRef.current = newTimerId;
  };

  const resetTimer = () => {
    // Clear the timer if it exists
    if (timerIdRef.current) {
      clearTimeout(timerIdRef.current);
      timerIdRef.current = null; // Reset the timer ID
    }
  };
  //when we get video then upload it to aws
  const AWSUpload = async (Blob, url) => {
    ////console.log("upload function start");
    if (Blob) {
      ////console.log(url);
      await uploadFile(url, Blob);
      setUrl(url.split("?")[0]);
      storeURL = url;
      ////console.log("upload function end");
    }
  };

  const handleRetake = async () => {
    if (!retakeTaken) {
      setRetakeTaken(true);

      setSeconds(60);
      resetTimer();

      mediaRecorder.current.stop();

      setVideoChunks([]);

      await startRecording();
    }
  };

  const getCameraPermission = async () => {
    setRecordedVideo(null);

    if ("MediaRecorder" in window) {
      try {
        const videoConstraints = {
          audio: false,
          video: true,
        };
        const audioConstraints = { audio: true };

        const audioStream = await navigator.mediaDevices.getUserMedia(
          audioConstraints
        );
        const videoStream = await navigator.mediaDevices.getUserMedia(
          videoConstraints
        );

        setPermission(true);

        const combinedStream = new MediaStream([
          ...videoStream.getVideoTracks(),
          ...audioStream.getAudioTracks(),
        ]);

        streamRef.current = combinedStream;

        liveVideoFeed.current.srcObject = videoStream;
      } catch (err) {
        alert(err.message);
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
    }
  };

  const startRecording = async () => {
    startTimer();
    setRecordingStatus("recording");

    const media = new MediaRecorder(streamRef.current, { mimeType });
    mediaRecorder.current = media;
    mediaRecorder.current.start();

    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      localVideoChunksRef.current = [];
      localVideoChunksRef.current.push(event.data);
    };

    setVideoChunks(localVideoChunksRef.current);
  };

  const stopRecording = () => {
    setPermission(false);
    setLoading(true);
    setRecordingStatus("inactive");
    mediaRecorder.current.stop();

    mediaRecorder.current.onstop = async () => {
      const vidBlob = new Blob(localVideoChunksRef.current, { type: mimeType });
      const videoUrl = URL.createObjectURL(vidBlob);

      setRecordedVideo(videoUrl);
      setVideoBlob(vidBlob);
      setVideoChunks([]);
      resetTimer();

      if (streamRef.current) {
        // Stop all the video tracks
        streamRef.current.getVideoTracks().forEach((track) => track.stop());
        // Stop all the audio tracks
        streamRef.current.getAudioTracks().forEach((track) => track.stop());
        streamRef.current = null; // Clear the stream reference
      }

      let token = getCookieAndRole();
      const response = await getSignedUrl("stage_3", token.token);
      await AWSUpload(vidBlob, response.url);
      ////console.log("After the Upload function in Stop recording");
      getConfidence(token.token);
      // getStage3Fluency(token.token);
      aiProctoringFunc(token.token);
      bgRemoverFunc(token.token);
      await completedTest(token.token, storeURL);
      await storeTabSwitchesCount(
        token.token,
        count,
        response.url.split("?")[0]
      );
      navigate("/feedback");
      setLoading(false);
    };
  };

  const getCookieAndRole = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));
    if (cookieValue) {
      const tokenWithPrefix = cookieValue.split("=")[1];
      if (tokenWithPrefix.length > 0) {
        const role = tokenWithPrefix[0];
        const token = tokenWithPrefix.slice(1); // Remove the prefix
        return { role, token };
      }
    }
    return { role: null, token: null };
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevTime) => {
        if (prevTime === 0) {
          // Reset the timer to 0 after 60 seconds
          return 0;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };
  const isBlinking = seconds <= 10 && seconds % 2 === 0;

  return (
    <div>
      {loading ? (
        <div className="magnifying-glass-wrapper flex flex-col items-center justify-center h-screen">
          <MagnifyingGlass
            visible={true}
            height="160"
            width="160"
            ariaLabel="MagnifyingGlass-loading"
            wrapperStyle={{}}
            wrapperClass="MagnifyingGlass-wrapper"
            glassColor="#c0efff"
            color="#2979FF"
          />
        </div>
      ) : (
        <div className="bg-gray-50">
          <TestingNavbar
            showAvatar={true}
            handleLogout={() => navigate("/login")}
          />
          <TabSwitch />
          <div>
            <div className="flex flex-col items-center justify-center bg-gray-50 min-h-fit min-w-full">
              <img
                src={problem}
                alt="Question vector Image"
                className="max-w-min max-h-min ml-8 mt-2"
              />
              {/* <CountdownTimer initialTime={80} className="max-h-fit" /> */}
              <div
                className={`p-2 border-4 rounded-lg text-center ${
                  seconds <= 10
                    ? isBlinking
                      ? "border-red-500 animate-ping"
                      : "border-red-500"
                    : "border-black"
                }`}
              >
                <div className="text-4xl font-bold text-gray-800">
                  {formatTime(seconds)}
                </div>
              </div>

              <div className="bg-white rounded-3xl shadow-2xl p-4 m-10 mt-2">
                <h3 className="text-black p-2 m-2">
                  <div>
                    <p>
                      Make a short video about yourself, focusing on your
                      career, skills, and what sets you apart.
                    </p>
                  </div>
                </h3>
              </div>

              <div className="flex justify-center">
                <video
                  ref={liveVideoFeed}
                  autoPlay
                  className="w-72 h-48  live-player sm:w-3/5 sm:h-auto md:aspect-w-16/9 sm:aspect-h-9/16 bg-gray-500"
                ></video>
              </div>

              <div className="mt-4">
                <button
                  onClick={handleRetake}
                  className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${
                    retakeTaken ? "hidden" : ""
                  }`}
                >
                  Retake
                </button>
              </div>

              <div className="mt-4">
                {recordingStatus === "recording" ? (
                  <button
                    onClick={stopRecording}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  >
                    END TEST
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
