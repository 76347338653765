import React from "react";
import Chart from "react-apexcharts";
function DonutChart(props) {
  const options = props.options;
  const series = props.series;
  return (
    <React.Fragment>
      <Chart
        type="radialBar"
        width={"100%"}
        height="300px"
        series={series}
        options={options}
      />
    </React.Fragment>
  );
}

export default DonutChart;
