import {  useEffect } from "react";
import { useTabSwitch } from "../../context/TabSwitchContext";

const TabSwitch = () => {
  const { incrementCount } = useTabSwitch();

  const handleVisibilityChange = () => {
    alert(
      'Tab Switch Detected! Please note that continuing may result in the exam being closed.'
    );

    // Increment count when tab switch is detected
    incrementCount();
  };

  const handleResize = () => {
    // Check if the window size is less than a certain threshold (e.g., 800x600)
    if (window.innerWidth < 320 || window.innerHeight < 568) { 
      alert(
        'Tab Size Reduced! Please note that continuing may result in the exam being closed.'
      );

      // Increment count when tab size is reduced
      incrementCount();
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('resize', handleResize);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('resize', handleResize);
    };
  }, [incrementCount]);

  return null;
};

export default TabSwitch;