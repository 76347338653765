import React, { useEffect, useRef, useState } from "react";
import { uploadFile } from "../service/mlAPI";
import axios from "axios";
import { toast } from "react-toastify";
import { changePasswordEmployer, getEmployerID } from "../service/api";
import { getSignedUrlLogo } from "../service/api";
import { useNavigate } from "react-router-dom";
import ChangePassword from "../components/dashboard/ChangePassword";
import { getEmployerDetails } from "../service/api";

export default function ResumeData() {
  const API_URI = "https://hiremeclub-backend.azurewebsites.net/api/v1";
  const [url, setUrl] = useState("");
  const navigate = useNavigate();
  const [file, setFile] = useState("");
  const [form, setForm] = useState({
    logoUrl: "",
    name: "",
    designation: "",
    phoneNumber: "",
    brandName: "",
    sector: "",
    companyName: "",
    companySize: "",
    address: "",
  });
  const [isEditable, setIsEditable] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const toggleEdit = () => {
    setIsEditable(!isEditable);
  };

  useEffect(() => {
    const getData = async () => {
      let token = getCookieAndRole();
      const response = await getSignedUrlLogo(token.token);
      // setUrl(response.url);
      setUrl(response.url.split("?")[0]);
      ////console.log(response.url);
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        await uploadFile(url, file);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    };
    file && getData();
  }, [file]);
  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile &&
      (selectedFile.type === "image/png" || selectedFile.type === "image/jpeg")
    ) {
      setFile(selectedFile);
      setForm({ ...form, logoUrl: url });
    } else {
      alert("Please select a Image file.");
      e.target.value = null; // Clear the file input
    }
  };

  const getCookieAndRole = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));
    if (cookieValue) {
      const tokenWithPrefix = cookieValue.split("=")[1];
      if (tokenWithPrefix.length > 0) {
        const role = tokenWithPrefix[0];
        const token = tokenWithPrefix.slice(1); // Remove the prefix
        return { role, token };
      }
    }
    return { role: null, token: null };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await sendEmployerDetails();
    toast.success("Registered Successfully!");
    navigate("/emp-dashboard");
  };

  //to send the employer details to the backend
  const sendEmployerDetails = async () => {
    try {
      let token = getCookieAndRole();
      let user = await getEmployerID(token.token);
      ////console.log(form);
      const response = await axios.post(
        `${API_URI}/Employer/new`,
        {
          ...form,
          user: user,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "https://hiremeclub.com",
          },
        }
      );
      ////console.log(response.data);
      return response.data;
    } catch (error) {
      ////console.log(error.response.data);
    }
  };

  useEffect(() => {
    const getData = async () => {
      let token = getCookieAndRole();
      const response = await getEmployerDetails(token.token);
      const data = response.foundEmployer;
      ////console.log(data);
      setForm({
        ...form,
        name: data.name,
        designation: data.designation,
        phoneNumber: data.phoneNumber,
        brandName: data.brandName,
        sector: data.sector,
        companyName: data.companyName,
        companySize: data.companySize,
        address: data.address,
      });
    };
    getData();
  }, []);

  return (
    <div>
      <div className="bg-gray-50">
        <div className="resume-data">
          <div className="bg-gray-50 min-h-screen py-8 lg:px-36">
            <div className="container mx-auto sm:p-8">
              <h2 className="text-3xl font-semibold mb-4 text-center">
                Employer's Detail
              </h2>
              <form onSubmit={handleSubmit}>
                <div className="border mb-3 bg-white rounded-lg lg:p-5 sm:p-8">
                  {/*Basic Candnameate's Details */}
                  <div className="mb-6 flex space-x-4">
                    <div className="w-full">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium "
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={form.name}
                        disabled
                        onChange={(e) =>
                          setForm({ ...form, name: e.target.value })
                        }
                        className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2 "
                      />
                    </div>

                    <div className="w-full">
                      <label
                        htmlFor="dob"
                        className="block mb-2 text-sm font-medium "
                      >
                        Designation
                      </label>
                      <input
                        type="text"
                        value={form.designation}
                        disabled={!isEditable}
                        onChange={(e) =>
                          setForm({ ...form, designation: e.target.value })
                        }
                        name="designation"
                        className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                      />
                    </div>
                  </div>

                  {/*Contact Information */}
                  <div className="mb-6  flex space-x-4">
                    <div className="w-full">
                      <label
                        htmlFor="phone"
                        className="block mb-2 text-sm font-medium"
                      >
                        Mobile Number
                      </label>
                      <input
                        value={form.phoneNumber}
                        onChange={(e) =>
                          setForm({ ...form, phoneNumber: e.target.value })
                        }
                        type="tel"
                        disabled={!isEditable}
                        name="phoneNumber"
                        className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                      />
                    </div>

                    <div className="w-full">
                      <label
                        htmlFor="brandName"
                        className="block mb-2 text-sm font-medium "
                      >
                        Brand Name
                      </label>
                      <input
                        value={form.brandName}
                        disabled={!isEditable}
                        placeholder="Ex:- Hirepreneurs Technologies Pvt. Ltd."
                        onChange={(e) =>
                          setForm({ ...form, brandName: e.target.value })
                        }
                        type="text"
                        name="brandName"
                        className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                      />
                    </div>
                  </div>
                  <div className="mb-6  flex space-x-4">
                    <div className="w-full">
                      <label
                        htmlFor="sector"
                        className="block mb-2 text-sm font-medium"
                      >
                        Sector
                      </label>
                      <input
                        type="text"
                        disabled={!isEditable}
                        value={form.sector}
                        onChange={(e) =>
                          setForm({ ...form, sector: e.target.value })
                        }
                        name="sector"
                        className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                      />
                    </div>

                    <div className="w-full">
                      <label
                        htmlFor="companyName"
                        className="block mb-2 text-sm font-medium "
                      >
                        Company Name
                      </label>
                      <input
                        type="text"
                        disabled={!isEditable}
                        value={form.companyName}
                        onChange={(e) =>
                          setForm({ ...form, companyName: e.target.value })
                        }
                        name="companyName"
                        className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                      />
                    </div>
                  </div>
                  <div className="mb-6  flex space-x-4">
                    <div className="w-full">
                      <label
                        htmlFor="file"
                        className="block mb-2 text-sm font-medium"
                      >
                        Upload Logo
                      </label>
                      <input
                        disabled
                        onChange={handleImageChange}
                        type="file"
                        name="file"
                        className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                      />
                    </div>

                    <div className="w-full">
                      <label
                        htmlFor="companyName"
                        className="block mb-2 text-sm font-medium "
                      >
                        Company Size
                      </label>
                      <select
                        className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                        value={form.companySize}
                        disabled={!isEditable}
                        onChange={(e) =>
                          setForm({ ...form, companySize: e.target.value })
                        }
                      >
                        <option value="0-10">Less than 10</option>
                        <option value="10-50">10-50</option>
                        <option value="50-100">50-200</option>
                        <option value="100-500">200-500</option>
                        <option value="500-1000">500-1000</option>
                        <option value="1000+">1000+</option>
                      </select>
                    </div>
                  </div>
                  <div className="mb-6  flex space-x-4">
                    <div className="w-full">
                      <label
                        htmlFor="address"
                        className="block mb-2 text-sm font-medium"
                      >
                        Address
                      </label>
                      <textarea
                        type="text"
                        value={form.address}
                        disabled={!isEditable}
                        onChange={(e) =>
                          setForm({ ...form, address: e.target.value })
                        }
                        rows={5}
                        cols={20}
                        name="address"
                        className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-center space-x-4">
                  <button
                    type="button"
                    onClick={toggleEdit}
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    {isEditable ? "Save" : "Edit"}
                  </button>
                  <button
                    type="submit"
                    className="bg-green-500 text-white px-4 py-2 rounded"
                  >
                    Submit
                  </button>
                </div>
              </form>
              <div className="flex items-center justify-center mt-2 bg-gray-50 pb-8">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  onClick={openModal}
                >
                  Change Password
                </button>
                {isModalOpen && (
                  <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-8 max-w-md mx-auto rounded-lg shadow-md">
                      <ChangePassword
                        changePasswordApi={changePasswordEmployer}
                      />

                      <button
                        className=" flex items-center justify-center bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                        onClick={closeModal}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <Footer showBookingSlot={true} /> */}
      </div>
    </div>
  );
}
