import React from 'react'
import marketing from "../../images/marketing.png";
import business from "../../images/business.png";
import sales from "../../images/sales.png";
import hr from "../../images/hr.png";
import social from "../../images/social.png";
import digital from "../../images/digital.png";
import content from "../../images/content.png";
import finance from "../../images/finance.png";
import "../customCss/Categories.css";

function Categoriess() {
    const blocks = [
        { image: marketing, heading: "Marketing Candidates" },
        { image: business, heading: "Business Development Candidates" },
        { image: sales, heading: "Sales Candidates" },
        { image: hr, heading: "Human Resource" },
        { image: social, heading: "Social Media Associate" },
        { image: digital, heading: "Digital Marketing" },
        { image: content, heading: "Content Writer" },
        { image: finance, heading: "Accounts and Finance" },
    ];
    return (
        <div className="px-4 py-16 mx-auto  sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-8 bg-slate-100">
            <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                <div>
                    <p className="inline-block px-3 py-px text-sm font-bold trackng-wider text-blue-800  rounded-full bg-blue-100">
                        Brand New
                    </p>
                </div>
                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                    Categories
                </h2>
                <p className="text-base text-gray-700 md:text-lg">
                    Empower your HR team to hire top talent across diverse student
                    categories with ease from Interns to Freshers, we provide a wide range
                    of candidates to meet your hiring needs. Streamline your recruitment
                    process and find the perfect fit for your organization
                </p>
            </div>
            <div className="flex justify-center items-center h-full">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 ">
            {blocks.map((block, index) => (
                <div key={index} className="flex flex-col justify-center items-center p-4 border-b-4 border-blue-500 hover:border-b-8 rounded-3xl shadow-sm transition-transform transform hover:scale-105 hover:shadow-lg w-36 md:w-44 mt-8 mr-8 md:mb-0 bg-white  ">
                    <div className="w-32 h-32 flex justify-center items-center zoom-rotate-container cursor-pointer">
                        <img src={block.image} alt={block.heading} className="zoom-rotate-img" />
                    </div>
                    <h6 className="mb-2 font-semibold leading-5 text-center p-2 text-gray-700">{block.heading}</h6>
                </div>
            ))}
        </div>
        </div>
        
        </div>
    )
}

export default Categoriess
