import React from "react";
import "../customCss/Challenges.css";
import candidates from "../../images/candidates.svg";
import long from "../../images/long.svg";
import time from "../../images/time.svg";
import unresume from "../../images/unresume.svg";
function Challenges() {
  return (
    <div className="">
      <h1 className="text-center text-gray-600 mt-20 mb-40 text-4xl">
        Are you facing these{" "}
        <span className="mt-2 text-5xl text-blue-500">Challenges?</span>
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 ml-10 mt-10 md:mt-20">
        <div className="card">
          <div className="imgbox">
            <img
              className="rounded-lg shadow-lg w-[200px] h-[180px] object-cover "
              src={long}
              alt="Long And Hectic Shortlisting Process"
            />
            <h2 className="text-center text-gray-600 text-lg lg:text-xl">
              Long And Hectic Shortlisting Process
            </h2>
          </div>

          <div className="content">
            <p className="lg:mb-10 text-base mt-10">
              The challenge of a lengthy and exhausting shortlisting process
              hampers efficiency and delays candidate selection.
            </p>
          </div>
        </div>
        <div className="card">
          <div className="imgbox">
            <img
              className="rounded-lg shadow-lg w-[200px] h-[180px] object-cover "
              src={candidates}
              alt="Irrelevant Candidates"
            />
            <h2 className="text-center text-gray-600 text-lg lg:text-xl">
              Irrelevant Candidates
            </h2>
          </div>

          <div className="content">
            <p className="lg:mb-10 text-base mt-10">
              Dealing with irrelevant candidates adds complexity and increases
              the time spent on finding the right fit for the role.
            </p>
          </div>
        </div>
        <div className="card">
          <div className="imgbox">
            <img
              className="rounded-lg shadow-lg w-[200px] h-[180px] object-cover bg-white "
              src={time}
              alt="Time taking Assessment Process"
            />
            <h2 className="text-center text-gray-600 text-lg lg:text-xl">
              Time taking Assessment Process
            </h2>
          </div>

          <div className="content">
            <p className="lg:mb-10 text-base mt-10">
              Time-consuming assessment processes significantly prolong hiring
              timelines, impacting productivity, agility, and overall
              efficiency.
            </p>
          </div>
        </div>
        <div className="card">
          <div className="imgbox">
            <img
              className="rounded-lg shadow-lg w-[200px] h-[180px] object-cover bg-white"
              src={unresume}
              alt="Unverified Resumes"
            />
            <h2 className="text-center text-gray-600 text-lg lg:text-xl">
              Unverified Resumes
            </h2>
          </div>

          <div className="content">
            <p className="lg:mb-10 text-base mt-10">
              Sorting through unverified resumes poses a risk to the hiring
              process's accuracy and quality, necessitating careful screening
              and verification measures.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Challenges;
