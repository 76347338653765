import React, { useState } from "react";
import axios from "axios";
import { getEmployerID } from "../../service/api";
import { toast } from "react-toastify";
import "../customCss/employer_dashboard.css";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Button from "../tiptap/button";

const API_URI = "https://hiremeclub-backend.azurewebsites.net/api/v1";

const extensions = [StarterKit];

const PostJob = () => {
  const [postJob, setPostJob] = useState({
    jobTitle: "",
    workLocation: "",
    description: "Enter description",
    candidatePreferences: "Enter candidate preferences ",
    noteJD: "Enter note for job description",
    salary: "",
    variableSalary: "",
    workType: "Job",
    applicationDeadline: "",
    noOfPositions: "",
    perks: [],
  });

  const editor_description = useEditor({
    extensions,
    content: postJob.description,
    onUpdate: ({ editor }) => {
      setPostJob((prevFormData) => ({
        ...prevFormData,
        description: editor.getHTML(),
      }));
    },
  });

  const editor_candidatePreferences = useEditor({
    extensions,
    content: postJob.candidatePreferences,
    onUpdate: ({ editor }) => {
      setPostJob((prevFormData) => ({
        ...prevFormData,
        candidatePreferences: editor.getHTML(),
      }));
    },
  });

  const editor_noteJD = useEditor({
    extensions,
    content: postJob.noteJD,
    onUpdate: ({ editor }) => {
      setPostJob((prevFormData) => ({
        ...prevFormData,
        noteJD: editor.getHTML(),
      }));
    },
  });

  // Universal change handler for all form inputs
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      // Handling checkboxes separately
      setPostJob((prevFormData) => {
        const newPerks = checked
          ? [...prevFormData.perks, value]
          : prevFormData.perks.filter((perk) => perk !== value);

        return { ...prevFormData, [name]: newPerks };
      });
    } else {
      // Handling other inputs like text, number, date, etc.
      setPostJob((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const getCookieAndRole = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));
    if (cookieValue) {
      const tokenWithPrefix = cookieValue.split("=")[1];
      if (tokenWithPrefix.length > 0) {
        const role = tokenWithPrefix[0];
        const token = tokenWithPrefix.slice(1); // Remove the prefix
        return { role, token };
      }
    }
    return { role: null, token: null };
  };
  const formSubmission = async () => {
    try {
      toast.info("Posting Job...");
      let token = getCookieAndRole();
      let user = await getEmployerID(token.token);
      const response = await axios.post(
        `${API_URI}/Employer/newJobPosting`,
        {
          ...postJob,
          employer: user,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "https://hiremeclub.com",
          },
        }
      );
      toast.success("Job Posted!");
      return response.data;
    } catch (error) {
      toast.warning("Please fill all fields!");
      //console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await formSubmission();

      // Reset the form fields to their initial state
      setPostJob({
        jobTitle: "",
        workLocation: "",
        description: "Enter description",
        candidatePreferences: "Enter candidate preferences ",
        noteJD: "Enter note for job description",
        salary: "",
        variableSalary: "",
        workType: "Job",
        applicationDeadline: "",
        noOfPositions: "",
        perks: [],
      });
    } catch (error) {
      //console.log(error);
      toast.error(error.message || "Something went wrong");
    }
  };

  if (!editor_description || !editor_candidatePreferences || !editor_noteJD) {
    return null;
  }

  return (
    <div className="mb-6 min-w-full md:w-fit overflow-auto scrollbar-hide py-4 h-full md:pb-32 lg:pb-0">
      <h2 className="text-3xl font-semibold text-center text-gray-700 mb-4">
        Post a Job
      </h2>

      <div className="bg-white rounded-lg shadow-lg p-6 w-fit md:w-full">
        <p className="italic text-red-600 text-xs">* Required</p>
        <div>
          <div className="mb-4">
            <div className="flex space-x-4">
              <div className="w-1/2">
                <label
                  htmlFor="role"
                  className="block text-gray-700 font-semibold"
                >
                  Job Title: <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  id="role"
                  name="jobTitle"
                  className="rounded-md border border-gray-300 p-2 w-full"
                  value={postJob.jobTitle}
                  onChange={handleChange}
                />
              </div>
              <div className="w-1/2">
                <label
                  htmlFor="workLocation"
                  className="block text-gray-700 font-semibold"
                >
                  Work Location: <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  id="workLocation"
                  name="workLocation"
                  className="rounded-md border border-gray-300 p-2 w-full"
                  value={postJob.workLocation}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="mb-4">
            <div className="flex space-x-4">
              <div className="w-full">
                <label className="block text-gray-700 font-semibold">
                  Job Description: <span className="text-red-600">*</span>
                </label>

                <div>
                  <Button editor={editor_description} />

                  <div className="rounded-md border border-gray-500 p-2 w-full h-60 overflow-y-auto">
                    <EditorContent editor={editor_description} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <div className="flex space-x-4">
              <div className="w-1/2">
                <label
                  htmlFor="jobDescriptionNote"
                  className="block text-gray-700 font-semibold"
                >
                  Do you have any candidate preferences?:
                </label>
                <div>
                  <Button editor={editor_candidatePreferences} />

                  <div className="rounded-md border border-gray-500 p-2 w-full h-60 overflow-y-auto">
                    <EditorContent editor={editor_candidatePreferences} />
                  </div>
                </div>
              </div>
              <div className="w-1/2">
                <label
                  htmlFor="jobDescriptionNote"
                  className="block text-gray-700 font-semibold"
                >
                  Note for Job Description:
                </label>
                <div>
                  <Button editor={editor_noteJD} />

                  <div className="rounded-md border border-gray-500 p-2 w-full h-60 overflow-y-auto">
                    <EditorContent editor={editor_noteJD} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <div className="flex space-x-4">
              <div className="w-1/2">
                <label
                  htmlFor="salary"
                  className="block text-gray-700 font-semibold"
                >
                  Salary/ Stipend (Per Annum):{" "}
                  <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  id="salary"
                  name="salary"
                  className="rounded-md border border-gray-300 p-2 w-full"
                  placeholder="Base monthly salary"
                  value={postJob.salary}
                  onChange={handleChange}
                />
              </div>
              <div className="w-1/2">
                <label
                  htmlFor="variableSalary"
                  className="block text-gray-700 font-semibold"
                >
                  Any Variable Salary:
                </label>
                <input
                  type="number"
                  id="variableSalary"
                  name="variableSalary"
                  value={postJob.variableSalary}
                  onChange={handleChange}
                  className="rounded-md border border-gray-300 p-2 w-full"
                  placeholder="Any extra incentive?"
                />
              </div>
            </div>
          </div>

          <div className="mb-4">
            <div className="flex space-x-4">
              <div className="w-1/2">
                <label
                  htmlFor="workType"
                  className="block text-gray-700 font-semibold"
                >
                  Work Type: <span className="text-red-600">*</span>
                </label>
                <select
                  id="workType"
                  name="workType"
                  value={postJob.workType}
                  onChange={handleChange}
                  className="rounded-md border border-gray-300 p-2 w-full"
                >
                  <option value="Job">Job</option>
                  <option value="Internship">Internship</option>
                </select>
              </div>

              <div className="w-1/2">
                <label
                  htmlFor="applicationDeadline"
                  className="block text-gray-700 font-semibold"
                >
                  Application Deadline:
                </label>
                <input
                  type="date"
                  id="applicationDeadline"
                  name="applicationDeadline"
                  value={postJob.applicationDeadline}
                  onChange={handleChange}
                  className="rounded-md border border-gray-300 p-2 w-full"
                />
              </div>
            </div>
          </div>

          <div className="mb-4">
            <div className="flex space-x-4">
              <div className="w-1/2">
                <label
                  htmlFor="positions"
                  className="block text-gray-700 font-semibold"
                >
                  No of Positions:
                </label>
                <input
                  type="number"
                  id="positions"
                  name="noOfPositions"
                  value={postJob.noOfPositions}
                  onChange={handleChange}
                  className="rounded-md border border-gray-300 p-2 w-full"
                  min="0"
                />
              </div>
              <div className="mb-4">
                <div className="block text-gray-700 font-semibold">Perks:</div>
                {/* First row of checkboxes */}
                <div className="flex checkbox md:space-x-8 mb-4">
                  {[
                    { id: "week", label: "5 Days a Week" },
                    { id: "health", label: "Health Insurance" },
                    { id: "life", label: "Life Insurance" },
                    { id: "gym", label: "Gym Benefit" },
                    { id: "travel", label: "Travel Allowance" },
                  ].map((perk) => (
                    <div className="flex items-center space-x-4" key={perk.id}>
                      <input
                        type="checkbox"
                        className="rounded border border-gray-300"
                        value={perk.label} // Using label as value for better readability
                        name="perks" // This should match the key in your state object
                        checked={postJob.perks.includes(perk.label)} // Check if the perk is in the state array
                        onChange={handleChange}
                      />
                      <span className="ml-2">{perk.label}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="text-center mt-8">
            <button
              onClick={(e) => handleSubmit(e)}
              className="bg-blue-500 hover-bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Post Job
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostJob;
