import React, { useState } from "react";
import "../customCss/jobDetails.css";
import parse from "html-react-parser";

import {
  DocumentTextIcon,
  ClipboardDocumentListIcon,
  AcademicCapIcon,
} from "@heroicons/react/24/outline";
import { applyToJob } from "../../service/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const JD1 = ({ cardData }) => {
  const [isWishlistClicked, setIsWishlistClicked] = useState(false);
  const navigate = useNavigate();
  const handleWishlistClick = () => {
    setIsWishlistClicked(!isWishlistClicked);
  };

  const handleApplyClick = async () => {
    try {
      const token = getCookieAndRole();
      if (token.token) {
        // Assuming applyToJob is an asynchronous function that returns a promise
        const response = await applyToJob(
          token.token,
          cardData._id,
          cardData.employer
        );
        // ////console.log(response);
        if (response.success) {
          toast.success("Successfully applied");
        } else {
          toast.error("Alreadly Applied");
        }
      } else {
        navigate("/login");
        toast.error("Login to Apply!");
      }
    } catch (error) {
      console.error("Error applying to the job:", error);
      // You can display an error toast message here if needed
    }
  };

  const getCookieAndRole = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));
    if (cookieValue) {
      const tokenWithPrefix = cookieValue.split("=")[1];
      if (tokenWithPrefix.length > 0) {
        const role = tokenWithPrefix[0];
        const token = tokenWithPrefix.slice(1); // Remove the prefix
        return { role, token };
      }
    }
    return { role: null, token: null };
  };

  //console.log(cardData);
  return (
    <div className="bg-white shadow-md p-4 rounded-lg ">
      <div className="relative flex items-center">
        <div className="md:absolute top-2 md:-left-2 rounded-full p-1">
          <img
            src={cardData.companyName.logoUrl}
            alt="Company Icon"
            className="w-8 h-8"
          />
        </div>

        <div className="md:ml-10">
          <h3 className="font-bold text-xl mt-2">{cardData.jobTitle}</h3>
          <p className="text-gray-800 font-bold">{cardData.workLocation}</p>
        </div>
        <div className="md:absolute md:top-0 md:right-0 bg-white-900">
          <div className="flex justify-between md:mt">
            <button
              className="px-4 py-2  bg-blue-500 text-white rounded-lg m-10 mt-5 md:mt-0"
              onClick={handleApplyClick}
            >
              Apply Now
            </button>
          </div>
        </div>
      </div>
      <div className="flex mt-3 badge ml-12 space-x-2 mt-sm">
        {cardData.perks[0] && (
          <span className="px-2 py-1 bg-blue-100 text-blue-800 text-xs rounded-lg">
            {cardData.perks[0]}
          </span>
        )}
        {cardData.perks[1] && (
          <span className="px-2 py-1 bg-orange-100 text-orange-800 text-xs rounded-lg">
            {cardData.perks[1]}
          </span>
        )}
        {cardData.perks[2] && (
          <span className="px-2 py-1 bg-green-100 text-green-900 text-xs rounded-lg">
            {cardData.perks[2]}
          </span>
        )}
        {cardData.perks[3] && (
          <span className="px-2 py-1 bg-[#e4fffd] text-blue-800 text-xs rounded-lg">
            {cardData.perks[3]}
          </span>
        )}
        {cardData.perks[4] && (
          <span className="px-2 py-1 bg-red-100 text-red-700 text-xs rounded-lg">
            {cardData.perks[4]}
          </span>
        )}
      </div>

      <div className="mt-4">
        <p className="text-xl font-bold">
          <DocumentTextIcon className="h-6 w-6 mr-2 inline" />
          Job Description
        </p>
        <p className="overflow-hidden font-semibold text-md  md:w-3/4">
          {parse(cardData.description)}
        </p>
      </div>
      {cardData.noteJD && (
        <div className="mt-4">
          <p className="text-xl font-bold">
            <ClipboardDocumentListIcon className="h-6 w-6 mr-2 inline" />
            Additional Note for Candidates{" "}
          </p>
          <p className="overflow-hidden font-semibold text-md md:w-3/4">
            {parse(cardData.noteJD)}
          </p>
        </div>
      )}
      {cardData.candidatePreferences && (
        <div className="mt-4">
          <p className="text-xl font-bold">
            <AcademicCapIcon className="h-6 w-6 mr-2 inline" />
            Preferred Candidate{" "}
          </p>
          <p className="overflow-hidden font-semibold text-md md:w-3/4">
            {parse(cardData.candidatePreferences)}
          </p>
        </div>
      )}
    </div>
  );
};

export default JD1;
