import React, { useState, useEffect, useRef } from "react";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import Modal from "../../components/dashboard/Modal";
import {
  getAllJobsPosted,
  getAllCandidatesApplied,
  getEmployerStats,
} from "../../service/api";
import "../customCss/employer.css";
import { Link } from "react-router-dom";
import { MagnifyingGlass } from "react-loader-spinner";

const Employer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [latestJob, setLatestJob] = useState([]);
  const [latestCandidates, setLatestCandidates] = useState([]);
  const [stats, setStats] = useState([]);
  const selectedCandidateRef = useRef();
  

  useEffect(() => {
    const token = getCookieAndRole();
    const fetchLatestJobAndCandidates = async () => {
      try {
        const jobs = await getAllJobsPosted(token.token);
        // ////console.log(jobs);
        const latestJob = jobs.length > 0 ? jobs[jobs.length - 1] : null;
        setLatestJob(latestJob);
        if (latestJob) {
          const candidates = await getAllCandidatesApplied(latestJob._id);
          // Candidates will have 1st index of array as Employer ID
          setLatestCandidates(candidates);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchStats = async () => {
      try {
        const stats = await getEmployerStats(token.token);
        // ////console.log(stats);
        setStats(stats);
      } catch (error) {
        console.error("Error fetching stats", error);
      }
    };

    fetchStats();
    fetchLatestJobAndCandidates();
  }, []);

  const getCookieAndRole = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));
    if (cookieValue) {
      const tokenWithPrefix = cookieValue.split("=")[1];
      if (tokenWithPrefix.length > 0) {
        const role = tokenWithPrefix[0];
        const token = tokenWithPrefix.slice(1); // Remove the prefix
        return { role, token };
      }
    }
    return { role: null, token: null };
  };

  const openModal = (candidate) => {
    selectedCandidateRef.current = candidate;
    setIsModalOpen(true);
  };

  const closeModal = () => {
    selectedCandidateRef.current = null;
    setIsModalOpen(false);
  };

  return (
    <div className="flex-col p-2 contain rounded-lg bg-white lg:mt-4">
      <div className="text-4xl heading text-gray-700 mb-8 font-semibold">
        Employer's Dashboard <span className="text-blue-600">Overview</span>
      </div>
      {/*To display the Stats in */}
      {stats.data ? (
        <div className="responsive-box flex flex-wrap mb-7 sm:space-x-4">
          <div className="bg-cyan-300 box rounded-lg shadow-2xl flex-1 h-44 relative">
            <div className="text-gray-50 text-lg sm:text-xl pl-3 pt-3">
              Active job posting
            </div>
            <div className="text-gray-50 text-5xl p-3 absolute bottom-0 right-0">
              {stats.data.totalJobPostings}
            </div>
          </div>
          <div className="bg-cyan-300 box rounded-lg shadow-2xl flex-1 h-44 relative">
            <div className="text-gray-50 text-lg sm:text-xl p-3">
              Total application
            </div>
            <div className="text-gray-50 text-5xl p-3 absolute bottom-0 right-0">
              {stats.data.totalJobApplications}
            </div>
          </div>
          <div className="bg-cyan-300 box rounded-lg shadow-2xl flex-1 h-44 relative">
            <div className="text-gray-50 text-lg sm:text-xl pl-3 pt-3">
              Shortlisted candidates
            </div>
            <div className="text-gray-50 text-5xl p-3 absolute bottom-0 right-0">
              {stats.data.totalShortlistedCandidates}
            </div>
          </div>
          <div className="bg-cyan-300 box rounded-lg shadow-2xl flex-1 h-44 relative">
            <div className="text-gray-50 text-lg sm:text-xl pl-3 pt-3">
              In hiring process
            </div>
            <div className="text-gray-50 text-5xl p-3 absolute bottom-0 right-0">
              {stats.data.totalInterviewCandidates}
            </div>
          </div>
        </div>
      ) : (
        <div className="magnifying-glass-wrapper flex items-center justify-center h-screen">
          <MagnifyingGlass
            visible={true}
            height="160"
            width="160"
            ariaLabel="MagnifyingGlass-loading"
            wrapperStyle={{}}
            wrapperClass="MagnifyingGlass-wrapper"
            glassColor="#c0efff"
            color="#2979FF"
          />
        </div>
      )}
      <div className="bg-white border rounded-lg shadow-lg mb-3">
        <div className="text-2xl  text-gray-700 font-semibold pl-2 pt-2 pb-1">
          <div className="flex justify-between items-center">
            {latestJob ? (
              <>
                {latestJob.jobTitle}
                <Link
                  to="/emp-dashboard/candidates"
                  className="text-gray-800 text-base"
                >
                  View All
                  <ArrowLongRightIcon className="h-6 w-6 mr-2 inline" />
                </Link>
              </>
            ) : (
              <p>No Job Posted</p>
            )}
          </div>
        </div>
        <hr className="border-t border-gray-300"></hr>
        <div className="mx-auto tables">
          <div className="max-h-[350px] overflow-y-auto">
            {latestCandidates && latestCandidates.length > 0 ? (
              <table className="min-w-full my-2 bg-white ">
                <thead>
                  <tr>
                    <td className="py-2 px-4 text-center text-gray-500">
                      E-Mail
                    </td>
                    <td className="py-2 px-4 text-center text-gray-500">
                      Total Score
                    </td>
                    <td className="py-2 px-4 text-center text-gray-500">
                      Name of Candidate
                    </td>
                    <td className="py-2 px-4 text-center text-gray-500">PoW</td>
                    <td className="py-2 px-4 text-center text-gray-400 ">
                      Schedule Interview
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {latestCandidates.slice(0, 5).map((candidate, index) => (
                    <tr key={index}>
                      <td className="py-2 px-4 text-center text-gray-700">
                        {candidate.email}
                      </td>
                      <>
                        {candidate.completedStages.stage2 ? (
                          <>
                            <td className="py-2 px-4 text-center text-gray-700">
                              {(() => {
                                try {
                                  const score =
                                    ((candidate.candidate.role_related.score.ques1?.score ?? 0) +
                                      (candidate.candidate.role_related.score.ques2?.score ?? 0)) /
                                      2 +
                                    candidate.candidate.scoreExcel +
                                    candidate.candidate.scoreAnalytical +
                                    candidate.candidate.scoreReasoning +
                                    ((candidate.candidate.creative_related.score.ques1?.score ?? 0) +
                                      (candidate.candidate.creative_related.score.ques2?.score ?? 0)) /
                                      2 +
                                    candidate.candidate.resume_score;

                                  // Check if the calculated score is NaN or not a finite number
                                  if (isNaN(score) || !isFinite(score)) {
                                    throw new Error("Invalid score");
                                  }

                                  return score.toFixed(2);
                                } catch (error) {
                                  console.error("Error fetching score:", error);
                                  return "Pending";
                                }
                              })()}
                            </td>
                            <td className="py-2 px-4 text-center text-gray-700">
                              {candidate.candidate.name}
                            </td>
                            <td className="py-2 px-4 text-center text-gray-700">
                              <button
                                onClick={() => openModal(candidate)}
                                className="text-blue-500 hover:underline cursor-pointer"
                              >
                                View PoW
                              </button>
                              <Modal
                                isOpen={isModalOpen}
                                closeModal={closeModal}
                                candidateRef={selectedCandidateRef}
                              />
                            </td>
                            <div className="ml-12">
                              <td
                                className={`py-1 px-8 text-center text-white ${
                                  candidate.status === "Interview"
                                    ? "bg-green-400"
                                    : candidate.status === "pending"
                                    ? "bg-yellow-600"
                                    : "bg-red-400"
                                } rounded-3xl w-40`}
                              >
                                {candidate.status}
                              </td>
                            </div>
                          </>
                        ) : (
                          <td className="py-2 px-4 text-center text-yellow-700">Pending</td>
                        )}
                      </>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="text-gray-500 text-center">
                No candidates available for the latest job.
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="md:hidden mt-6">&nbsp;</div>

      {/* <Profile /> */}
    </div>
  );
};
export default Employer;
