import React from "react";
import "../customCss/jobDetails.css";
import {
  BriefcaseIcon,
  CalendarDaysIcon,
  QueueListIcon,
  CurrencyRupeeIcon,
} from "@heroicons/react/24/outline";
import { format } from "date-fns";

const JD2 = ({ cardData }) => {
  // ////console.log(cardData);
  return (
    <div className="flex flex-col w-full  bg-white shadow-md p-4 rounded-lg full-width-height">
      {cardData.companyName.brandName && (
        <div className="mb-4">
          <h2 className="text-xl font-bold">Company</h2>
          <p className="text-lg font-bold text-blue-gray-800">
            {cardData.companyName.brandName}
          </p>
        </div>
      )}
      {cardData.salary && (
        <div className="mb-4">
          <h2 className="text-xl font-bold">
            <CurrencyRupeeIcon className="h-6 w-6 mr-2 inline" />
            Salary
          </h2>
          <p className="text-lg font-bold text-blue-gray-800">
            &#x20B9;{cardData.salary}
          </p>
        </div>
      )}
      {cardData.variableSalary && (
        <div className="mb-4">
          <h2 className="text-xl font-bold">
            <CurrencyRupeeIcon className="h-6 w-6 mr-2 inline" />
            Variable Salary
          </h2>
          <p className="text-lg font-bold text-blue-gray-800">
            &#x20B9;{cardData.variableSalary}
          </p>
        </div>
      )}
      {cardData.workType && (
        <div className="mb-4">
          <h2 className="text-xl font-bold">
            <BriefcaseIcon className="h-6 w-6 mr-2 inline" />
            Work Type
          </h2>
          <p className="text-lg font-bold text-blue-gray-800">
            {cardData.workType}
          </p>
        </div>
      )}
      {cardData.applicationDeadline && (
        <div className="mb-4">
          <h2 className="text-xl font-bold">
            <CalendarDaysIcon className="h-6 w-6 mr-2 inline" />
            Application Deadline
          </h2>
          <p className="text-lg font-bold text-blue-gray-800">
            {format(new Date(cardData.applicationDeadline), "dd-MMM-yyyy")}
          </p>
        </div>
      )}
      {cardData.noOfPositions && (
        <div className="mb-4">
          <h2 className="text-xl font-bold">
            <QueueListIcon className="h-6 w-6 mr-2 inline" />
            No. of Position
          </h2>
          <p className="text-lg font-bold text-blue-gray-800">
            {cardData.noOfPositions}
          </p>
        </div>
      )}
    </div>
  );
};

export default JD2;
