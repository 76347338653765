import React, { useState, useEffect } from "react";
import TopNavigation from "../components/NavBar/TopNavigation";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  forgetPasswordCandidate,
  forgetPasswordEmployer,
} from "../service/api";
import { useNavigate } from "react-router-dom";
import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";

function ForgetPassword() {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const finalToken = token.slice(0, -1);
  const n = token.charAt(token.length - 1);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      toast.error("Password does not match");
      return;
    }
    if (n == 0) {
      try {
        const response = await forgetPasswordCandidate(
          newPassword,
          confirmNewPassword,
          finalToken
        );
        // ////console.log(response);

        if (response.success) {
          navigate("/login");
          toast.success("Password Reset Successfully!");
        } else {
          toast.error("Error in setting Password");
        }
      } catch (error) {
        toast.error("Error Change in Password");
      }
    } else {
      try {
        const response = await forgetPasswordEmployer(
          newPassword,
          confirmNewPassword,
          finalToken
        );
        if (response.success) {
          navigate("/emp-login");
          toast.success("Password Reset Successfully!");
        } else {
          toast.error("Error in setting Password");
        }
      } catch (error) {
        toast.error("Error Change in Password");
      }
    }
  };

  return (
    <div className="bg-gray-100 h-screen">
      <TopNavigation showNotification={false} />
      <div className="max-w-md mx-auto mt-28 p-6 text-center align-middle bg-gray-50 rounded-xl shadow-md">
        <h2 className="text-2xl font-semibold mb-4">Reset Password</h2>
        <div className="mb-4">
          <label
            htmlFor="newPassword"
            className="block text-sm font-medium text-gray-600"
          >
            New Password
          </label>
          <div className="w-full group flex flex-nowrap items-center mt-1 px-2 border-2 rounded-md bg-white gap-2 focus-within:border-blue-500">
            <input
              type={showPassword ? "text" : "password"}
              id="newPassword"
              className="py-3 h-full w-full bg-transparent outline-none border-none"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Enter password"
              autoFocus
            />
            <button
              type="button"
              className="focus:outline-none"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? (
                <IoMdEyeOff className="w-4 h-4 text-gray-700 group-focus-within:text-blue-500" />
              ) : (
                <IoMdEye className="w-4 h-4 text-gray-700 group-focus-within:text-blue-500" />
              )}
            </button>
          </div>
        </div>
        <div className="mb-4">
          <label
            htmlFor="confirmNewPassword"
            className="block text-sm font-medium text-gray-600"
          >
            Confirm New Password
          </label>
          <div className="w-full group flex flex-nowrap items-center mt-1 px-2 border-2 rounded-md bg-white gap-2 focus-within:border-blue-500">
            <input
              type={showConfirmPassword ? "text" : "password"}
              id="confirmNewPassword"
              className="py-3 h-full w-full bg-transparent outline-none border-none"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              placeholder="Re-Enter password"
            />
            <button
              type="button"
              className="focus:outline-none"
              onClick={toggleConfirmPasswordVisibility}
            >
              {showConfirmPassword ? (
                <IoMdEyeOff className="w-4 h-4 text-gray-700 group-focus-within:text-blue-500" />
              ) : (
                <IoMdEye className="w-4 h-4 text-gray-700 group-focus-within:text-blue-500" />
              )}
            </button>
          </div>
        </div>
        <button
          className="bg-blue-500 text-white p-2 rounded-md w-full"
          onClick={handleChangePassword}
        >
          Change Password
        </button>
      </div>
    </div>
  );
}

export default ForgetPassword;
