import React, { useState , useEffect } from "react";
import "../customCss/candidateSidebar.css";
import {
  PresentationChartLineIcon,
  ChatBubbleLeftRightIcon,
  BriefcaseIcon,
  InboxIcon,
  UserGroupIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { NavLink, useLocation } from "react-router-dom";

const Sidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const location = useLocation();
  const [selectedNavItem, setSelectedNavItem] = useState("");

  useEffect(() => {
    // Update the selectedNavItem whenever the location changes
    setSelectedNavItem(location.pathname.split("/").pop() || "dashboard");
  }, [location.pathname]);

  useEffect(() => {
    // Save the selected item to localStorage whenever it changes
    localStorage.setItem("selectedNavItem", selectedNavItem);
  }, [selectedNavItem]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleNavItemClick = (navItem) => {
    setSelectedNavItem(navItem);
  };

  const handleLogoClick = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };


  return (
    <div className="contain mt-0 flex rounded h-fit md:min-h-screen">
      {/* Mobile Sidebar Toggle Button */}
      <button
        onClick={toggleSidebar}
        className="md:hidden absolute top-10 right-3 z-10 p-3"
      >
        {isSidebarOpen ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        )}
      </button>

      {/* Sidebar */}
      <nav
        className={`bg-white md:w-16 ${isSidebarOpen ? "lg:w-64" : "lg:w-24"} rounded-2xl side-navigation border-r transition-all duration-500 ${isSidebarOpen ? "block" : "hidden"
          } md:block `}
      >
        {/* Logo - Visible only for screens medium and larger */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="hidden md:flex w-6 h-6 ml-auto mr-8 my-4 cursor-pointer"
          onClick={handleLogoClick}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
          />
        </svg>

        {/* Navigation Links */}
        <ul className="text-gray-700 px-4 pt-2">
          <NavLink to="/emp-dashboard/">
            <div
              className={`relative group hover:bg-gray-200 hover:text-blue-600 transition-transform transform-gpu hover:-translate-y-1 p-4 cursor-pointer rounded-xl ${selectedNavItem === "dashboard" ? "bg-gray-200 text-blue-600" : ""
                }`}
              onClick={() => handleNavItemClick("dashboard")}
            >
              <PresentationChartLineIcon className="h-6 w-6 mr-2 inline" />
              <span className={isSidebarOpen ? "" : "hidden"}>Dashboard</span>

              {!isSidebarOpen && (
                <div className="absolute left-full rounded-md px-2 py-2 ml-6 bg-gray-200 text-blue-600 text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0 z-10">
                  Dashboard
                </div>
              )}
            </div>
          </NavLink>

          <NavLink to="/emp-dashboard/interview">
            <div
              className={`relative group hover:bg-gray-200 hover:text-blue-600 transition-transform transform-gpu hover:-translate-y-1 p-4 cursor-pointer rounded-xl mt-2 ${selectedNavItem === "interview" ? "bg-gray-200 text-blue-600" : ""
                }`}
              onClick={() => handleNavItemClick("interview")}
            >
              <ChatBubbleLeftRightIcon className="h-6 w-6 mr-2 inline" />
              <span className={isSidebarOpen ? "" : "hidden"}>Interview</span>

              {!isSidebarOpen && (
                <div className="absolute left-full rounded-md px-2 py-2 ml-6 bg-gray-200 text-blue-600 text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0">
                  Interview
                </div>
              )}
            </div>
          </NavLink>
          <NavLink to="/emp-dashboard/postjob">
            <div
              className={`relative group hover:bg-gray-200 hover:text-blue-600 transition-transform transform-gpu hover:-translate-y-1 p-4 cursor-pointer rounded-xl mt-2 ${selectedNavItem === "postjob" ? "bg-gray-200 text-blue-600" : ""
                }`}
              onClick={() => handleNavItemClick("postjob")}
            >
              <BriefcaseIcon className="h-6 w-6 mr-2 inline" />
              <span className={isSidebarOpen ? "" : "hidden"}>Post a Job</span>

              {!isSidebarOpen && (
                <div className="absolute left-full rounded-md py-2 px-2 ml-6 bg-gray-200 text-blue-600 text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0 whitespace-nowrap">
                  Post a Job
                </div>
              )}
            </div>
          </NavLink>

          <NavLink to="/emp-dashboard/test">
            <div
              className={`relative group hover:bg-gray-200 hover:text-blue-600 transition-transform transform-gpu hover:-translate-y-1 p-4 cursor-pointer rounded-xl mt-2 ${selectedNavItem === "test" ? "bg-gray-200 text-blue-600" : ""
                }`}
              onClick={() => handleNavItemClick("test")}
            >
              <InboxIcon className="h-6 w-6 mr-2 inline" />
              <span className={isSidebarOpen ? "" : "hidden"}>Test Link</span>

              {!isSidebarOpen && (
                <div className="absolute left-full rounded-md px-2 py-2 ml-6 bg-gray-200 text-blue-600 text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0">
                  Test Link
                </div>
              )}
            </div>
          </NavLink>

          <NavLink to="/emp-dashboard/candidates">
            <div
              className={`relative group hover:bg-gray-200 hover:text-blue-600 transition-transform transform-gpu hover:-translate-y-1 p-4 cursor-pointer rounded-xl mt-2 ${selectedNavItem === "candidates" ? "bg-gray-200 text-blue-600" : ""
                }`}
              onClick={() => handleNavItemClick("candidates")}
            >
              <UserGroupIcon className="h-6 w-6 mr-2 inline" />
              <span className={isSidebarOpen ? "" : "hidden"}>Candidates</span>

              {!isSidebarOpen && (
                <div className="absolute left-full rounded-md px-2 py-2 ml-6 bg-gray-200 text-blue-600 text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0">
                  Candidates
                </div>
              )}
            </div>
          </NavLink>

          <NavLink to="/emp-dashboard/profile">
            <div
              className={`relative group hover:bg-gray-200 hover:text-blue-600 transition-transform transform-gpu hover:-translate-y-1 p-4 cursor-pointer rounded-xl mt-2 ${selectedNavItem === "profile" ? "bg-gray-200 text-blue-600" : ""
                }`}
              onClick={() => handleNavItemClick("profile")}
            >
              <UserCircleIcon className="h-6 w-6 mr-2 inline" />
              <span className={isSidebarOpen ? "" : "hidden"}>Profile</span>

              {!isSidebarOpen && (
                <div className="absolute left-full rounded-md px-2 py-2 ml-6 bg-gray-200 text-blue-600 text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0">
                  Profile
                </div>
              )}
            </div>
          </NavLink>
          {/* Add more NavLink elements as needed */}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
