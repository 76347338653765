import React from "react";
import TopNavigation from "../components/NavBar/TopNavigation";
import { Footer } from "../components/Footer";
import PriceCard from "../components/PriceCard";

export default function TermsConditions() {
  return (
    <div className="bg-white">
      <TopNavigation />
      <div className="container mx-auto px-4 py-2 lg:px-8 lg:py-4">
        <h1 className="text-3xl font-bold mb-2 text-blue-500">
          Terms and Conditions
        </h1>
        <p className="mb-4">
          Welcome to HiremeClub, your innovative GenAI-driven hiring portal
          provided by{" "}
          <strong>&quot;Hirepreneurs Technologies Private Limited&quot;</strong>
          . By using our services available through our website and associated
          applications{" "}
          <strong>(collectively, the &quot;HiremeClub Platform&quot;)</strong>,
          you agree to the following terms and conditions (the
          &quot;Terms&quot;). If you do not agree with any part of these Terms,
          you should discontinue access and use of the Platform.
        </p>
        <ol className="list-decimal lg:ml-6 lg:mb-8 mb-2">
          <li className="mb-6">
            <h2 className="text-xl font-bold mb-2">Acceptance of Terms</h2>
            <p>
              HiremeClub provides a digital platform for job posters to post job
              vacancies and for candidates to apply to them using GenAI
              technology. Our GenAI screens candidates based on 8 different
              parameters to assist job posters in their hiring process. By using
              the Platform, you acknowledge that you have read, understood, and
              agree to be bound by these Terms.
            </p>
          </li>
          <li className="mb-6">
            <h2 className="text-xl font-bold mb-2">Use of Platform</h2>
            <ul>
              <li>
                <strong>Job Posters:</strong> You can post job vacancies, view
                candidate profiles that match your job criteria, and utilize our
                GenAI for screening candidates.
              </li>
              <li>
                <strong>Candidates:</strong> You can search for jobs, submit
                applications, and have your profiles evaluated by potential
                employers using our GenAI technology.
              </li>
              <li>
                <strong>All Users:</strong> You are responsible for ensuring the
                information you provide is accurate, complete, and up-to-date.
              </li>
            </ul>
          </li>
          <li className="mb-6">
            <h2 className="text-xl font-bold mb-2">GenAI Functionality</h2>
            <p>
              Our GenAI assesses candidates using 8 different parameters. These
              parameters help in evaluating the skills, experience, and
              suitability of candidates for specific job roles. By using
              HiremeClub, you consent to the use of GenAI in processing and
              analyzing your data as per our Privacy Policy.
            </p>
          </li>
          <li className="mb-6">
            <h2 className="text-xl font-bold mb-2">Intellectual Property</h2>
            <p>
              The content and data found on HiremeClub, including text,
              graphics, logos, icons, images, audio clips, and software, are the
              property of HiremeClub or are licensed to us and are protected
              under both United States and foreign intellectual property laws.
            </p>
          </li>
          <li className="mb-6">
            <h2 className="text-xl font-bold mb-2">User Conduct</h2>
            <p>
              You agree to use HiremeClub only for lawful purposes and in a way
              that does not infringe the rights of, restrict, or inhibit anyone
              else's use and enjoyment of the Platform. Prohibited behavior
              includes harassing or causing distress or inconvenience to any
              other user, transmitting obscene or offensive content, or
              disrupting the normal flow of dialogue within HiremeClub.
            </p>
          </li>
          <li className="mb-6">
            <h2 className="text-xl font-bold mb-2">
              Modifications to the Platform and Terms
            </h2>
            <p>
              HiremeClub reserves the right, at its discretion, to modify these
              Terms at any time. By continuing to use the Platform following
              such modifications, you agree to be bound by such modifications.
            </p>
          </li>
          <li className="mb-6">
            <h2 className="text-xl font-bold mb-2">Account Security</h2>
            <p>
              You are responsible for maintaining the confidentiality of your
              login information and for all activities that occur under your
              account. You agree to immediately notify HiremeClub of any
              unauthorized use of your account.
            </p>
          </li>
          <li className="mb-6">
            <h2 className="text-xl font-bold mb-2">Termination</h2>
            <p>
              HiremeClub has the right to terminate your access to the Platform
              if you fail to comply with these Terms.
            </p>
          </li>
          <li className="mb-6">
            <h2 className="text-xl font-bold mb-2">Disclaimers</h2>
            <p>
              HiremeClub provides the Platform on an "as is" and "as available"
              basis. You therefore use the Platform at your own risk. HiremeClub
              expressly disclaims any warranties of merchantability, fitness for
              a particular purpose, or non-infringement.
            </p>
          </li>
          <li className="mb-6">
            <h2 className="text-xl font-bold mb-2">Limitation of Liability</h2>
            <p>
              In no event will HiremeClub be liable for any indirect,
              incidental, special, punitive, or consequential damages as a
              result of your use of the Platform, any content or services
              provided by the Platform or these Terms.
            </p>
          </li>
          <li className="mb-6">
            <h2 className="text-xl font-bold mb-2">Indemnity</h2>
            <p>
              You agree to indemnify and hold harmless HiremeClub, its officers,
              directors, employees, suppliers, and affiliates from any claims,
              losses, damages, liabilities, including legal fees and expenses,
              arising out of your use of the Platform, your violation of these
              Terms, or your violation of any rights of another.
            </p>
          </li>
          <li className="mb-6">
            <h2 className="text-xl font-bold mb-2">Dispute Resolution</h2>
            <p>
              Any disputes arising under these Terms will be governed by the
              laws of the State of [State], without regard to its conflict of
              laws rules. All legal proceedings arising out of or in connection
              with these Terms shall be brought solely in [City, State].
            </p>
          </li>
          <li className="mb-6">
            <h2 className="text-xl font-bold mb-2">Contact Information</h2>
            <p>
              If you have any questions about these Terms, please contact us at
              <a href="mailto:support@hiremeclub.com">
                <em className="font-bold"> support@hiremeclub.com</em>
              </a>
            </p>
          </li>
        </ol>
        <p className="pb-4 font-semibold text-md">
          By accessing and using HiremeClub, you accept and agree to be bound by
          the terms and provision of this agreement. In addition, when using
          HiremeClub's services, you shall be subject to any posted guidelines
          or rules applicable to such services. All such guidelines or rules are
          hereby incorporated by reference into the Terms.
        </p>
      </div>
      <Footer />
    </div>
  );
}
