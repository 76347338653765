import React, { useState, useEffect, useRef } from "react";
import problem from "../images/problem.png";
import { TopNavigation } from "../components/NavBar/TopNavigation";
import {
  getStage2Excel,
  getStage2Analytical,
  getStage2Reasoning,
} from "../service/mlAPI";
import { storeMCQScore } from "../service/api";
import TabSwitch from "../components/portal/TabSwitch";
import { useNavigate, useLocation } from "react-router-dom";
import TestingNavbar from "../components/NavBar/TestingNavbar";
// import { uploadFile, getSignedUrl } from "../service/mlAPI";

// const mimeType = 'video/webm; codecs="opus,vp8"';

const Mcq = () => {
  const questionsArray = useRef([]);
  // const mediaRecorder = useRef(null);
  const location = useLocation();
  const optionsArray = useRef([]);
  const allQuestions = useRef([]);
  const timerIdRef = useRef(null);
  // const streamRef = useRef(null);
  const testQuestion = useRef(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(1);
  const [permission, setPermission] = useState(false);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [stream, setStream] = useState(null);
  const [recordedVideo, setRecordedVideo] = useState(null);
  const liveVideoFeed = useRef(null);
  const [count, setCount] = useState(0);
  const [userSelectedOptions, setUserSelectedOptions] = useState([]); // Store user's selected options
  const [lastSelectedOpt, setLastSelectedOpt] = useState(""); // Store user's selected options
  const [timer, setTimer] = useState(60);
  const navigate = useNavigate();
  // const [seconds, setSeconds] = useState(60);
  // const localVideoChunksRef = useRef([]);
  // const [videoChunks, setVideoChunks] = useState([]);
  // const [videoBlob, setVideoBlob] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [url, setUrl] = useState("");

  const fetchData = async () => {
    await getCameraPermission();
    // await startRecording();
    //console.log("after camera permission");
    // Retrieve the state from the previous page
    const state = location.state;
    ////console.log(state);
    allQuestions.current = state.allQuestions;
    startTest();
  };

  useEffect(() => {
    fetchData();
  }, [location]);

  const getCookieAndRole = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));
    if (cookieValue) {
      const tokenWithPrefix = cookieValue.split("=")[1];
      if (tokenWithPrefix.length > 0) {
        const role = tokenWithPrefix[0];
        const token = tokenWithPrefix.slice(1); // Remove the prefix
        return { role, token };
      }
    }
    return { role: null, token: null };
  };

  const handleTimer = () => {
    if (timer === 0) {
      // If the timer reaches 0, move to the next question
      ////console.log("called");
      getNextQuestion();
    } else {
      // If the timer is not yet 0, decrement it by 1 every second
      setTimer(timer - 1);
    }
  };

  useEffect(() => {
    // Start the timer when the test begins
    if (recordingStatus === "recording" && timer > 0) {
      const intervalId = setInterval(handleTimer, 1000);
      // if (timer === 0) {
      //   ////console.log("called");
      //   getNextQuestion();
      // }
      // Clean up the interval when the component unmounts
      return () => clearInterval(intervalId);
    } else {
      // If the test is not recording, reset the timer to 30 seconds
      if (recordingStatus === "recording") {
        getNextQuestion();
      }
      ////console.log("called");
      setTimer(60);
    }
  }, [recordingStatus, timer]);

  const getQuestionsFromOpenAI = async (data) => {
    try {
      const q7Promise = getStage2Excel(data);
      const q8Promise = getStage2Reasoning(data);
      const q6Promise = getStage2Analytical(data);

      // Use Promise.all to fetch questions in parallel
      const [q7, q8, q6] = await Promise.all([q7Promise, q8Promise, q6Promise]);
      allQuestions.current = [...q7, ...q8, ...q6];
      ////console.log(allQuestions.current);
      questionsArray.current = q6;
    } catch (error) {
      ////console.log("Error getting questions from OpenAI", error.message);
    }
  };
  const startTest = async () => {
    setRecordingStatus("recording");
    testQuestion.current = allQuestions.current[0].ques;
    optionsArray.current = allQuestions.current[0].Options;
    ////console.log(optionsArray.current);
  };
  const getNextQuestion = async () => {
    if (currentQuestionIndex >= allQuestions.current.length) {
      setUserSelectedOptions((prevSelectedOptions) => [
        ...prevSelectedOptions,
        selectedOption,
      ]);
      //console.log("No questions available.");
      // stopRecording();
      calculateScore();
      return;
    }
    setTimer(60);
    const nextQuestion = allQuestions.current[currentQuestionIndex];
    setUserSelectedOptions((prevSelectedOptions) => [
      ...prevSelectedOptions,
      selectedOption,
    ]);
    // Update the question and options references
    testQuestion.current = nextQuestion.ques;
    optionsArray.current = nextQuestion.Options;

    ////console.log(userSelectedOptions);
    setCurrentQuestionIndex(currentQuestionIndex + 1);
    setSelectedOption(null);
  };

  const calculateScore = () => {
    let scoreExcel = 0;
    let scoreReasoning = 0;
    let scoreAnalytical = 0;
    let i = 0;
    for (i = 0; i < userSelectedOptions.length; i++) {
      if (userSelectedOptions[i] === allQuestions.current[i].correct) {
        if (i <= 3) {
          scoreExcel++;
        } else if (i > 3 && i <= 5) {
          scoreReasoning++;
        } else {
          scoreAnalytical++;
        }
      }
    }
    if (
      allQuestions.current[userSelectedOptions.length].correct ===
      lastSelectedOpt
    ) {
      scoreAnalytical++;
    }
    let token = getCookieAndRole();
    storeMCQScore(token.token, scoreExcel, scoreReasoning, scoreAnalytical);
    ////console.log(scoreExcel);
    ////console.log(scoreReasoning);
    ////console.log(scoreAnalytical);
    navigate("/insidea");
    setLoading(false);
    return scoreExcel;
  };
  const endTest = async () => {
    setRecordingStatus("inactive");
  };

  const handleOptionSelect = (option) => {
    if (currentQuestionIndex >= allQuestions.current.length) {
      setLastSelectedOpt(option);
    }
    setSelectedOption(option);
  };

  const clearSelectedOption = () => {
    setSelectedOption(null);
  };

  const submitAnswer = () => {
    setSelectedOption(null);
  };

  const getCameraPermission = async () => {
    // let cookieVal = getCookie();
    // await getQuestionsFromOpenAI(cookieVal);
    setRecordedVideo(null);

    if ("MediaRecorder" in window) {
      try {
        const videoConstraints = {
          audio: false,
          video: true,
        };
        const audioConstraints = { audio: true };

        const audioStream = await navigator.mediaDevices.getUserMedia(
          audioConstraints
        );
        const videoStream = await navigator.mediaDevices.getUserMedia(
          videoConstraints
        );

        setPermission(true);

        const combinedStream = new MediaStream([
          ...videoStream.getVideoTracks(),
          ...audioStream.getAudioTracks(),
        ]);

        setStream(combinedStream);

        liveVideoFeed.current.srcObject = videoStream;
      } catch (err) {
        alert(err.message);
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
    }
  };

  // const startRecording = async () => {
  //   // startTimer();
  //   setRecordingStatus("recording");

  //   const media = new MediaRecorder(streamRef.current, { mimeType });
  //   mediaRecorder.current = media;
  //   mediaRecorder.current.start();

  //   mediaRecorder.current.ondataavailable = (event) => {
  //     if (typeof event.data === "undefined") return;
  //     if (event.data.size === 0) return;
  //     localVideoChunksRef.current = [];
  //     localVideoChunksRef.current.push(event.data);
  //   };

  //   setVideoChunks(localVideoChunksRef.current);
  // };

  // const stopRecording = () => {
  //   setPermission(false);
  //   setLoading(true);
  //   setRecordingStatus("inactive");
  //   mediaRecorder.current.stop();

  //   mediaRecorder.current.onstop = async () => {
  //     const vidBlob = new Blob(localVideoChunksRef.current, { type: mimeType });
  //     const videoUrl = URL.createObjectURL(vidBlob);

  //     setRecordedVideo(videoUrl);
  //     setVideoBlob(vidBlob);
  //     setVideoChunks([]);
  //     // resetTimer();
  //     let token = getCookieAndRole();
  //     const response = await getSignedUrl("mcq", token.token);
  //     await AWSUpload(vidBlob, response.url);
  //   };
  // };

  // const AWSUpload = async (Blob, url) => {
  //   //console.log("upload function start");
  //   if (Blob) {
  //     //console.log(url);
  //     await uploadFile(url, Blob);
  //     setUrl(url.split("?")[0]);
  //     //console.log("upload function end");
  //   }
  // };

  const formatTime = (timer) => {
    const minutes = Math.floor(timer / 60);
    const remainingSeconds = timer % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };
  const isBlinking = timer <= 10 && timer % 2 === 0;

  return (
    <div className="min-h-screen bg-gray-100">
      <TestingNavbar
        showAvatar={true}
        handleLogout={() => navigate("/login")}
      />
      <TabSwitch/>

      <div className="flex flex-col items-center justify-center bg-gray-100 min-h-fit min-w-full">
        <img
          src={problem}
          alt="Question vector Image"
          className="max-w-min max-h-min ml-8 mt-2"
        />
        {/* <CountdownTimer initialTime={30} className="max-h-fit" /> */}

        <div
          className={`p-2 border-4 rounded-lg text-center ${
            timer <= 10
              ? isBlinking
                ? "border-red-500 animate-ping"
                : "border-red-500"
              : "border-black"
          }`}
        >
          <div className="text-4xl font-bold text-gray-800">
            {formatTime(timer)}
          </div>
        </div>

        <div className="w-min-8">
          <div>
            {testQuestion.current ? (
              <div>
                <p className="bg-white rounded-3xl shadow-2xl p-4 mx-28 my-8 mt-2">
                  {testQuestion.current}
                </p>
              </div>
            ) : (
              <p className="bg-white rounded-3xl shadow-2xl p-4 m-5 mt-2">
                Loading Questions...
              </p>
            )}
            <div className="px-16">
              {optionsArray.current ? (
                <ul>
                  {optionsArray.current.map((option, index) => (
                    <li key={index} className="px-8 flex justify-center">
                      <button
                        onClick={() => handleOptionSelect(option)}
                        className={`bg-white rounded-2xl shadow-md text-center py-2 px-8 m-2 w-96
              ${
                selectedOption === option || selectedOption === null
                  ? "hover:text-blue-600 hover:border-blue-600 border-2 px-8 border-solid w-96"
                  : ""
              }
              ${
                selectedOption === option ? "border-blue-600 text-blue-600" : ""
              }
            `}
                      >
                        {option}
                      </button>
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>

            <div>
              {recordingStatus === "recording" ? (
                <div className="flex justify-center">
                  <button
                    onClick={getNextQuestion}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 m-2 px-4 rounded-xl"
                  >
                    Next Question
                  </button>
                </div>
              ) : null}
            </div>
          </div>

          <div className="lg:absolute sm:flex sm:justify-center lg:bottom-1 lg:right-10 w-100">
            <video
              ref={liveVideoFeed}
              autoPlay
              className="live-player w-60 lg:relative lg:bottom-10 h-full"
            ></video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mcq;
