import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import JD1 from "../../components/portal/JD1";
import JD2 from "../../components/portal/JD2";
import { getJobDetails } from "../../service/api";
import { Footer } from "../../components/Footer";
import "../../components/customCss/jobDetails.css";
import TopNavigation from "../../components/NavBar/TopNavigation";
import { MagnifyingGlass } from "react-loader-spinner";
import CandidateNavbar from "../../components/NavBar/CandidateNavbar";

const JobDetails = () => {
  const { id } = useParams();

  const inputString = id;
  const startIndex = inputString.indexOf("(") + 1;
  const endIndex = inputString.indexOf(")");

  const finalId = inputString.substring(startIndex, endIndex);
  const [jobs, setJobs] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchJobDetails = async () => {
      setLoading(true);
      try {
        const response = await getJobDetails(finalId);
        setJobs(response.jobDetails);
        ////console.log(jobs);
      } catch (error) {
        console.error("Error fetching job details:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchJobDetails();
  }, [finalId]);

  return (
    <div>
      {loading ? (
        <div className="magnifying-glass-wrapper bg-gray-100 flex flex-col items-center justify-center h-screen">
          <MagnifyingGlass
            visible={true}
            height="160"
            width="160"
            ariaLabel="MagnifyingGlass-loading"
            wrapperStyle={{}}
            wrapperClass="MagnifyingGlass-wrapper"
            glassColor="#c0efff"
            color="#2979FF"
          />
        </div>
      ) : Object.keys(jobs).length > 0 ? ( // Check if jobs has been fetched
        <div className="w-full bg-gray-100">
          <CandidateNavbar />
          <div className="icontainer">
            <div className="box1 rounded-lg">
              <JD1 cardData={jobs} />
            </div>
            <div className="box2 rounded-lg">
              <JD2 cardData={jobs} />
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div>Job details not found</div>
      )}
    </div>
  );
};

export default JobDetails;
