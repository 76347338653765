import React, { useState } from "react";
import hero from "../images/hero.png";
import axios from "axios";
import {
  resetPasswordEmployer,
  sendOTPEmployer,
  signUpEmployer,
} from "../service/api";
import { TopNavigation } from "../components/NavBar/TopNavigation";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MagnifyingGlass } from "react-loader-spinner";
import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";

function Auth() {
  const [showPassword, setShowPassword] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [showForgotPasswordDialog, setShowForgotPasswordDialog] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [resendOtp, setResendOtp] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://hiremeclub-backend.azurewebsites.net/api/v1/loginEmployer/",
        JSON.stringify({ email, password }),
        { headers: { "Content-Type": "application/json" } }
      );
      document.cookie = `token=${response.data.token}`;
      //console.log(response.data);

      if (response.data.success) {
        if (response.data.details) {
          navigate("/emp-dashboard");
        } else {
          navigate("/empDetails");
        }
      } else {
        toast.error("Invalid User Credentials");
      }
      ////console.log(document.cookie);
    } catch (error) {
      ////console.log("Error while login ", error.message);
      return error.response.data;
    }
  };

  const toggleAuthMode = () => {
    setIsRegister(!isRegister); // Toggle between login and register
  };

  // "Forgot Password" feature: Open the dialog
  const openForgotPasswordDialog = () => {
    setShowForgotPasswordDialog(true);
  };

  // "Forgot Password" feature: Close the dialog
  const closeForgotPasswordDialog = () => {
    setShowForgotPasswordDialog(false);
  };

  // Send OTP over mail to Employer for Verification
  const sendOtp = async (e) => {
    e.preventDefault();

    // Check if the email domain is not common
    if (!isCommonEmailDomain(email)) {
      setIsEmailValid(true);

      try {
        setLoading(true);
        const response = await sendOTPEmployer(email);
        if (response.success) {
          setOtpSent(true);
          openForgotPasswordDialog();
          setLoading(false);
          toast.success("OTP Email sent");
        } else {
          setLoading(false);
          toast.error("User Already Registered!");
        }
      } catch (error) {
        console.error("Error sending OTP Email:", error.message);
      }
    } else {
      setIsEmailValid(false);
    }
  };

  //Send Forget Password Email
  const handleForget = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await resetPasswordEmployer(forgotPasswordEmail);

      if (response.success) {
        toast.success("Reset password link has been sent on your email");
        setLoading(false);
      } else {
        toast.error("Failed to send Email. Please try again");
        setLoading(false);
      }
    } catch (error) {
      toast.error("E-Mail doesn't exists!"); 
      setLoading(false);
    }
  };

  //Handle Register of new Employer
  const handleRegister = async (e) => {
    e.preventDefault();

    if (otp.length === 6) {
      try {
        const otpString = otp.join("");
        const response = await signUpEmployer(email, password, otpString);

        // //console.log(response);
        document.cookie = `token=${response.token}`;
        toast.success("Employer Registered Successfully");

        if (response.success) {
          navigate("/empDetails");
        } else {
          toast.error("Invalid Employer Credentials");
        }
      } catch (error) {
        ////console.log("Error while registering ", error.message);
        toast.error("Registration Failed");
        return error.response.data;
      }
    } else {
      console.error("Invalid OTP format.");
      toast.error("Invalid OTP!");
    }
  };

  // Function to check if an email is from a common domain
  const isCommonEmailDomain = (email) => {
    const commonDomains = [
      "gmail.com",
      "yahoo.com",
      "rediffmail.com",
      "hotmail.com",
      "outlook.com",
      "aol.com",
      "protonmail.com",
      "zoho.com",
      "icloud.com",
      "gmx.com",
      "yandex.com",
      "mail.com",
    ];
    const domain = email.split("@")[1];
    return commonDomains.includes(domain);
  };

  return (
    <div className="bg-gray-50 h-screen">
      <TopNavigation showNotification={false} />
      <div>
        <div>
          {loading ? (
            <div className="magnifying-glass-wrapper flex flex-col items-center justify-center h-screen">
              <MagnifyingGlass
                visible={true}
                height="120"
                width="120"
                ariaLabel="MagnifyingGlass-loading"
                wrapperStyle={{}}
                wrapperClass="MagnifyingGlass-wrapper"
                glassColor="#c0efff"
                color="#2979FF"
              />
              <h3 className="text-gray-800 font-Kalam">
                Sending OTP to your Mail
              </h3>
            </div>
          ) : (
            <section className="bg-gray-50  min-h-full pt-16 flex items-center justify-center">
              <div className="md:block hidden w-1/2">
                <img className="rounded-2xl" src={hero} alt="Login" />
              </div>
              <div className="bg-gray-100 flex rounded-2xl shadow-lg max-w-3xl p-2 m-0 items-center">
                <div className="px-2 md:px-16">
                  <h2 className="font-bold text-2xl text-blue-700">
                    {isRegister ? "Recruiter's Register" : "Recruiter's Login"}
                  </h2>
                  <p className="text-xs mt-4 text-blue-700">
                    {isRegister
                      ? "Create a new account"
                      : "If you are already a member, easily log in"}
                  </p>

                  <form
                    action=""
                    onSubmit={isRegister ? sendOtp : handleLogin}
                    className="flex flex-col gap-4"
                  >
                    <input
                      className={`p-2 mt-8 rounded-xl border ${
                        !isEmailValid ? "border-red-500" : ""
                      }`}
                      type="email"
                      name="email"
                      placeholder="Company Email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                    {!isEmailValid && (
                      <p className="text-red-500">
                        Write a valid company email "@companyname"
                      </p>
                    )}
                    <div className="relative">
                      <input
                        className="p-2 rounded-xl border w-full"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                      />
                      {showPassword ? (
                        <IoMdEyeOff
                          className="absolute top-1/2 right-3 -translate-y-1/2 cursor-pointer"
                          width="16"
                          height="16"
                          onClick={togglePasswordVisibility}
                        />
                      ) : (
                        <IoMdEye
                          className="absolute top-1/2 right-3 -translate-y-1/2 cursor-pointer"
                          style={{ transform: "translateY(-50%)" }}
                          width="16"
                          height="16"
                          onClick={togglePasswordVisibility}
                        />
                      )}
                    </div>
                    <button
                      className="bg-blue-700 rounded-xl text-white py-2 hover:scale-105 duration-300"
                      type="submit"
                    >
                      {isRegister ? "Register" : "Login"}
                    </button>
                  </form>

                  <div className="mt-5 text-xs border-b border-blue-700 py-4 text-blue-700">
                    <a href="#" onClick={openForgotPasswordDialog}>
                      Forgot your password?
                    </a>
                  </div>

                  <div className="mt-3 text-xs flex justify-between items-center text-blue-700">
                    <p>
                      {isRegister
                        ? "Already have an account?"
                        : "Don't have an account?"}
                    </p>
                    <button
                      className="py-2 px-5 ml-2 bg-blue-700 text-white border rounded-xl hover:scale-110 duration-300"
                      onClick={toggleAuthMode}
                    >
                      {isRegister ? "Login" : "Register"}
                    </button>
                  </div>
                </div>
              </div>

              {/* "Forgot Password" Dialog */}
              {showForgotPasswordDialog && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-75">
                  <div className="bg-gray-100 p-8 rounded-lg">
                    {!otpSent ? (
                      <>
                        <p className="text-blue-700 text-lg mb-4">
                          Forgot Password
                        </p>
                        <input
                          type="email"
                          className="p-2 rounded-xl border w-full"
                          placeholder="Enter email id"
                          value={forgotPasswordEmail}
                          onChange={(e) =>
                            setForgotPasswordEmail(e.target.value)
                          }
                        />
                        <button
                          className="bg-blue-700 rounded-lg text-white p-2 mx-2 mt-2 hover:scale-105 duration-300"
                          onClick={handleForget}
                        >
                          Send Link
                        </button>
                      </>
                    ) : (
                      <div className="otp-submit">
                        <p className="text-blue-700 text-lg mb-4">Enter OTP</p>
                        <div className="flex gap-2">
                          {[1, 2, 3, 4, 5, 6].map((index) => (
                            <input
                              key={index}
                              type="text"
                              className="p-2 w-10 rounded-xl border text-center"
                              maxLength="1"
                              value={otp[index - 1] || ""}
                              onChange={(e) => {
                                const newOtp = [...otp];
                                newOtp[index - 1] = e.target.value;
                                setOtp(newOtp);
                                if (index < 6 && e.target.value !== "") {
                                  document
                                    .getElementById(`otp-input-${index + 1}`)
                                    .focus();
                                }
                                const isAllBoxesFilled = newOtp.every(
                                  (box) => box !== ""
                                );
                                if (isAllBoxesFilled) {
                                  const otpString = newOtp.join("");
                                }
                              }}
                              onKeyDown={(e) => {
                                if (
                                  e.key === "Backspace" &&
                                  e.target.value === "" &&
                                  index > 1
                                ) {
                                  const prevInput = document.getElementById(
                                    `otp-input-${index - 1}`
                                  );
                                  const newOtp = [...otp];
                                  newOtp[index - 2] = "";
                                  setOtp(newOtp);
                                  prevInput.focus();
                                }
                              }}
                              id={`otp-input-${index}`}
                            />
                          ))}
                        </div>
                        {resendOtp && (
                          <button
                            className="text-blue-700 hover:underline mt-2"
                            onClick={sendOtp}
                          >
                            Resend OTP
                          </button>
                        )}
                        <button
                          className="bg-blue-700 rounded-xl text-white p-2 mt-4 hover:scale-105 duration-300"
                          onClick={handleRegister}
                        >
                          Submit OTP
                        </button>
                      </div>
                    )}
                    <button
                      className="text-blue-700 hover:underline mt-4"
                      onClick={closeForgotPasswordDialog}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </section>
          )}
        </div>
      </div>
    </div>
  );
}

export default Auth;
