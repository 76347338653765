import React, { useState, useEffect, useRef } from "react";
import { TopNavigation } from "../../components/NavBar/TopNavigation";
import Instruct from "../../components/portal/Instruct";
import { useNavigate } from "react-router-dom";
import { getStage3Question } from "../../service/mlAPI";
import TestingNavbar from "../../components/NavBar/TestingNavbar";

export default function InStage3() {
  const DATA = {
    ins1: `Video Resume Round`,
    ins2: "This round requires candidates to create a 2-minute video resume showcasing their achievements, skills, and introduction.",
    ins3: "Time Limit",
    ins4: "Candidates will have 2 minutes to record their video resume.",
    ins5: "Evaluation Criteria",
    ins6: "The video will be evaluated based on the candidate's confidence, fluency, and presentation skills.",
    ins7: "Usage",
    ins8: "The video resume will be utilized for virtual online resume submissions and assessments.",
};

  // const randomQuestion = useRef(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  // const questionsArray = useRef([]);
  const [recordedVideo, setRecordedVideo] = useState(null);
  const navigate = useNavigate();

  const getCookieAndRole = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));
    if (cookieValue) {
      const tokenWithPrefix = cookieValue.split("=")[1];
      if (tokenWithPrefix.length > 0) {
        const role = tokenWithPrefix[0];
        const token = tokenWithPrefix.slice(1); // Remove the prefix
        return { role, token };
      }
    }
    return { role: null, token: null };
  };

  const getCameraPermission = async () => {
    // let cookieVal = getCookie();
    // const ques = await getStage3Question(cookieVal);
    // randomQuestion.current = ques.ques1;
    setRecordedVideo(null);
    if ("MediaRecorder" in window) {
      try {
        const videoConstraints = {
          audio: false,
          video: true,
        };
        const audioConstraints = { audio: true };
        // create audio and video streams separately
        const audioStream = await navigator.mediaDevices.getUserMedia(
          audioConstraints
        );
        const videoStream = await navigator.mediaDevices.getUserMedia(
          videoConstraints
        );

        setButtonDisabled(false); // Enable the button when the streams are obtained
      } catch (err) {
        console.error("Error accessing camera", err.message);
        return;
      }
    } else {
      console.error("The MediaRecorder API is not supported in your browser.");
      // Display an error message on the UI or handle it as needed
      return;
    }
  };

  const handleStartTest = () => {
    navigate("/stage3");
  };

  useEffect(() => {
    getCameraPermission();
  }, []);

  return (
    <div className="bg-gray-100 h-screen">
      <TestingNavbar
        showAvatar={true}
        handleLogout={() => navigate("/login")}
      />
      <div className="px-12">
        <Instruct instructions={DATA} />
        {/* Start Test Button */}
        <div className="mt-4 flex justify-center">
          <button
            disabled={buttonDisabled}
            onClick={handleStartTest}
            className={`${
              buttonDisabled
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-700"
            } text-white font-bold py-2 px-4 rounded`}
          >
            Start Test
          </button>
        </div>
      </div>
    </div>
  );
}
