import React from "react";
export default function PriceCard({
  title,
  description,
  price,
  duration,
  features,
  old,
}) {
  return (
    // <div className="relative group px-6 py-4 rounded-2xl flex flex-col border-4 border-gray-200 items-center w-[330px] md:w-[400px] hover:border-blue-200 hover:scale-105 transition-all duration-300 delay-0 shadow-md justify-start pb-14">
    //   <h3 className="text-blue-400 mb-4 text-2xl">{title}</h3>
    //   <div className="w-full flex justify-center h-fit mb-2">
    //     {title === "LAUNCH OFFER" && (
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         width="75"
    //         height="75"
    //         viewBox="0 0 24 24"
    //         fill="none"
    //         stroke="currentColor"
    //         stroke-width="2"
    //         stroke-linecap="round"
    //         stroke-linejoin="round"
    //         className="lucide lucide-rocket text-gray-500 group-hover:text-blue-500 group-hover:scale-105 transition-all duration-300"
    //       >
    //         <path d="M4.5 16.5c-1.5 1.26-2 5-2 5s3.74-.5 5-2c.71-.84.7-2.13-.09-2.91a2.18 2.18 0 0 0-2.91-.09z" />
    //         <path d="m12 15-3-3a22 22 0 0 1 2-3.95A12.88 12.88 0 0 1 22 2c0 2.72-.78 7.5-6 11a22.35 22.35 0 0 1-4 2z" />
    //         <path d="M9 12H4s.55-3.03 2-4c1.62-1.08 5 0 5 0" />
    //         <path d="M12 15v5s3.03-.55 4-2c1.08-1.62 0-5 0-5" />
    //       </svg>
    //     )}
    //     {title === "PRICING" && (
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         width="75"
    //         height="75"
    //         viewBox="0 0 24 24"
    //         fill="none"
    //         stroke="currentColor"
    //         stroke-width="2"
    //         stroke-linecap="round"
    //         stroke-linejoin="round"
    //         class="lucide lucide-snowflake text-gray-500 group-hover:text-blue-500 group-hover:scale-105 transition-all duration-300"
    //       >
    //         <line x1="2" x2="22" y1="12" y2="12" />
    //         <line x1="12" x2="12" y1="2" y2="22" />
    //         <path d="m20 16-4-4 4-4" />
    //         <path d="m4 8 4 4-4 4" />
    //         <path d="m16 4-4 4-4-4" />
    //         <path d="m8 20 4-4 4 4" />
    //       </svg>
    //     )}
    //   </div>
    //   <p className="font-semibold text-xl flex flex-nowrap mb-2">
    //     ₹<span className="font-bold text-4xl tracking-wider">{price}</span>
    //     <span className="self-end text-sm text-gray-500">/ {duration}</span>
    //   </p>
    //   <hr className="border-1 border-gray-500 w-full mb-4" />
    //   <ul className="px-2 text-md font-medium mb-8">
    //     {features.map((feature) => (
    //       <li>
    //         {feature}
    //       </li>
    //     ))}
    //   </ul>
    //   <button
    //     className="absolute bottom-4 bg-blue-400 w-60 lg:w-80 md:w-80 rounded-lg py-3 font-bold text-white hover:bg-blue-600 transition-all duration-300 delay-0 disabled:cursor-not-allowed outline-none"
    //     disabled={old}
    //   >
    //     Subscribe
    //   </button>
    // </div>
    <div className="w-[330px] md:w-[400px] bg-gray-100 shadow-md h-auto rounded-lg overflow-hidden">
      <div className="flex flex-col bg-blue-100 p-4 gap-1 rounded-lg ">
        <p className="font-sans text-xl font-bold">{title}</p>
        <p className="font-sans text-sm font-medium mb-2">{description}</p>
        <p className="flex flex-nowrap items-center gap-1 mb-4">
          <span className="text-4xl font-medium">₹{price}</span>
          <span className="text-sm font-medium">/ {duration}</span>
        </p>
        <div className="relative"></div>
        <button
          className="bg-white text-blue-500 w-full rounded-lg py-3 font-bold hover:bg-light-blue-500 hover:text-white transition-all duration-300 ease-in-out delay-0 disabled:cursor-not-allowed outline-none"
          disabled={old}
        >
          Subscribe
        </button>
      </div>
      <ul className="px-2 py-4 list-none">
        {features.map((feature, index) => (
          <li
            className="flex items-start gap-4 text-md font-medium mb-4"
            key={index}
          >
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-check pt-2 scale-150"><path d="M20 6 9 17l-5-5"/></svg> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="1.125"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-circle-check pt-1 scale-[2]"
            >
              <circle cx="12" cy="12" r="10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
            <span>{feature}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}
