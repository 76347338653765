import React,{ useState, useEffect } from "react";
import { useAuth } from "./AuthProvider";
import { Outlet } from "react-router-dom";
import MagnifyingGlassComponent from '../Loader/MagnifyingGlass'
import { MagnifyingGlass } from "react-loader-spinner";

function EmpPrivateRoute() {
    const { isLoggedIn, loading , user} = useAuth();
    
    if(loading){
      return  <div className="magnifying-glass-wrapper flex items-center justify-center h-screen">

      <MagnifyingGlass
      visible={true}
      height="160"
      width="160"
      ariaLabel="MagnifyingGlass-loading"
      wrapperStyle={{}}
      wrapperClass="MagnifyingGlass-wrapper"
      glassColor = '#c0efff'
      color = '#2979FF'
    />
    </div>;
    }
  return (isLoggedIn && user === 'EmployerLoggedIn') ? <Outlet/> : <MagnifyingGlassComponent destination="/emp-login"/>;
}

export default EmpPrivateRoute