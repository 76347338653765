import React from "react";
import Auth from "./Auth"; // Import the modified component
import { TopNavigation } from "../components/NavBar/TopNavigation";


function Login() {
  return (
    <div className="bg-gray-50 h-screen">
      <TopNavigation  showNotification={false}/>
      <Auth />
    </div>
  );
}

export default Login;
