import resumeWithQr from "../../images/parameters/resume_with_qr.png";
import resumeWithoutQr from "../../images/parameters/resume_without_qr.png";
import React, { useState, useEffect } from "react";

const parameters = [
  {
    id: 1,
    section: "subject knowledge",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="lucide lucide-book"
      >
        <path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H19a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H6.5a1 1 0 0 1 0-5H20" />
      </svg>
    ),
  },
  {
    id: 2,
    section: "real life situation",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="lucide lucide-globe"
      >
        <circle cx="12" cy="12" r="10" />
        <path d="M12 2a14.5 14.5 0 0 0 0 20 14.5 14.5 0 0 0 0-20" />
        <path d="M2 12h20" />
      </svg>
    ),
  },
  {
    id: 3,
    section: "excel knowledge",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="lucide lucide-bar-chart-2"
      >
        <line x1="18" x2="18" y1="20" y2="10" />
        <line x1="12" x2="12" y1="20" y2="4" />
        <line x1="6" x2="6" y1="20" y2="14" />
      </svg>
    ),
  },
  {
    id: 4,
    section: "communication skills",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="lucide lucide-message-square"
      >
        <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
      </svg>
    ),
  },
  {
    id: 5,
    section: "analytical skills",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="lucide lucide-activity"
      >
        <path d="M22 12h-2.48a2 2 0 0 0-1.93 1.46l-2.35 8.36a.25.25 0 0 1-.48 0L9.24 2.18a.25.25 0 0 0-.48 0l-2.35 8.36A2 2 0 0 1 4.49 12H2" />
      </svg>
    ),
  },
  {
    id: 6,
    section: "confidence level",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="lucide lucide-user-check"
      >
        <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
        <circle cx="9" cy="7" r="4" />
        <polyline points="16 11 18 13 22 9" />
      </svg>
    ),
  },
  {
    id: 7,
    section: "problem solving skills",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="lucide lucide-pencil-ruler"
      >
        <path d="M13 7 8.7 2.7a2.41 2.41 0 0 0-3.4 0L2.7 5.3a2.41 2.41 0 0 0 0 3.4L7 13" />
        <path d="m8 6 2-2" />
        <path d="m18 16 2-2" />
        <path d="m17 11 4.3 4.3c.94.94.94 2.46 0 3.4l-2.6 2.6c-.94.94-2.46.94-3.4 0L11 17" />
        <path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z" />
        <path d="m15 5 4 4" />
      </svg>
    ),
  },
  {
    id: 8,
    section: "creativity",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="lucide lucide-star"
      >
        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
      </svg>
    ),
  },
];
const Parameters = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-8 lg:pb-28 md:pb-32">
      <div className="text-left md:text-center px-4 flex flex-col md:items-center">
        <div>
          <p className="inline-block px-3 py-px text-sm font-bold tracking-wider text-blue-800 rounded-full bg-blue-100">
            Proof-of-Work
          </p>
        </div>
        <h2 className="max-w-lg mb-1 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          Parameters
        </h2>
        <p className="text-justify md:text-center text-gray-700 md:text-lg md:w-1/2 mb-6">
          Discover how our AI crafts resumes based on seven key parameters,
          showcasing candidates' strengths effectively and professionally.
        </p>
      </div>
      {!isMobile && (
        <div className=" w-full flex flex-col flex-wrap items-center gap-4 justify-center">
          <div className="relative flex-1 w-[250px] h-[400px] md:w-[400px] md:h-[500px] lg:w-[400px] lg:h-[500px] rounded-xl">
            <img
              src={isHovered ? resumeWithoutQr : resumeWithQr}
              className="w-full h-full object-fill md:object-contain lg:object-contain rounded-xl transition-all ease-in duration-500 hover:[transform:rotateY(360deg)]"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            />
            {/* SECTION 1 */}
            <div className="w-8 h-8 absolute -left-3 top-20 hidden md:block">
              <img src="https://assets-global.website-files.com/62c67bbf65af229e9075fedf/6558deae62b0964141e7c62a_pointer-left-blue.svg" />
            </div>
            <div className="absolute lg:top-5 lg:w-40 lg:left-[-10.7rem] md:w-32 md:-left-[8.7rem] md:top-0 border-indigo-accent-200 border-2 rounded-lg p-2 hidden md:block">
              <h3 className="font-bold text-md text-indigo-accent-200 md:text-sm pb-1">
                CANDIDATE DETAILS
              </h3>
              <p className="text-sm font-semibold md:text-xs">
                This section contains the candidate's contact information,
                including their email, name, and date of birth.
              </p>
            </div>
            {/* SECTION 2 */}
            <div className="w-8 h-8 absolute -left-3 top-52 hidden md:block">
              <img src="https://assets-global.website-files.com/62c67bbf65af229e9075fedf/6558deae62b0964141e7c62a_pointer-left-blue.svg" />
            </div>
            <div className="absolute lg:top-40 lg:w-40 lg:left-[-10.7rem] md:w-32 md:-left-[8.7rem] md:top-48 border-indigo-accent-200 border-2 rounded-lg p-2 hidden md:block">
              <h3 className="font-bold text-md text-indigo-accent-200 md:text-sm pb-1">
                SKILLS
              </h3>
              <p className="text-sm font-semibold md:text-xs">
                This section highlights the candidate's professional skills and
                areas of expertise.
              </p>
            </div>
            {/* SECTION 3 */}
            <div className="w-8 h-8 absolute -left-3 lg:top-[21rem] md:top-[23rem] hidden md:block">
              <img src="https://assets-global.website-files.com/62c67bbf65af229e9075fedf/6558deae62b0964141e7c62a_pointer-left-blue.svg" />
            </div>
            <div className="absolute lg:top-72 lg:w-40 lg:left-[-10.7rem] md:w-32 md:-left-[8.7rem] md:top-[22rem] border-indigo-accent-200 border-2 rounded-lg p-2 hidden md:block">
              <h3 className="font-bold text-md text-indigo-accent-200 md:text-sm pb-1x">
                EDUCATION
              </h3>
              <p className="text-sm font-semibold md:text-xs">
                This section provides details about the candidate's educational
                background and qualifications.
              </p>
            </div>

            {/* SECTION 4 */}
            <div className="w-8 h-8 absolute -right-3 top-16 hidden md:block">
              <img
                src="https://assets-global.website-files.com/62c67bbf65af229e9075fedf/6558deae62b0964141e7c62a_pointer-left-blue.svg"
                className="rotate-180"
              />
            </div>
            <div className="absolute lg:top-4 lg:w-48 lg:right-[-12.7rem] md:w-36 md:-right-[9.7rem] md:-top-2   border-indigo-accent-200 border-2 rounded-lg p-2 hidden md:block">
              <h3 className="font-bold text-md text-indigo-accent-200 md:text-sm pb-1">
                EXPERIENCE
              </h3>
              <p className="text-sm font-semibold md:text-[0.7rem] leading-3">
                This section summarizes the candidate's previous work experience
                and roles.
              </p>
            </div>
            {/* SECTION 5 */}
            <div className="w-8 h-8 absolute -right-3 top-[11rem] hidden md:block">
              <img
                src="https://assets-global.website-files.com/62c67bbf65af229e9075fedf/6558deae62b0964141e7c62a_pointer-left-blue.svg"
                className="rotate-180"
              />
            </div>
            <div className="absolute lg:top-[6.75rem] lg:w-48 lg:right-[-12.7rem] md:w-36 md:-right-[9.7rem] md:top-[6.5rem] border-indigo-accent-200 border-2 rounded-lg p-2 hidden md:block">
              <h3 className="font-bold text-md text-indigo-accent-200 md:text-sm pb-1">
                RESPONSIBILITIES
              </h3>
              <p className="text-sm font-semibold md:text-[0.7rem] leading-3">
                This section outlines the key responsibilities held by the
                candidate in their previous positions.
              </p>
            </div>
            {/* SECTION 6 */}
            <div className="w-8 h-8 absolute -right-3 top-[16.5rem] hidden md:block">
              <img
                src="https://assets-global.website-files.com/62c67bbf65af229e9075fedf/6558deae62b0964141e7c62a_pointer-left-blue.svg"
                className="rotate-180"
              />
            </div>
            <div className="absolute lg:top-52 lg:w-48 lg:right-[-12.7rem] md:w-36 md:-right-[9.7rem] md:top-52 border-indigo-accent-200 border-2 rounded-lg p-2 hidden md:block">
              <h3 className="font-bold text-md text-indigo-accent-200 md:text-sm pb-1">
                ACHIEVEMENTS
              </h3>
              <p className="text-sm font-semibold md:text-[0.7rem] leading-3">
                This section highlights significant accomplishments and
                recognitions received by the candidate.
              </p>
            </div>
            {/* SECTION 7 */}
            <div className="w-8 h-8 absolute -right-3 top-[21rem] hidden md:block">
              <img
                src="https://assets-global.website-files.com/62c67bbf65af229e9075fedf/6558deae62b0964141e7c62a_pointer-left-blue.svg"
                className="rotate-180"
              />
            </div>
            <div className="absolute lg:top-[19.5rem] lg:w-48 lg:right-[-12.7rem] md:w-36 md:-right-[9.7rem] md:top-80 border-indigo-accent-200 border-2 rounded-lg p-2 hidden md:block">
              <h3 className="font-bold text-md text-indigo-accent-200 md:text-sm pb-1">
                CERTIFICATIONS
              </h3>
              <p className="text-sm font-semibold md:text-[0.7rem] leading-3">
                This section lists relevant certifications and professional
                achievements of the candidate.
              </p>
            </div>
            {/* SECTION 8 */}
            <div className="w-8 h-8 absolute -right-3 lg:top-[26rem] md:top-[27rem] hidden md:block">
              <img
                src="https://assets-global.website-files.com/62c67bbf65af229e9075fedf/6558deae62b0964141e7c62a_pointer-left-blue.svg"
                className="rotate-180"
              />
            </div>
            <div className="absolute lg:top-[25rem] lg:w-48 lg:right-[-12.7rem] md:w-36 md:-right-[9.7rem] md:top-[27rem] border-indigo-accent-200 border-2 rounded-lg p-2 hidden md:block">
              <h3 className="font-bold text-md text-indigo-accent-200 md:text-sm pb-1">
                PROJECTS
              </h3>
              <p className="font-semibold lg:font-semibold md:text-[0.7rem] leading-3">
                This section showcases notable projects the candidate has worked
                on and their contributions.
              </p>
            </div>
            {/* SECTION 9 */}
            <div className="w-8 h-8 absolute top-[35rem] left-[11.5rem] hidden md:block">
              <img
                src="https://assets-global.website-files.com/62c67bbf65af229e9075fedf/6558deae62b0964141e7c62a_pointer-left-blue.svg"
                className="-rotate-90"
              />
            </div>
            <div className="absolute lg:top-[36.4rem] lg:w-48 lg:left-[6.5rem] md:w-36 md:left-[8rem] md:top-[36.4rem] border-indigo-accent-200 border-2 rounded-lg p-2 hidden md:block">
              <h3
                className={`font-bold text-md text-indigo-accent-200 md:text-sm ${
                  isHovered ? "text-left pb-1" : "text-center"
                }`}
              >
                {isHovered
                  ? "CANDIDATE SCORE"
                  : "HOVER TO VIEW CANDIDATE SCORE"}
              </h3>
              <p className="font-semibold lg:text-md md:text-[0.7rem] leading-3">
                {isHovered
                  ? "This section presents the candidate's resume score, evaluated based on eight different parameters."
                  : ""}
              </p>
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <div className="h-full flex-1 flex flex-col justify-center items-center w-full p-4 gap-4">
          <img
            src={isHovered ? resumeWithoutQr : resumeWithQr}
            className="h-96 border-2 border-gray-700/30 object-contain bg-gray-700/10 p-2 rounded-xl transition-all ease-in duration-700 hover:[transform:rotateY(360deg)]"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          />
          <h1 className="text-lg text-left w-full">
            Various parameter in Resume :
          </h1>
          <div className="w-full flex flex-col gap-2">
            {parameters.map((p) => (
              <div
                className="bg-blue-100 w-full rounded-lg p-4 flex items-center justify-between shadow-md hover:bg-blue-200 transition-colors duration-300"
                key={p.id}
              >
                <div className="flex items-center gap-2">
                  {p.icon}
                  <h1 className="text-lg font-semibold">{p.section}</h1>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Parameters;
