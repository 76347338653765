import React, { useState } from "react";

const ScheduleInterviewModal = ({ closeModal, handleScheduled }) => {
  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [error, setError] = useState("");

  function handleSchedule() {
    if (!date || !startTime || !endTime) {
      setError("Please fill in all fields");
      return;
    }

    const startDateTime = new Date(`${date} ${startTime}`);
    const endDateTime = new Date(`${date} ${endTime}`);

    if (startDateTime >= endDateTime) {
      setError("End time must be after start time");
      return;
    }

    setError("");
    handleScheduled();
    closeModal();
  }

  return (
    <div className="fixed z-50 inset-0 overflow-y-auto h-screen" id="myModal">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-800 opacity-60"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-blue-400 px-4 py-5 sm:px-6">
            <h3 className="text-lg font-medium text-white">
              Schedule Interview
            </h3>
          </div>
          <div className="px-4 py-5 sm:p-6">
            <div className="mb-4">
              <label
                htmlFor="datepicker"
                className="block text-sm font-medium text-gray-700"
              >
                Select Date (DD/MM/YYYY)
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  type="date"
                  id="datepicker"
                  value={date}
                  onChange={(e) => {
                    setDate(e.target.value);
                    setError("");
                  }}
                  className="p-4 bg-gray-100 focus:ring-blue-400 focus:border-blue-400 focus:border-2 outline-none block w-full sm:text-sm border-gray-300 border-2 rounded-md"
                />
              </div>
            </div>
            <div className="mb-4">
              <div className="flex justify-between">
                <div className="w-1/2 pr-2">
                  <label
                    htmlFor="start-time"
                    className="block text-sm font-medium text-gray-700 pb-1"
                  >
                    Start Time
                  </label>
                  <input
                    type="time"
                    id="start-time"
                    value={startTime}
                    onChange={(e) => {
                      setStartTime(e.target.value);
                      setError("");
                    }}
                    className="p-4 bg-gray-100 focus:ring-blue-400 focus:border-blue-400 focus:border-2 outline-none block w-full sm:text-sm border-gray-300 border-2 rounded-md"
                  />
                </div>
                <div className="w-1/2 pl-2">
                  <label
                    htmlFor="end-time"
                    className="block text-sm font-medium text-gray-700 pb-1"
                  >
                    End Time
                  </label>
                  <input
                    type="time"
                    id="end-time"
                    value={endTime}
                    onChange={(e) => {
                      setEndTime(e.target.value);
                      setError("");
                    }}
                    className="p-4 bg-gray-100 focus:ring-blue-400 focus:border-blue-400 focus:border-2 outline-none block w-full sm:text-sm border-gray-300 border-2 rounded-md"
                  />
                </div>
              </div>
            </div>
            {error && <p className="text-red-500">{error}</p>}
          </div>
          <div className="bg-gray-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-400 text-base font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400 sm:ml-3 sm:w-auto sm:text-sm disabled:cursor-not-allowed"
              disabled={!date || !startTime || !endTime || error}
              onClick={handleSchedule}
            >
              Schedule
            </button>
            <button
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm "
              onClick={closeModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleInterviewModal;
