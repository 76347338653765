import React from "react";

function RedCrossIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 50 50">
  <circle cx="25" cy="25" r="23" fill="#E5141E" />
  <path d="M15 15L35 35" fill="none" stroke="#FFFFFF" strokeWidth="5" />
  <path d="M15 35L35 15" fill="none" stroke="#FFFFFF" strokeWidth="5" />
</svg>

  );
}

export default RedCrossIcon;
