import React, { useEffect, useState } from "react";
import Card from "../../components/portal/Card";
import { getWishlistedJobs } from "../../service/api";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { MagnifyingGlass } from "react-loader-spinner";

function Wishlist() {
  const [wishlistedJobs, setWishlistedJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [jobsPerPage] = useState(6);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchWishlistedJobs = async () => {
      setLoading(true);
      try {
        const token = getCookieAndRole();
        const response = await getWishlistedJobs(token.token);
        if (response.success) {
          setWishlistedJobs(response.shortlistedJobs);
        } else {
          console.error("API request unsuccessful:", response);
        }
      } catch (error) {
        console.error("Error fetching wishlisted jobs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchWishlistedJobs();
  }, []);

  const getCookieAndRole = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));
    if (cookieValue) {
      const tokenWithPrefix = cookieValue.split("=")[1];
      if (tokenWithPrefix.length > 0) {
        const role = tokenWithPrefix[0];
        const token = tokenWithPrefix.slice(1); // Remove the prefix
        return { role, token };
      }
    }
    return { role: null, token: null };
  };


  // Get current jobs
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = wishlistedJobs.slice(indexOfFirstJob, indexOfLastJob);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      {/* Wishlist Page of Jobs with Filter */}
      <div className="h-screen mb-2 inner-container lg:col-span-3 md:col-span-3 bg-gray-100 lg:mt-6">
        {/* Wishlist Job Cards */}
        {loading && (
          <div className="magnifying-glass-wrapper flex items-center justify-center h-screen">
            <MagnifyingGlass
              visible={true}
              height="160"
              width="160"
              ariaLabel="MagnifyingGlass-loading"
              wrapperStyle={{}}
              wrapperClass="MagnifyingGlass-wrapper"
              glassColor="#c0efff"
              color="#2979FF"
            />
          </div>
        )}
        {!loading && wishlistedJobs.length === 0 && (
          <h1>No Wishlisted Jobs Found</h1>
        )}

        <div className="flex justify-center m-2 items-center h-screen bg-gray-100">
          {/* Wishlist Job Cards */}
          <div className=" h-screen mb-2 inner-container lg:row-span-3 md:row-span-3 bg-gray-100 lg:mt-6">
            <div className="content grid grid-cols-3 gap-y-2 gap-x-8 lg:mr-2">
              {currentJobs && currentJobs.length > 0 ? (
                currentJobs.map((job, index) => (
                  <div key={index} className="">
                    <Card cardData={job} navigate={navigate} id={job._id} />
                  </div>
                ))
              ) : (
                <p>No wishlisted jobs to display.</p>
              )}
            </div>
            <div className="buttons w-100 flex justify-between lg:mt-3">
              {/* Previous Page Button */}
              <button
                disabled={currentPage === 1}
                className="mr-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={() => paginate(currentPage - 1)}
              >
                <MdArrowBackIos />
              </button>

              {/* Next Page Button */}
              <button
                disabled={currentPage * jobsPerPage >= wishlistedJobs.length}
                className="ml-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={() => paginate(currentPage + 1)}
              >
                <MdArrowForwardIos />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Wishlist;
