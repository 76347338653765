import React, { useState, useEffect } from "react";
import "../customCss/applicant.css";
import { getAllJobApplications, getCandidateStats } from "../../service/api";
import { format } from "date-fns";
import { MagnifyingGlass } from "react-loader-spinner";

const Applicant = () => {
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [stats, setStats] = useState([]);
  useEffect(() => {
    const token = getCookieAndRole();
    getAllJobApplications(token.token)
      .then((data) => setAppliedJobs(data))
      .catch((error) => console.error("Error fetching data:", error));
    const fetchStats = async () => {
      try {
        const stats = await getCandidateStats(token.token);
        // ////console.log(stats);
        setStats(stats);
      } catch (error) {
        console.error("Error fetching stats", error);
      }
    };

    fetchStats();
  }, []);
  const getCookieAndRole = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));
    if (cookieValue) {
      const tokenWithPrefix = cookieValue.split("=")[1];
      if (tokenWithPrefix.length > 0) {
        const role = tokenWithPrefix[0];
        const token = tokenWithPrefix.slice(1); // Remove the prefix
        return { role, token };
      }
    }
    return { role: null, token: null };
  };

  return (
    <div className="flex-col p-2 contain rounded-lg bg-white">
      <div className="text-4xl heading text-gray-700 mb-8 font-semibold">
        Applicant's Dashboard <span className="text-blue-600">Overview</span>
      </div>

      {/*Stats of the applicant's in one line with boxes*/}
      {stats.data ? (
        <div className="responsive-box flex flex-wrap mb-7 sm:space-x-4">
          <div className="bg-cyan-300 box rounded-lg shadow-2xl flex-1 h-44 relative">
            <div className="text-gray-50 text-lg sm:text-xl pl-3 pt-3">
              Total no of Applications
            </div>
            <div className="text-gray-50 text-5xl p-3 absolute bottom-0 right-0">
              {stats.data.totalJobApplications}
            </div>
          </div>
          <div className="bg-cyan-300 box rounded-lg shadow-2xl flex-1 h-44 relative">
            <div className="text-gray-50 text-lg sm:text-xl p-3">
              No of Active Applications
            </div>
            <div className="text-gray-50 text-5xl p-3 absolute bottom-0 right-0">
              {stats.data.totalJobListingsWithActiveStatus}
            </div>
          </div>
          <div className="bg-cyan-300 box rounded-lg shadow-2xl flex-1 h-44 relative">
            <div className="text-gray-50 text-lg sm:text-xl pl-3 pt-3">
              Shortlisted Applications
            </div>
            <div className="text-gray-50 text-5xl p-3 absolute bottom-0 right-0">
              {stats.data.totalShortlistedJobListings}
            </div>
          </div>
          <div className="bg-cyan-300 box rounded-lg shadow-2xl flex-1 h-44 relative">
            <div className="text-gray-50 text-lg sm:text-xl pl-3 pt-3">
              In-touch
            </div>
            <div className="text-gray-50 text-5xl p-3 absolute bottom-0 right-0">
              {stats.data.noOfInterviews}
            </div>
          </div>
        </div>
      ) : (
        <div className="magnifying-glass-wrapper flex items-center justify-center h-full">
          <MagnifyingGlass
            visible={true}
            height="160"
            width="160"
            ariaLabel="MagnifyingGlass-loading"
            wrapperStyle={{}}
            wrapperClass="MagnifyingGlass-wrapper"
            glassColor="#c0efff"
            color="#2979FF"
          />
        </div>
      )}

      <hr className="border-t  border-gray-300"></hr>
      <div className="mx-auto tables">
        <div className="max-h-[350px] overflow-y-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 text-center font-semibold text-xl border-b-2 border-gray-300">
                  Company
                </th>
                <th className="py-2 px-4 text-center text-l border-b-2 border-gray-300">
                  Role
                </th>
                <th className="py-2 px-4 text-center text-l border-b-2 border-gray-300">
                  Date Applied
                </th>
                <th className="py-2 px-4 text-center text-l border-b-2 border-gray-300">
                  Salary
                </th>
                <th className="py-2 px-4 text-center text-l border-b-2 border-gray-300">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {appliedJobs.map((job, index) => (
                <tr key={index}>
                  <td className="py-3 px-4 text-center">
                    {job.job_listing.companyName &&
                    job.job_listing.companyName.brandName
                      ? job.job_listing.companyName.brandName
                      : "null"}
                  </td>
                  <td className="py-3 px-4 text-center">
                    {job.job_listing.jobTitle || "null"}
                  </td>
                  <td className="py-3 px-4 text-center">
                    {job.updatedAt
                      ? format(new Date(job.updatedAt), "dd-MMM-yyyy")
                      : "null"}
                  </td>
                  <td className="py-3 px-4 text-center">
                    {job.job_listing.salary || "null"}
                  </td>
                  <td
                    className={`py-3 px-4 text-center ml-24 ${
                      job.status === "Interview"
                        ? "bg-green-400"
                        : job.status === "pending"
                        ? "bg-yellow-600"
                        : "bg-red-400"
                    } rounded-3xl w-40`}
                  >
                    {job.status || "null"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="md:hidden mt-6">&nbsp;</div>
    </div>
  );
};

export default Applicant;
