import React, { useEffect } from 'react';
import TopNavigation from '../components/NavBar/TopNavigation';
import { Footer } from '../components/Footer';
import error from '../images/error.png';
import {useNavigate } from 'react-router-dom';

function ErrorPage() {
  
  return (
    <div className='bg-gray-100 w-full'>
      <TopNavigation  showNotification={false} />

      <div className='flex justify-center items-center h-full'>
        {/* Text Content */}
        <div className='text-center'>
          <h1 className='text-red-500 font-Kalam text-8xl'>ERROR</h1>
          <h2 className='text-black font-Kalam text-4xl mb-4'>Page not found</h2>
        </div>

        {/* Image Section */}
        <div className='ml-4'>
          <img src={error} alt='Error Page 404' className='w-1/2' />
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ErrorPage;
