import React, { useState } from "react";
import img1 from "../../images/hero.png";
import upArrow from "../../images/upArrow.svg";
import { Link } from "react-router-dom";
import YouTube from "../YouTube";

export const  Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const youtubeVideoId = "rxq16QfcCuM";

  const openModal = () => {
    setIsModalOpen(true);
    //console.log("Open MOdal CLicked");
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="bg-blue-50 h-fit w-full">
      <div className="relative md:ml-10 lg:pt-24 lg:pb-28 xl:px-20 sm:max-w-xl md:max-w-full ">
        <div className="flex justify-center h-fit overflow-hidden xl:w-3/4 lg:w-3/5 lg:absolute lg:justify-center lg:bottom-16 lg:right-0 lg:items-end">
          <img src={img1} className="" alt="intern hiring Process" />
        </div>
        <div className="max-w-xl mx-auto lg:max-w-screen-xl z-10">
          <div className="mb-16 lg:max-w-lg lg:mb-0">
            <div className="max-w-xl mb-6 px-2">
              <h1 className="max-w-lg mb-6 font-sans text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl sm:leading-none">
                Hire Candidates in
                <span className="inline-block text-blue-accent-400 ml-2">
                  24 Hours.
                </span>
              </h1>
              <p className="text-base text-gray-700 md:text-lg">
                Revolutionize your hiring process with our AI-based shortlisting
                startup.
              </p>

              {/* 1. Add Get Verified Proof-of-Work */}
              <div className="flex flex-row mb-2">
                <h3 className="text-lg mt-6 text-gray-700 md:text-4xl">Get </h3>

                <span className="text-blue-accent-400 text-xl transform absolute ml-8 -skew-x-6 sm:text-xl  -skew-y-6 font-Kalam">
                  Verified
                </span>

                <img
                  width="30"
                  className="relative top-6 right-1  transform rotate-90"
                  src={upArrow}
                  alt="arrow"
                />

                <h3 className="text-lg font-bold mt-6 text-gray-700 md:text-4xl">
                  Proof-of-Work{" "}
                </h3>
              </div>
              {/* absolute  transform -translate-x-1 -translate-y-1 -skew-x-12 -skew-y-6 */}

              {/* 2. Hire in just 4 clicks */}
              <p className="text-base text-gray-700 md:text-lg">
                Hire in just 4 clicks.
              </p>

              {/* Button - How it Works with YouTube Video */}
              <div className="flex space-x-2 p-4 items-center">
                {/* 3. Blue Button with Hover Effect */}
                <Link to="/emp-login">
                  <button className="inline-block bg-blue-accent-400 hover:bg-blue-accent-500 text-white font-semibold py-3 px-6 rounded-lg transition-transform transform hover:scale-105 ease-in duration-300">
                    Get Started
                  </button>
                </Link>

                <span className="text-black text-sm font-semibold bg-white rounded-3xl p-1 px-2">
                  How it works?
                </span>
                <div>
                  <button
                    className="p-2 transition ease-in-out delay-150 cursor-pointer rounded-full bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-blue-200 duration-300"
                    onClick={openModal}
                  >
                    {/* YouTube Play Button Icon */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 3l14 9-14 9V3z"
                      />
                    </svg>
                  </button>
                  {isModalOpen && (
                    <YouTube videoId={youtubeVideoId} onClose={closeModal} />
                  )}
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
