import React from "react";
import { useNavigate } from "react-router-dom";
import TestingNavbar from "../../components/NavBar/TestingNavbar";
import Instruct from "../../components/portal/Instruct";

const Instruction = () => {
  const navigate = useNavigate();
  const DATA = {
    ins1: "Stage 1: Role Related Questions",
    ins2 : "Consists of 2 role-related questions focusing on areas like Sales, HR, Marketing, etc.",
    ins3: "Stage 2: Multiple Choice Questions (MCQ)",
    ins4 : "Includes 12 MCQ questions covering topics such as Data Analysis, Excel, and Problem Solving.",
    ins5 : "Stage 3: Real-Life Situation Questions",
    ins6 : "Features 2 questions presenting real-life scenarios to assess candidates' problem-solving skills.",
    ins7 : "Video Resume Round:",
    ins8 : "You will have 1 minute to record your achievements, skills, and a brief introduction about yourself.",
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <TestingNavbar
        isSticky={false}
        showAvatar={true}
        handleLogout={() => navigate("/login")}
      />
      <div className="px-12 py-4">
        <Instruct instructions={DATA} />
        <div className="mt-4 flex justify-center">
          <button
            onClick={() => navigate("/role")}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg"
          >
            Start Test
          </button>
        </div>
      </div>
    </div>
  );
};

export default Instruction;
