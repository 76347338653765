import React, { useState } from "react";

const YouTube = ({ videoId, onClose }) => {
  const [showModal, setShowModal] = useState(true);

  const closeModal = () => {
    setShowModal(false);
    onClose();
  };

  // return (
  //   <div className={`fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 ${showModal ? 'block' : 'hidden'}`}>
  //     <div className="absolute w-full h-full bg-gray-800 opacity-75"></div>
  //     <div className="w-full md:max-w-screen-md mx-auto rounded shadow-lg z-50 overflow-y-auto bg-gray-300 relative">
  //       <div className="absolute top-0 right-0 p-4 cursor-pointer">
  //         <span className='text-2xl md:text-3xl text-black md:text-black absolute top-0 right-0' onClick={closeModal}>&times;</span>
  //       </div>
  //       <div className="p-4 flex items-center justify-center">
  //         <iframe
  //           title="YouTube Video"
  //           width="650"
  //           height="315"
  //           src={`https://www.youtube.com/embed/${videoId}`}
  //           allowFullScreen
  //         ></iframe>
  //       </div>
  //     </div>
  //   </div>
  // );
  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 px-2 ${
        showModal ? "block" : "hidden"
      }`}
    >
      <div className="absolute w-full h-full bg-gray-800 opacity-80"></div>
      <div className="w-full lg:min-w-[900px] md:mx-3 md:max-w-screen-md mx-auto rounded-lg shadow-lg z-50 overflow-y-auto bg-gray-300 relative">
        <div className="flex justify-between items-end p-4">
          <h2>Hireme<span className="text-blue-500">Club</span></h2>
          <button
            className="text-2xl md:text-3xl text-black cursor-pointer font-bold hover:text-gray-600 transition-all duration-700 ease-in-out"
            onClick={closeModal}
          >
            &times;
          </button>
        </div>
        <div className="pt-2 pb-4 px-2  lg:px-4 flex items-center justify-center w-full lg:min-w-[650px]">
          <iframe
            title="YouTube Video"
            src={`https://www.youtube.com/embed/${videoId}`}
            allowFullScreen
            className="w-full h-full min-h-[420px] rounded-lg"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default YouTube;
