import React from 'react';

const TickIcon = () => (
    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 50 50"
                  >
                    <circle cx="25" cy="25" r="23" fill="#00CC66" />
                    <path
                      d="M18 25l6 6 12-12"
                      fill="none"
                      stroke="#fff"
                      strokeWidth="5"
                    />
                  </svg>
);

export default TickIcon;
