import cv from "../../images/cv.png";
import assignment from "../../images/videointerview.png";
import proof from "../../images/proof.png";
import interview from "../../images/4click.png";

export const Steps = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <div>
          <p className="inline-block px-3 py-px mb-1 text-sm font-bold trackng-wider text-red-800  rounded-full bg-red-100">
            Brand New
          </p>
        </div>
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          Verify your <span className="text-blue-600">Proof-of-Work</span>
          <br />
          <span className="text-blue-400">in 3 Quick Easy Steps</span>
        </h2>
        <p className="text-base text-gray-700 md:text-lg">
          Experience an advanced hiring process with our AI-powered 3-step
          shortlisting approach designed to bring you the best and most talented
          candidates for your business needs.
        </p>
      </div>
      <div className="grid gap-10 lg:grid-cols-4 sm:grid-cols-2">
        <div className="p-3 border-solid border-4 hover:border-blue-600 border-gray-200 transition-transform transform hover:scale-105 hover:shadow-lg">
          <div className="flex flex-col items-center justify-between mb-6">
            <div className="w-2/3">
              <img src={cv} alt="CV Icon" />
            </div>
            <p className="text-2xl font-bold w-2/3">Resume Shortlisting</p>
            <svg
              className="w-6 text-gray-700 transform rotate-90 sm:rotate-0"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              viewBox="0 0 24 24"
            >
              <line
                fill="none"
                strokeMiterlimit="10"
                x1="2"
                y1="12"
                x2="22"
                y2="12"
              />
              <polyline
                fill="none"
                strokeMiterlimit="10"
                points="15,5 22,12 15,19 "
              />
            </svg>
          </div>
          <p className="text-gray-600">
            Our AI algorithm shortlists candidates based on some basic criteria.
          </p>
        </div>
        <div className="p-3 border-solid border-4 hover:border-blue-600 border-gray-200 transition-transform transform hover:scale-105 hover:shadow-lg">
          <div className="flex flex-col items-center justify-between mb-6">
            <div className="w-2/3">
              <img src={assignment} alt="Assignment icon" />
            </div>
            <p className="text-2xl font-bold w-2/3"> Virtual Interview</p>
            <svg
              className="w-6 text-gray-700 transform rotate-90 sm:rotate-0"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              viewBox="0 0 24 24"
            >
              <line
                fill="none"
                strokeMiterlimit="10"
                x1="2"
                y1="12"
                x2="22"
                y2="12"
              />
              <polyline
                fill="none"
                strokeMiterlimit="10"
                points="15,5 22,12 15,19 "
              />
            </svg>
          </div>
          <p className="text-gray-600">
            Unlock the power of AI with our GPT-3.5 powered virtual interviews,
            revolutionizing the hiring process
          </p>
        </div>
        <div className="p-3 border-solid border-4 hover:border-blue-600 border-gray-200 transition-transform transform hover:scale-105 hover:shadow-lg">
          <div className="flex flex-col items-center justify-between mb-6">
            <div className="w-2/3">
              <img src={interview} alt="Communication-Skills Icon" />
            </div>
            <p className="text-2xl font-bold w-2/3">
              Communication Skills Analysis
            </p>
            <svg
              className="w-6 text-gray-700 transform rotate-90 sm:rotate-0"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              viewBox="0 0 24 24"
            >
              <line
                fill="none"
                strokeMiterlimit="10"
                x1="2"
                y1="12"
                x2="22"
                y2="12"
              />
              <polyline
                fill="none"
                strokeMiterlimit="10"
                points="15,5 22,12 15,19 "
              />
            </svg>
          </div>
          <p className="text-gray-600">
            Evaluate communication skills effortlessly with our AI-powered
            analysis using the CERF framework
          </p>
        </div>
        <div className="p-3 border-solid border-4 hover:border-blue-600 border-gray-200 transition-transform transform hover:scale-105 hover:shadow-lg">
          <div className="flex flex-col items-center justify-between mb-6">
            <div className="w-2/3">
              <img src={proof} alt="Proof-of-Work Verified" />
            </div>
            <p className="text-2xl font-bold w-2/3">Proof of Work Verified!!</p>
            <svg
              className="w-8 text-gray-600"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <polyline
                fill="none"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="6,12 10,16 18,8"
              />
            </svg>
          </div>
          <p className="text-gray-600">
            Get the best talent in the industry with our 3-step process. Sign up
            today!
          </p>
        </div>
      </div>
    </div>
  );
};
