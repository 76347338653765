import React, { useState,useEffect, useRef } from "react";
import icon from "../images/icon.png"
import { useNavigate } from "react-router-dom";
import { getCandidateDetails, getUserID } from "../service/api";
import { PresentationChartLineIcon } from "@heroicons/react/24/outline";

function CandidateComponent() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const navigate = useNavigate();
    const [profile,setProfile]=useState(null);
    const handleDashboard = () => {
        navigate("/dashboard");
      };

      
  useEffect(() => {
    const getCookieAndRole = () => {
      const cookieValue = document.cookie
        .split("; ")
        .find((row) => row.startsWith("token="));
      if (cookieValue) {
        const tokenWithPrefix = cookieValue.split("=")[1];
        if (tokenWithPrefix.length > 0) {
          const role = tokenWithPrefix[0];
          const token = tokenWithPrefix.slice(1); // Remove the prefix
          return { role, token };
        }
      }
      return { role: null, token: null };
    };
  

    const fetchData = async () => {
      const token = getCookieAndRole();

      if (token) {
        try {
          const userID = await getUserID(token.token);
            const data = await getCandidateDetails(userID);
            setProfile(data.candidate.profile_photo);
        } catch (error) {
          console.error('Error fetching user details:', error);
          setProfile(null);
        } 
      } 
    };

    fetchData();
  }, []);
  return (
    <div className="flex icon items-center md:space-x-8 lg:flex">
      <button
        className="flex items-center focus:outline-none"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <img
          className="h-12 w-12 rounded-full object-cover hover:shadow-sm"
          src={icon ? profile : icon}
          alt="Default Avatar"
        />
      </button>

      {isMenuOpen && (
        <div
          ref={menuRef}
          className="absolute right-12 mt-24 w-36 bg-white border rounded-lg shadow-md drop-shadow-2xl"
        >
          {/* Dashboard Menu */}
          <div className="block text-bottom px-4 py-2 hover:bg-gray-300">
            <button onClick={handleDashboard}>
              <PresentationChartLineIcon className="h-6 w-6 mr-2 inline" />
              Dashboard
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CandidateComponent;
