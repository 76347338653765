import React, { useState } from "react";
import ProjectCard from "./ProjectCard";
import sales from "../images/sales.png";
import bde from "../images/business.png";
import marketting from "../images/marketting.png";
import hr from "../images/hr.png";
import finance from "../images/finance.png";
import social from "../images/social.png";
import content from "../images/content.png";
import digitall from "../images/digital.png";
import TestingNavbar from "./NavBar/TestingNavbar";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const projects = [
  {
    image: sales,
    topic: "Sales",
    role: "sales",
  },
  {
    image: bde,
    topic: "Business Development(BDE)",
    role: "bde",
  },
  {
    image: marketting,
    topic: "Marketing",
    role: "marketing",
  },
  {
    image: hr,
    topic: "HR",
    role: "hr",
  },
  {
    image: finance,
    topic: "Finance & Accounting",
    role: "finance",
  },
  {
    image: social,
    topic: "Social Media",
    role: "social",
  },
  {
    image: content,
    topic: "Content Writing",
    role: "content",
  },
  {
    image: digitall,
    topic: "Digital Marketing",
    role: "digital",
  },
];

const experienceLevels = [
  { title: "Fresher", years: "0 - 2 Years" },
  { title: "Experienced", years: "2 - 5 Years" },
  { title: "Professional", years: "5+ Years" },
];

const Role = () => {
  const navigate = useNavigate();
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedExperience, setSelectedExperience] = useState("Fresher");

  const handleCardClick = (role) => {
    // setSelectedTopic(topic);
    setSelectedRole(role);
    console.log(selectedRole);
  };

  const handleSubmit = () => {
    if (selectedRole) {
      console.log(selectedRole);
      navigate("/instruction-video", {
        state: { role: selectedRole, experience: selectedExperience },
      });
    } else {
      toast.warning("Please select a topic");
    }
  };

  return (
    <div className="min-h-screen">
      <TestingNavbar
        showAvatar={true}
        handleLogout={() => navigate("/login")}
      />
      <div className="text-center bg-gray-100">
        <h2 className="text-xl font-bold mb-4 text-gray-600 p-4">
          Select your Exam Category
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-4 mx-16">
          {projects.map((project, index) => (
            <ProjectCard
              key={index}
              {...project}
              onClick={handleCardClick}
              isSelected={selectedRole === project.role}
            />
          ))}
        </div>
        <div className="flex flex-col w-full mt-4">
          <h2 className="text-xl font-bold mb-2 text-gray-600 p-4">
            Select your Experience Level
          </h2>
          <div className="flex flex-wrap w-full gap-4 justify-center items-center px-4">
            {experienceLevels.map((level, index) => {
              return (
                <button
                  onClick={() => setSelectedExperience(level.title)}
                  key={index}
                  className={`flex-1 max-w-[10rem] outline-none flex py-2 px-4 flex-col border-2 rounded-lg hover:border-blue-400 ${
                    selectedExperience === level.title
                      ? "bg-blue-500 text-white border-blue-500"
                      : "border-gray-400 bg-gray-200"
                  }`}
                >
                  <p className="text-lg font-semibold">{level.title}</p>
                  <p className="text-md font-semibold">{level.years}</p>
                </button>
              );
            })}
          </div>
        </div>
        <button
          className="bg-white text-gray-700 hover:text-blue-600 hover:border-2 hover:border-blue-600 hover:border-solid font-bold text-lg tracking-wide transition-colors duration-200 rounded-lg px-6 py-2 my-10 shadow-lg"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default Role;
