import React, { useState, useEffect } from "react";

const CountdownTimer = ({ initialTime }) => {
  const resetTimer = initialTime;
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime === 0) {
          // Reset the timer to 0 after 60 seconds
          return resetTimer;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);


  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const isBlinking = time <= 10 && time % 2 === 0;

  return (
    <div
      className={`p-2 border-4 rounded-lg text-center ${
        time <= 10
          ? isBlinking
            ? "border-red-500 animate-ping"
            : "border-red-500"
          : "border-black"
      }`}
    >
      <div className="text-4xl font-bold text-gray-800">{formatTime(time)}</div>
    </div>
  );
};

export default CountdownTimer;
