import React, { useState } from "react";

const Item = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b">
      <button
        type="button"
        aria-label="Open item"
        title="Open item"
        className="flex items-center justify-between w-full p-4 focus:outline-none gap-1"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="text-lg font-medium text-left">{title}</p>
        <svg
          viewBox="0 0 24 24"
          className={`w-3 text-gray-600 transform transition-transform duration-200 ${
            isOpen ? "rotate-180" : ""
          }`}
        >
          <polyline
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeMiterlimit="10"
            points="2,7 12,17 22,7"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <div
        className={`overflow-hidden transition-all duration-700 ease-in-out ${
          isOpen ? "max-h-[33rem] opacity-100" : "max-h-0 opacity-0"
        }`}
      >
        <div className="p-4 pt-0 transition-opacity duration-700 ease-in-out">
          <p className="text-gray-700">{children}</p>
        </div>
      </div>
    </div>
  );
};

export const Faq = () => {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 ">
      <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            Your common questions related to{" "}
            <span className="ml-2 text-3xl font-bold tracking-wide text-gray-800 font-custom">
              Hireme<span className="text-blue-500">Club</span>
            </span>
          </h2>
        </div>
        <div className="space-y-4">
          <Item title="What is AI-based hiring?">
            AI-based hiring, also known as AI-driven recruitment or automated
            recruiting, refers to the use of artificial intelligence and machine
            learning technologies in the process of identifying, evaluating, and
            selecting job candidates for employment. It involves the automation
            and augmentation of various aspects of the hiring process, including
            job posting, candidate sourcing, resume screening, candidate
            assessment, interview scheduling, and data analysis. AI-based hiring
            solutions utilize algorithms and data analysis to match candidates
            with job openings more effectively, reduce bias in hiring decisions,
            increase the efficiency of the recruitment process, and provide
            valuable insights to employers.
          </Item>
          <Item title="What are the benefits of using AI in hiring?">
            AI in hiring offers several advantages, including increased
            efficiency through automation, reduced time-to-hire, cost savings,
            more objective can didate assessment, scalability, data-driven
            insights, enhanced candidate experiences, and the potential to
            improve diversity and inclusion. Additionally, AI systems
            continually learn and adapt, providing organizations with a
            competitive edge in the recruitment process while reducing human
            errors. However, ethical and transparent use is crucial to address
            bias and privacy concerns effectively.
          </Item>
          <Item title="Is AI-based hiring fair and unbiased?">
            AI-based hiring has the potential to be fairer and less biased than
            traditional hiring methods, but it depends on how the technology is
            implemented and the data it uses.
          </Item>
          <Item title="Can your AI-based hiring solution work for all industries and roles?">
            Yes, our AI-based hiring solution is designed to be versatile and
            adaptable, making it suitable for a wide range of industries and job
            roles. The underlying algorithms and technologies have been
            developed to assess candidates based on their skills,
            qualifications, and attributes, which can be applied to various
            professions and sectors.
          </Item>
          <Item title="Is AI-based hiring fair and unbiased?">
            Yes , AI-based hiring is a significant and evolving aspect of the
            future of recruitment. While it may not entirely replace traditional
            recruitment methods, it is expected to play a substantial role in
            shaping how organizations identify, evaluate, and select candidates
            for employment.
          </Item>
        </div>
      </div>
    </div>
  );
};
