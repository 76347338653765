import axios from "axios";

const API_URI = "https://hiremeclub-backend.azurewebsites.net/api/v1";

export const getSignedUrlResume = async (data) => {
  try {
    const response = await axios.post(`${API_URI}/resume-url`, {
      token: data,
    });
    ////console.log("WE HAVE THE URL TO UPLOAD API.JS");
    return response.data;
  } catch (error) {
    ////console.log("Error while calling the API ", error.message);
    return error.response.data;
  }
};
