import React from "react";
import ChangePassword from "./ChangePassword";
import { changePasswordEmployer } from "../../service/api";
import EmployerProfile from "../../components/EmployerProfile";

function Settings() {
  return (
    <>
      <EmployerProfile />
      {/* <ChangePassword  changePasswordApi={changePasswordEmployer}/> */}
    </>
  );
}

export default Settings;
