import { TopNavigation } from "../../components/NavBar/TopNavigation";
import "./dashboard.css";
import Employer from "../../components/dashboard/Employer";
import Sidebar from "../../components/NavBar/EmployerSidebar";
import { useEffect, useState } from "react";
import Candidates from "../../components/dashboard/Candidates";
import Interview from "../../components/dashboard/Interview";
import PostJob from "../../components/dashboard/PostJob";
import Settings from "../../components/dashboard/Settings";
import { Footer } from "../../components/Footer";
import { getEmployerDetails } from "../../service/api";
import { useNavigate, Routes, Route } from "react-router-dom";
import TestLink from "../../components/dashboard/TestLink";

const EmployerDashboard = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);

  useEffect(() => {
    const getDetails = async () => {
      const token = getCookieAndRole();
      const data = await getEmployerDetails(token.token);
      // //console.log(data);
      // setUser(data.candidate.profile_photo);
      setUser(data.foundEmployer.logoUrl);
    };

    getDetails();
  }, []);


  const getCookieAndRole = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));
    if (cookieValue) {
      const tokenWithPrefix = cookieValue.split("=")[1];
      if (tokenWithPrefix.length > 0) {
        const role = tokenWithPrefix[0];
        const token = tokenWithPrefix.slice(1); // Remove the prefix
        return { role, token };
      }
    }
    return { role: null, token: null };
  };

  return (
    <>
      <div className="w-full contain bg-gray-100 ">
        <div className="topNav w-full">
          <TopNavigation
            isSticky={false}
            showAvatar={true}
            showNotification={false}
            showDashboard={true}
            handleLogout={() => navigate("/emp-login")}
            handleDashboard={() => navigate("/emp-dashboard")}
            dashboardPath="/emp-dashboard"
            user={user}
          />
        </div>
        <div className="outer-container flex">
          <div className="sideNav md:grid md:col-start-1 md:row-start-1 lg:mt-4">
            <Sidebar />
          </div>
          <div className="inner-container row-start-1 col-start-2 lg:col-span-5 md:col-span-5 mr-10 mx-2 p-4 w-full">
            <Routes>
              <Route path="/" element={<Employer />} />
              <Route path="postjob" element={<PostJob />} />
              <Route path="test" element={<TestLink/>} />
              <Route path="interview" element={<Interview />} />
              <Route path="candidates" element={<Candidates />} />
              <Route path="profile" element={<Settings />} />
            </Routes>
          </div>
        </div>
      </div>
  
    </>
  );
};

export default EmployerDashboard;