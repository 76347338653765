import React, { useState, useEffect, useRef } from "react";
import Instruct from "../../components/portal/Instruct";
import {
  getStage2Excel,
  getStage2Analytical,
  getStage2Reasoning,
} from "../../service/mlAPI";
import { useNavigate } from "react-router-dom";
import TestingNavbar from "../../components/NavBar/TestingNavbar";
import { toast } from "react-toastify";
import { fetchMCQquestions } from "../../service/api";

export default function InstructionMCQ() {
  const DATA = {
    ins1: `MCQ Round`,
    ins2: "This round will require you to respond to multiple-choice questions covering topics such as Data Analysis, Problem Solving, and Excel",
    ins3: "Number of Questions",
    ins4: "You'll have a total of 12 questions, with 4 from each section",
    ins5: "Time",
    ins6: "You will be allotted one minute for each question to contemplate and provide your response regarding this role.",
    ins7: "Assessment of Role",
    ins8: "You will encounter questions tailored to assess your suitability in Excel Knowledge, Problem Solving and Data Analysis",
  };
  const [permission, setPermission] = useState(false);
  const liveVideoFeed = useRef(null);
  const [stream, setStream] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [recordedVideo, setRecordedVideo] = useState(null);
  const allQuestions = useRef([]);
  const navigate = useNavigate();
  const questionsArray = useRef([]);
  const [seconds, setSeconds] = useState(60);
  const getQuestionsFromOpenAI = async (data) => {
    try {
      // const q7Promise = getStage2Excel(data);
      // const q8Promise = getStage2Reasoning(data);
      // const q6Promise = getStage2Analytical(data);
      const qm = await fetchMCQquestions();
      // Use Promise.all to fetch questions in parallel
      // const [q7, q8, q6] = await Promise.all([q7Promise, q8Promise, q6Promise]);
      allQuestions.current = qm;
      // console.log(allQuestions.current);
      // console.log(qm);
      // questionsArray.current = q6;
    } catch (error) {
      //console.log("Error getting questions from OpenAI", error.message);
      toast.error("Error Occured! Send Screenshot to Telegram!");
    }
  };

  const getCookieAndRole = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));
    if (cookieValue) {
      const tokenWithPrefix = cookieValue.split("=")[1];
      if (tokenWithPrefix.length > 0) {
        const role = tokenWithPrefix[0];
        const token = tokenWithPrefix.slice(1); // Remove the prefix
        return { role, token };
      }
    }
    return { role: null, token: null };
  };

  const getCameraPermission = async () => {
    try {
      const videoConstraints = {
        audio: true,
        video: true,
      };

      const stream = await navigator.mediaDevices.getUserMedia(
        videoConstraints
      );
      setPermission(true);

      let token = getCookieAndRole();
      await getQuestionsFromOpenAI(token.token);
      setButtonDisabled(false);
    } catch (err) {
      toast.error("Check Camera Permission and Reset settings");
    }
  };

  const handleStartTest = () => {
    // Pass questionsArray to the next page and navigate
    navigate("/mcq", { state: { allQuestions: allQuestions.current } });
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  useEffect(() => {
    getCameraPermission();

    // Start the countdown timer
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    // Cleanup the timer on unmount
    return () => clearInterval(timer);
  }, []);

  // Redirect if timer reaches 0
  useEffect(() => {
    if (seconds == 0) {
      getQuestionsFromOpenAI();
    }
  }, [seconds]);
  return (
    <div className="bg-gray-100 h-screen">
      <TestingNavbar
        showAvatar={true}
        handleLogout={() => navigate("/login")}
      />
      <div className="px-12">
        <Instruct instructions={DATA} />
        {/* Start Test Button */}
        <div className="mt-4 flex justify-center">
          {buttonDisabled ? (
            <div className="p-2 border-4 rounded-lg text-center border-black">
              <div className="text-4xl font-bold text-gray-800">
                {formatTime(seconds)}
              </div>
            </div>
          ) : (
            <button
              disabled={buttonDisabled}
              onClick={handleStartTest}
              className={`${
                buttonDisabled
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-700"
              } text-white font-bold py-2 px-4 rounded`}
            >
              Start Test
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
