import marketing from "../../images/marketing.png";
import business from "../../images/business.png";
import sales from "../../images/sales.png";

export const Categories = () => {
  return (
    <div className="px-4 py-16 mx-auto  sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-8">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
      <div>
          <p className="inline-block px-3 py-px text-sm font-bold trackng-wider text-blue-800  rounded-full bg-blue-100">
            Brand New
          </p>
        </div>
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          Categories
        </h2>
        <p className="text-base text-gray-700 md:text-lg">
          Empower your HR team to hire top talent across diverse student
          categories with ease from Interns to Freshers, we provide a wide range
          of candidates to meet your hiring needs. Streamline your recruitment
          process and find the perfect fit for your organization
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        <div className="flex flex-col justify-center items-center p-3 border-4 hover:border-blue-600 border-gray-200 rounded shadow-sm transition-transform transform hover:scale-105 hover:shadow-lg">
            <div className="w-40 h-40 flex justify-center items-center">
              <img src={marketing} alt="marketing icon" />
            </div>
            <h6 className="mb-2 font-semibold leading-5">
              Marketing Candidates
            </h6>
            <p className="mb-3 text-sm text-gray-900">
              Discover exceptional marketing candidates and talented interns to
              drive your business growth by partnering with us
            </p>

        </div>
        <div className="flex flex-col justify-center items-center p-3 border-4 hover:border-blue-600 border-gray-200 rounded shadow-sm transition-transform transform hover:scale-105 hover:shadow-lg">

            <div className="w-40 h-40 flex justify-center items-center">
              <img src={business} alt="icon by freepik" />
            </div>
            <h6 className="mb-2 font-semibold leading-5">
              Business Development Candidates{" "}
            </h6>
            <p className="mb-3 text-sm text-gray-900">
              Unleash the potential of your business with our exceptional
              Business Development candidates, equipped to drive growth and
              create new opportunities.
            </p>
    
        </div>
        <div className="flex flex-col justify-center items-center p-3 border-4 hover:border-blue-600 border-gray-200 rounded shadow-sm transition-transform transform hover:scale-105 hover:shadow-lg">
            <div className="w-40 h-40 flex justify-center items-center">
              <img src={sales} alt="Sales candidates icon" />
            </div>
            <h6 className="mb-2 font-semibold leading-5">Sales Candidates</h6>
            <p className="mb-3 text-sm text-gray-900">
              Fuel your sales success with our high-caliber candidates, poised
              to drive revenue growth and exceed targets.
            </p>

        </div>
      </div>
    </div>
  );
};
