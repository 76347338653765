import React, { useState } from "react";
import { toast } from "react-toastify";
import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";

const ChangePassword = ({ changePasswordApi }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMismatch, setPasswordMismatch] = useState(false);

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const getCookieAndRole = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));
    if (cookieValue) {
      const tokenWithPrefix = cookieValue.split("=")[1];
      if (tokenWithPrefix.length > 0) {
        const role = tokenWithPrefix[0];
        const token = tokenWithPrefix.slice(1); // Remove the prefix
        return { role, token };
      }
    }
    return { role: null, token: null };
  };

  const token = getCookieAndRole();

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "oldPassword":
        setOldPassword(value);
        break;
      case "newPassword":
        setNewPassword(value);
        setPasswordMismatch(false);
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        setPasswordMismatch(value !== newPassword);
        break;
      default:
        break;
    }
  };

  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setPasswordMismatch(true);
    } else {
      try {
        const response = await changePasswordApi(
          oldPassword,
          newPassword,
          token.token
        );
        if (response) {
          // ////console.log('Password updated successfully!');
          toast.success("Password Changed!");
        } else {
          toast.error("Password Incorrect!");
        }
      } catch (error) {
        // console.error('Error updating password:', error);
        toast.error("Password Incorrect!");
      }
    }
  };

  return (
    <div className="w-full max-w-xs mx-auto shadow-2xl bg-gray-100 rounded-xl">
      <form
        onSubmit={handleSubmit}
        className="bg-white shadow-md rounded-xl px-8 pt-6 pb-8 mb-4"
      >
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="oldPassword"
          >
            Old Password
          </label>
          <div className="relative">
            <input
              className="shadow appearance-none border rounded-xl w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="oldPassword"
              type={showOldPassword ? "text" : "password"}
              placeholder="******************"
              name="oldPassword"
              value={oldPassword}
              onChange={handlePasswordChange}
            />
            {showOldPassword ? (
              <IoMdEyeOff
                className="absolute top-1/2 right-3 -translate-y-1/2 cursor-pointer"
                width="16"
                height="16"
                onClick={toggleOldPasswordVisibility}
              />
            ) : (
              <IoMdEye
                className="absolute top-1/2 right-3 -translate-y-1/2 cursor-pointer"
                style={{ transform: "translateY(-50%)" }}
                width="16"
                height="16"
                onClick={toggleOldPasswordVisibility}
              />
            )}
          </div>
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="newPassword"
          >
            New Password
          </label>
          <div className="relative">
            <input
              className="shadow appearance-none border rounded-xl w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="newPassword"
              type={showNewPassword ? "text" : "password"}
              placeholder="******************"
              name="newPassword"
              value={newPassword}
              onChange={handlePasswordChange}
            />
            {showNewPassword ? (
              <IoMdEyeOff
                className="absolute top-1/2 right-3 -translate-y-1/2 cursor-pointer"
                width="16"
                height="16"
                onClick={toggleNewPasswordVisibility}
              />
            ) : (
              <IoMdEye
                className="absolute top-1/2 right-3 -translate-y-1/2 cursor-pointer"
                style={{ transform: "translateY(-50%)" }}
                width="16"
                height="16"
                onClick={toggleNewPasswordVisibility}
              />
            )}
          </div>
        </div>
        <div className="mb-6">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="confirmPassword"
          >
            Confirm New Password
          </label>
          <input
            className="shadow appearance-none border rounded-xl w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="confirmPassword"
            type="password"
            placeholder="******************"
            name="confirmPassword"
            value={confirmPassword}
            onChange={handlePasswordChange}
          />
          {passwordMismatch && (
            <p className="text-red-500 text-xs italic">
              New Password and Confirm New Password should be same.
            </p>
          )}
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-xl focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Change Password
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
