import React, { useState } from "react";
import { addToWishlist, applyToJob, completedStages } from "../../service/api";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Card = ({ cardData }) => {
  const navigate = useNavigate();
  const [isWishlistClicked, setIsWishlistClicked] = useState(false);

  const getCookieAndRole = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));
    if (cookieValue) {
      const tokenWithPrefix = cookieValue.split("=")[1];
      if (tokenWithPrefix.length > 0) {
        const role = tokenWithPrefix[0];
        const token = tokenWithPrefix.slice(1); // Remove the prefix
        return { role, token };
      }
    }
    return { role: null, token: null };
  };

  const handleApplyClick = async () => {
    try {
      const token = getCookieAndRole();
      if (token.token !== null) {
        //There are 3 Cases:
        //Completed Test
        const response = await completedStages(token.token);
        if (response.completedStages.stage2) {
          // Assuming applyToJob is an asynchronous function that returns a promise
          const applyJOb = await applyToJob(
            token.token,
            cardData._id,
            cardData.employer
          );
          // //console.log(response);
          if (applyJOb.success) {
            toast.success("Successfully applied");
          } else {
            toast.error("Alreadly Applied");
            //console.log("Already APplied");
          }
        } else if (response.completedStages.stage1) {
          navigate("/instruction");
          toast.warning("Please complete test first!");
        } else {
          navigate("/stage1");
          toast.warning("Please complete test first!");
        }
      } else {
        console.log("here");
        navigate("/login");
        toast.error("Login to Apply!");
      }
    } catch (error) {
      console.error("Error applying to the job:", error);
      // You can display an error toast message here if needed
    }
  };

  const handleWishlistClick = () => {
    // Toggle the wishlist state
    setIsWishlistClicked(!isWishlistClicked);
    const token = getCookieAndRole();

    if (token) {
      addToWishlist(token.token, cardData._id);
    } else {
      navigate("/login");
    }
  };

  // console.log("card data ", cardData);

  return (
    <div className="bg-white shadow-md p-4 rounded-xl w-full h-auto">
      <div className="relative flex items-center">
        <div className="absolute top-2 left-0">
          <div className="rounded-full overflow-hidden h-10 w-10">
            <img
              src={cardData.companyName.logoUrl}
              alt="company"
              className="object-cover h-full w-full"
            />
          </div>
        </div>
        <div className="ml-10">
          <h2 className="font-bold text-lg">
            {cardData.companyName.brandName}
          </h2>
          <p className="text-gray-500 font-semibold">{cardData.workLocation}</p>
        </div>
        <div className="absolute top-0 right-0 bg-white-900">
          <svg
            onClick={handleWishlistClick}
            className={`w-6 h-6 cursor-pointer ${
              isWishlistClicked ? "text-red-500" : "text-gray-500"
            }`}
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M10 18.879l-1.45-1.31C3.642 13.016 0 9.35 0 5.1 0 2.372 2.238 0 5 0c1.74 0 3.41.905 4.5 2.367C11.59.905 13.26 0 15 0 17.762 0 20 2.372 20 5.1c0 4.25-3.642 7.916-8.55 12.47L10 18.88z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>

      <h3 className="font-bold text-xl mt-2">{cardData.jobTitle}</h3>
      <p className="text-gray-800 font-bold">&#x20B9;{cardData.salary}</p>
      <h3 className="font-semibold text-md text-gray-800 mt-2">
        {cardData.workType}
      </h3>
      <div>
        {/* Badges of Perks */}
        <div className="flex mt-2 space-x-2">
          {cardData.perks[0] && (
            <span className="px-2 py-1 bg-blue-100 text-blue-800 text-xs rounded-md">
              {cardData.perks[0]}
            </span>
          )}
          {cardData.perks[1] && (
            <span className="px-2 py-1 bg-orange-100 text-orange-800 text-xs rounded-md">
              {cardData.perks[1]}
            </span>
          )}
          {cardData.perks[2] && (
            <span className="px-2 py-1 bg-green-100 text-green-900 text-xs rounded-md">
              {cardData.perks[2]}
            </span>
          )}
        </div>
        <div className="flex mt-2 space-x-4">
          {cardData.perks[3] && (
            <span className="px-2 py-1 bg-[#e4fffd] text-blue-800 text-xs rounded-md">
              {cardData.perks[3]}
            </span>
          )}
          {cardData.perks[4] && (
            <span className="px-2 py-1 bg-red-100 text-red-700 text-xs rounded-md">
              {cardData.perks[4]}
            </span>
          )}
        </div>
      </div>

      <div className="flex justify-between mt-4">
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded-lg"
          onClick={handleApplyClick}
        >
          Apply Now
        </button>
        <Link
          to={`/jobs/details/${cardData.jobTitle + `(` + cardData._id + `)`}`}
        >
          <button
            className="px-4 py-2 bg-white text-blue-500 border border-blue-500 rounded-lg"
            onClick={() => navigate(`/jobs/details/${cardData._id}`)}
          >
            View Details
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Card;
