import { useAuth } from './AuthProvider';

const AuthWrapper = ({ candidateComponent, employerComponent, fallbackComponent }) => {
  const { isLoggedIn, user } = useAuth();

  if (isLoggedIn && user === 'userLoggedIn') {
    return candidateComponent;
  } else if (isLoggedIn && user === 'EmployerLoggedIn') {
    return employerComponent;
  } else {
    return fallbackComponent;
  }
};

export default AuthWrapper;
