import React from "react";
import PriceCard from "../PriceCard";

export default function Plans() {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-full md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-8">
      <div className="max-w-xl lg:pt-10 mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <div>
          <p className="inline-block px-3 py-px text-sm font-bold tracking-wider text-blue-800 rounded-full bg-blue-100">
            Pricing
          </p>
        </div>
        <h2 className="max-w-lg mb-3 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl sm:ml-10 md:mx-auto">
          Plans
        </h2>
        <p className="text-base text-gray-700 md:text-lg">
          Choose a plan that suits your needs
        </p>
      </div>
      <div className="flex justify-center gap-8 flex-wrap">
        <PriceCard
          title={"PRICING"}
          description={
            "Begin your journey with our introductory price plan, setting the stage for success."
          }
          duration={"Proof of Work"}
          price={"20"}
          features={[
            "Proof-of-Work: Verified resume scores post-tests on our site.",
          ]}
          old
        />
        <PriceCard
          title={"LAUNCH OFFER"}
          description={
            "Dive into success with our Launch Plan - your pathway to a dynamic start."
          }
          duration={"30 Days"}
          price={"5000"}
          features={[
            "Unlimited Test( Proof-of-Work) of Candidates using Test Link Feature",
          ]}
        />
      </div>
    </div>
  );
}
