import React from "react";

const Instruct = (props) => {
  const { instructions } = props;
  return (
    <div className="bg-white border rounded-lg shadow-lg p-5">
      {/* Heading */}
      <h2 className="text-2xl text-gray-700 font-semibold">Instructions</h2>

      {/* Bullet Points with Instructions */}
      <ul className="list-decimal ml-6 mt-4">
        <li>
          The test will consist of 6 sections, including 3 sections of{" "}
          <span className="font-bold text-gray-800">Virtual Interview </span>{" "}
          and 3 sections of{" "}
          <span className="font-bold text-gray-800">MCQ tests.</span>
        </li>
        <li>
          Total time for the test is{" "}
          <span className="font-bold text-gray-800">
            approximately 25 minutes.{" "}
          </span>
          Please complete the test within 25 minutes.{" "}
          <span className="font-bold text-gray-800">
            Closing the test prematurely will lead to disqualification.
          </span>
        </li>
        <li>
          The test will be used to create a{" "}
          <span className="font-bold text-gray-800">📄"Proof-Of-Work"</span> for
          use in recruitment by different companies.
        </li>
        <li>
          The test is 👽AI-Proctored, and{" "}
          <span className="font-bold text-gray-800">
            {" "}
            📷 camera and 🎤 audio{" "}
          </span>{" "}
          access are required for proctoring.
          <span className="font-bold text-red-600">
            {" "}
            Do not attempt to cheat.
          </span>
        </li>
        <li>
          Ensure you are seated in a <span className="font-bold text-gray-800">💡place with proper lighting</span> or else you may
          get a wrong score.
        </li>
        <li>
          The website may{" "}
          <span className="font-bold text-gray-800">
            {" "}
            detect 💻 tab changes
          </span>
          , so avoid changing tabs during the test, as it may{" "}
          <span className="font-bold text-gray-800">
            {" "}
            lead to disqualification.
          </span>
        </li>
        {/* Here Props based Instruction will start */}
        <li>
        <span className="font-bold text-gray-800">{instructions.ins1}</span>: {instructions.ins2}
        </li>
        <li>
        <span className="font-bold text-gray-800">{instructions.ins3}</span>: {instructions.ins4}
        </li>
        <li>
        <span className="font-bold text-gray-800">{instructions.ins5}</span>: {instructions.ins6}
        </li>
        <li>
        <span className="font-bold text-gray-800">{instructions.ins7}</span>: {instructions.ins8}
        </li>
      </ul>
      <div className="text-2xl font-semibold text-gray-700 text-center py-2">
        All the Best for Exam{" "}
        <span role="img" aria-label="Thumbs Up">
          👍
        </span>
      </div>
    </div>
  );
};

export default Instruct;
