import React, { useState, useEffect } from "react";
import axios from "axios";
import { Transition } from "@headlessui/react";
import "../customCss/applicant.css";
import Settings from "./Settings";
import {
  changePasswordCandidate,
  getCandidateProfile,
} from "../../service/api";
import ChangePassword from "./ChangePassword";

const ProfileForm = () => {
  const [formData, setFormData] = useState({
    foundCandidate: {
      name: "",
      dob: "",
      mobno: "",
      email: "",
      college: "",
      collegedegree: "",
      collegeyear: "",
      collegecgpa: "",
      school: "",
      schooldegree: "",
      schoolyear: "",
      schoolcgpa: "",
      exe1: "",
      exedes1: "",
      exe2: "",
      exedes2: "",
      res: "",
      resdes: "",
      achievement1: "",
      achievement1des: "",
      achievement2: "",
      achievement2des: "",
      certificate: "",
      certificatedes: "",
      project: "",
      projectdes: "",
    },
  });

  const [isEditable, setIsEditable] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const token = getCookieAndRole();
    getCandidateProfile(token.token)
      .then((data) => {
        setFormData(data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const getCookieAndRole = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));
    if (cookieValue) {
      const tokenWithPrefix = cookieValue.split("=")[1];
      if (tokenWithPrefix.length > 0) {
        const role = tokenWithPrefix[0];
        const token = tokenWithPrefix.slice(1); // Remove the prefix
        return { role, token };
      }
    }
    return { role: null, token: null };
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevState) => {
      return {
        ...prevState,
        foundCandidate: {
          ...prevState.foundCandidate,
          [name]: value,
        },
      };
    });
  };

  const toggleEdit = () => {
    setIsEditable(!isEditable);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Replace '/api/profile' with the actual endpoint
      const response = await axios.post("/api/profile", formData);
      // ////console.log(response.data);
      // Handle the response from the server
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  // ////console.log(formData)

  return (
    <div className="bg-gray-100">
      <form onSubmit={handleSubmit} className=" resume-data">
        <div className="resume-data">
          <div className="bg-gray-50 min-h-screen py-8 lg:px-8">
            <div className="container profile mx-auto sm:p-8">
              <h2 className="text-3xl font-semibold mb-4 text-center">
                Profile Details
              </h2>
              <div className="border bg-white rounded-lg lg:p-5 sm:p-8">
                {/*Basic Candnameate's Details */}
                <div className="mb-6 flex space-x-4">
                  <div className="w-full">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium "
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2 "
                      value={formData.foundCandidate.name}
                      onChange={handleInputChange}
                      disabled={true}
                    />
                  </div>

                  <div className="w-full">
                    <label
                      htmlFor="dob"
                      className="block mb-2 text-sm font-medium "
                    >
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      name="dob"
                      className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                      value={formData.foundCandidate.dob}
                      onChange={handleInputChange}
                      disabled={!isEditable}
                    />
                  </div>
                </div>

                {/*Contact Information */}
                <div className="mb-6 flex space-x-4">
                  <div className="w-full">
                    <label
                      htmlFor="phone"
                      className="block mb-2 text-sm font-medium"
                    >
                      Mobile Number
                    </label>
                    <input
                      type="tel"
                      name="mobno"
                      className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                      value={formData.foundCandidate.mobno}
                      onChange={handleInputChange}
                      disabled={!isEditable}
                    />
                  </div>

                  <div className="w-full">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium "
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                      onChange={handleInputChange}
                      value={formData.foundCandidate.email}
                      disabled={!isEditable}
                    />
                  </div>
                </div>
              </div>

              {/*Education Details */}

              <div className="mb-6">
                <h3 className="text-lg font-semibold mt-5 mb-2">
                  Educational Details
                </h3>

                <div className="mb-4 border bg-white rounded-lg lg:p-5 sm:p-2">
                  <h4 className="text-md font-semibold mb-2">College</h4>
                  <div className="space-y-2">
                    <div className="flex space-x-4">
                      <div className="w-full">
                        <label
                          htmlFor="collegeName"
                          className="block mb-2 text-sm font-medium"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          name="college"
                          value={formData.foundCandidate.college}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                          className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                        />
                      </div>
                      <div className="w-full">
                        <label
                          htmlFor="degree"
                          placeholder="Degree"
                          className="block mb-2 text-sm font-medium"
                        >
                          Degree
                        </label>
                        <input
                          type="text"
                          name="collegedegree"
                          className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                          value={formData.foundCandidate.collegedegree}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                        />
                      </div>
                    </div>
                    <div className="flex space-x-4">
                      <div className="w-full">
                        <label
                          htmlFor="dob"
                          className="block mb-2 text-sm font-medium "
                        >
                          Year of Passing
                        </label>
                        <input
                          type="number"
                          name="collegeyear"
                          min="1900"
                          max="2099"
                          step="1"
                          placeholder="Enter a year"
                          className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                          onChange={handleInputChange}
                          value={formData.foundCandidate.collegeyear}
                          disabled={!isEditable}
                        />
                      </div>
                      <div className="w-full">
                        <label
                          htmlFor="cgpa"
                          className="block mb-2 text-sm font-medium"
                        >
                          CGPA
                        </label>
                        <input
                          type="text"
                          name="collegecgpa"
                          className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                          onChange={handleInputChange}
                          value={formData.foundCandidate.collegecgpa}
                          disabled={!isEditable}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-4 border bg-white rounded-lg lg:p-5 sm:p-2">
                  <h4 className="text-md font-semibold mb-2">School</h4>
                  <div className="space-y-2">
                    <div className="flex space-x-4">
                      <div className="w-full">
                        <label
                          htmlFor="schoolName"
                          className="block mb-2 text-sm font-medium"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          name="school"
                          className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                          value={formData.foundCandidate.school}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                        />
                      </div>
                      <div className="w-full">
                        <label
                          htmlFor="degree"
                          className="block mb-2 text-sm font-medium"
                        >
                          Degree
                        </label>
                        <input
                          type="text"
                          name="schooldegree"
                          className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                          value={formData.foundCandidate.schooldegree}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                        />
                      </div>
                    </div>
                    <div className="flex space-x-4">
                      <div className="w-full">
                        <label
                          htmlFor="dob"
                          className="block mb-2 text-sm font-medium "
                        >
                          Year of Passing
                        </label>
                        <input
                          type="number"
                          name="schoolyear"
                          min="1900"
                          max="2099"
                          step="1"
                          placeholder="Enter a year"
                          className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                          onChange={handleInputChange}
                          value={formData.foundCandidate.schoolyear}
                          disabled={!isEditable}
                        />
                      </div>
                      <div className="w-full">
                        <label
                          htmlFor="cgpa"
                          className="block mb-2 text-sm font-medium"
                        >
                          CGPA
                        </label>
                        <input
                          type="text"
                          name="schoolcgpa"
                          className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                          onChange={handleInputChange}
                          value={formData.foundCandidate.schoolyear}
                          disabled={!isEditable}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*Skills and Experience Details */}
              <div className="mb-6 ">
                <h3 className="text-lg font-semibold mb-2">Experience</h3>

                <div className="mb-4 border bg-white rounded-lg lg:p-5 sm:p-2">
                  <div className="space-y-2">
                    <h4 className="text-md font-semibold mb-2">Experience 1</h4>
                    <div className="flex space-x-4">
                      <div className="w-full">
                        <label
                          htmlFor="organizationName"
                          className="block mb-2 text-sm font-medium"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          name="exe1"
                          className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                          value={formData.foundCandidate.exe1}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                        />
                      </div>
                    </div>
                    <div className="flex space-x-4">
                      <div className="w-full">
                        <label
                          htmlFor="desciption"
                          className="block mb-2 text-sm font-medium"
                        >
                          Description
                        </label>
                        <textarea
                          type="textarea"
                          name="exedes1"
                          rows={2}
                          cols={20}
                          className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                          value={formData.foundCandidate.exedes1}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="space-y-2">
                    <h4 className="text-md font-semibold mb-2">Experience 2</h4>
                    <div className="flex space-x-4">
                      <div className="w-full">
                        <label
                          htmlFor="organizationName"
                          className="block mb-2 text-sm font-medium"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          name="exe2"
                          className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                          value={formData.foundCandidate.exe2}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                        />
                      </div>
                    </div>
                    <div className="flex space-x-4">
                      <div className="w-full">
                        <label
                          htmlFor="desciption"
                          className="block mb-2 text-sm font-medium"
                        >
                          Description
                        </label>
                        <textarea
                          type="textarea"
                          name="exedes2"
                          rows={2}
                          cols={20}
                          className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2"
                          value={formData.foundCandidate.exedes2}
                          onChange={handleInputChange}
                          disabled={!isEditable}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*Responsibilities Details */}
              <div className="mb-6">
                <h3 className="text-lg font-semibold mb-2 mt-5">
                  Responsibility
                </h3>
                <div className="border bg-white rounded-lg lg:p-5 sm:p-2">
                  <div className="mb-4 ">
                    <div className="space-y-2">
                      <div className="flex space-x-4">
                        <div className="w-full">
                          <label
                            htmlFor="organizationName"
                            className="block mb-2 text-sm font-medium"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            name="res"
                            className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2 "
                            value={formData.foundCandidate.res}
                            onChange={handleInputChange}
                            disabled={!isEditable}
                          />
                        </div>

                        <div className="w-full">
                          <label
                            htmlFor="desciption"
                            className="block mb-2 text-sm font-medium"
                          >
                            Description
                          </label>
                          <textarea
                            type="textarea"
                            name="description"
                            rows={2}
                            cols={20}
                            className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2 "
                            value={formData.foundCandidate.resdes}
                            onChange={handleInputChange}
                            disabled={!isEditable}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*Achievement Details */}
              <div className="mb-6">
                <h3 className="text-lg font-semibold mb-2">Achievements</h3>
                <div className="border bg-white rounded-lg lg:p-5 sm:p-2">
                  <div className="mb-4">
                    <h4 className="text-md font-semibold mb-2">
                      Achievement 1
                    </h4>
                    <div className="space-y-2">
                      <div className="flex space-x-4">
                        <div className="w-full">
                          <label
                            htmlFor="organizationName"
                            className="block mb-2 text-sm font-medium"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            name="achievement1"
                            className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2 "
                            value={formData.foundCandidate.achievement1}
                            onChange={handleInputChange}
                            disabled={!isEditable}
                          />
                        </div>

                        <div className="w-full">
                          <label
                            htmlFor="desciption"
                            className="block mb-2 text-sm font-medium"
                          >
                            Description
                          </label>
                          <textarea
                            type="textarea"
                            name="achievement1des"
                            rows={2}
                            cols={10}
                            className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2 "
                            value={formData.foundCandidate.achievement1des}
                            onChange={handleInputChange}
                            disabled={!isEditable}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <h4 className="text-md font-semibold mb-2">
                      Achievement 2
                    </h4>
                    <div className="space-y-2">
                      <div className="flex space-x-4">
                        <div className="w-full">
                          <label
                            htmlFor="organizationName"
                            className="block mb-2 text-sm font-medium"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            name="achievement2"
                            className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2 "
                            value={formData.foundCandidate.achievement2}
                            onChange={handleInputChange}
                            disabled={!isEditable}
                          />
                        </div>

                        <div className="w-full">
                          <label
                            htmlFor="desciption"
                            className="block mb-2 text-sm font-medium"
                          >
                            Description
                          </label>
                          <textarea
                            type="textarea"
                            name="achievement2des"
                            rows={2}
                            cols={20}
                            className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2 "
                            value={formData.foundCandidate.achievement2des}
                            onChange={handleInputChange}
                            disabled={!isEditable}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*Training and Certifications Details */}
              <div className="mb-6">
                <h3 className="text-lg font-semibold mb-2">
                  Training and Certifications
                </h3>
                <div className="border bg-white rounded-lg lg:p-5 sm:p-2">
                  <div className="mb-4">
                    <h4 className="text-md font-semibold mb-2">Certificate</h4>
                    <div className="space-y-2">
                      <div className="flex space-x-4">
                        <div className="w-full">
                          <label
                            htmlFor="organizationName"
                            className="block mb-2 text-sm font-medium"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            name="certificate"
                            className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2 "
                            onChange={handleInputChange}
                            value={formData.foundCandidate.certificate}
                            disabled={!isEditable}
                          />
                        </div>

                        <div className="w-full">
                          <label
                            htmlFor="desciption"
                            className="block mb-2 text-sm font-medium"
                          >
                            Description
                          </label>
                          <textarea
                            type="textarea"
                            name="certificatedes"
                            rows={2}
                            cols={20}
                            className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2 "
                            value={formData.foundCandidate.certificatedes}
                            onChange={handleInputChange}
                            disabled={!isEditable}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*Projects Details */}
              <div className="mb-6">
                <h3 className="text-lg font-semibold mb-2">Projects</h3>
                <div className="border bg-white rounded-lg lg:p-5 sm:p-2">
                  <div className="mb-4">
                    <div className="space-y-2">
                      <div className="flex space-x-4">
                        <div className="w-full">
                          <label
                            htmlFor="organizationName"
                            className="block mb-2 text-sm font-medium"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            name="project"
                            className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2 "
                            value={formData.foundCandidate.project}
                            onChange={handleInputChange}
                            disabled={!isEditable}
                          />
                        </div>

                        <div className="w-full">
                          <label
                            htmlFor="desciption"
                            className="block mb-2 text-sm font-medium"
                          >
                            Description
                          </label>
                          <textarea
                            type="textarea"
                            name="projectdes"
                            rows={2}
                            cols={20}
                            className="w-full bg-white-100 border border-gray-300 text-gray-700 text-sm rounded-lg p-2 "
                            value={formData.foundCandidate.projectdes}
                            onChange={handleInputChange}
                            disabled={!isEditable}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*Submit Button */}
              <div className="flex justify-center space-x-4">
                <button
                  type="button"
                  onClick={toggleEdit}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  {isEditable ? "Save" : "Edit"}
                </button>
                <button
                  type="submit"
                  className="bg-green-500 text-white px-4 py-2 rounded"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      {/*Change Password Modal */}
      <div className="flex items-center justify-center bg-gray-50 pb-8">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={openModal}
        >
          Change Password
        </button>

        {isModalOpen && (
          <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-8 max-w-md mx-auto rounded-lg shadow-md">
              <ChangePassword changePasswordApi={changePasswordCandidate} />

              <button
                className=" flex items-center justify-center bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileForm;
