import React from "react";
import { useState, useEffect } from "react";
import { MagnifyingGlass, ProgressBar } from "react-loader-spinner";
import { uploadFile } from "../../service/mlAPI";
import {
  getExtractEmailfromExcel,
  getSignedUrlExcel,
  creatingUserFromEmail,
} from "../../service/api";
import Pagination from "./Pagination";
import { toast } from "react-toastify";
import Email_List from "../../images/Email_List.png";

const TestLink = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [url, setUrl] = useState("");
  const [formData, setFormData] = useState({
    testName: "",
    role: "Sales",
    file: null,
  });

  const [linkSend, setLinkSend] = useState(false);

  const handleformSubmit = async (e) => {
    e.preventDefault();

    // console.log(formData);

    if (!formData.testName || !formData.role || !formData.file) {
      alert("Please fill all the fields");
      return;
    }
    setLoading(true);

    //upload file to s3 bucket
    await uploadFile(url, formData.file);

    const token = getCookieAndRole();
    //extract email from excel

    const emails = await getExtractEmailfromExcel(token.token);
    setLoading(false);
    if (emails.length == 0) {
      alert("No email found in the excel file");
      return;
    }

    for (let i = 0; i < emails.length; i++) {
      setData((prev) => [...prev, { id: prev.length + 1, email: emails[i] }]);
    }

    //after some condition set linkSend to true
    setLinkSend(true);
  };

  const handleBack = () => {
    setLinkSend(false);
    setData([]);
  };

  const handleDelete = (id) => {
    setData(data.filter((item) => item.id !== id));
  };

  const handleConfirm = async (e) => {
    e.preventDefault();
    try {
      setSendingEmail(true);
      // console.log(data);
      // console.log(data[0].email);
      const emailArray = data.map((obj) => obj.email);
      // console.log(emailArray);
      // console.log(formData);
      const token = getCookieAndRole();
      const response = await creatingUserFromEmail(
        emailArray,
        formData,
        token.token
      );
      // console.log(response);
      if (response.success) {
        setSendingEmail(false);
      } else {
        setSendingEmail(false);
        setLinkSend(false);
        toast.success("Test Link Sent Successfully");
      }
      // console.log(response);
    } catch (error) {
      // console.log(error);
      toast.error("Error in sending test link");
    }
  };

  const roles = [
    "Sales",
    "Business Development (BDE)",
    "Marketing",
    "HR",
    "Finance & Accounts",
    "Social Media",
    "Content Writing",
    "Digital Marketing",
  ];

  useEffect(() => {}, [linkSend]);

  useEffect(() => {
    const getDetails = async () => {
      const token = getCookieAndRole();
      const resp = await getSignedUrlExcel(token.token);
      setUrl(resp.url);
    };

    getDetails();
  }, []);

  const getCookieAndRole = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));
    if (cookieValue) {
      const tokenWithPrefix = cookieValue.split("=")[1];
      if (tokenWithPrefix.length > 0) {
        const role = tokenWithPrefix[0];
        const token = tokenWithPrefix.slice(1);
        return { role, token };
      }
    }
    return { role: null, token: null };
  };
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <div>
      {loading ? (
        <div className="magnifying-glass-wrapper flex flex-col items-center justify-center h-screen">
          <MagnifyingGlass
            visible={true}
            height="160"
            width="160"
            ariaLabel="MagnifyingGlass-loading"
            wrapperStyle={{}}
            wrapperClass="MagnifyingGlass-wrapper"
            glassColor="#c0efff"
            color="#2979FF"
          />
          <h3 className="text-gray-800 font-Kalam">
            Extracting Data from Excel Sheet
          </h3>
        </div>
      ) : (
        <div>
          {!linkSend && (
            <div className="w-full flex-1 h-full flex items-center justify-center lg:pt-12">
              <div className="max-w-fit mx-auto bg-white rounded-lg shadow-lg p-6 md:p-8">
                <h1 className="text-3xl font-bold mb-4">Generate Test</h1>
                <form
                  className="space-y-4"
                  onSubmit={(e) => handleformSubmit(e)}
                >
                  <div>
                    <label htmlFor="name" className="block mb-1">
                      Test Name:
                    </label>
                    <input
                      id="name"
                      type="text"
                      required
                      className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-400"
                      value={FormData.testName}
                      onChange={(e) =>
                        setFormData({ ...formData, testName: e.target.value })
                      }
                    />
                  </div>
                  <div>
                    <label htmlFor="role" className="block mb-1">
                      Role:
                    </label>
                    <select
                      id="role"
                      value={FormData.role}
                      required
                      onChange={(e) =>
                        setFormData({ ...formData, role: e.target.value })
                      }
                      className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-400"
                    >
                      {roles.map((role, index) => (
                        <option key={index} value={role}>
                          {role}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label htmlFor="file" className="block mb-1">
                      Excel File:
                    </label>
                    <input
                      id="file"
                      type="file"
                      accept=".xlsx"
                      required
                      onChange={(e) =>
                        setFormData({ ...formData, file: e.target.files[0] })
                      }
                      className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-400 bg-gray-50 hover:bg-gray-100"
                    />
                  </div>
                  <div className="w-full flex items-center justify-center">
                    <button
                      type="submit"
                      className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                    >
                      Send Link
                    </button>
                  </div>
                </form>
              </div>
              {/* Image and Note */}
              <div className="pr-28 flex flex-col items-center">
                <img
                  src={Email_List}
                  alt="E-Mail Format"
                  style={{ width: "22rem", height: "22rem" }}
                />
                <p className="mt-4 text-lg italic font-bold text-red-700 text-center">
                  Add Email as First Column and below all <br />
                  the Emails of Candidates in this format
                </p>
              </div>
            </div>
          )}
          <div>
            {sendingEmail ? (
              <div className="progress-bar-wrapper flex flex-col items-center justify-center h-screen">
                <ProgressBar
                  visible={true}
                  height="160"
                  width="160"
                  barColor="#2979FF"
                  borderColor="#c0efff"
                  ariaLabel="progress-bar-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
                <h3 className="text-gray-800 font-Kalam">
                  Sending Test Link Mail to Candidates
                </h3>
              </div>
            ) : (
              <div>
                {linkSend && (
                  <div className="w-full h-full flex items-center pt-4">
                    <div className="container px-4 bg-white lg:m-20 flex flex-col h-full justify-evenly items-center p-4 lg:gap-0 gap-4">
                      <h1 className="text-3xl font-bold w-full py-4 px-4 text-center">
                        Candidates Details
                      </h1>
                      <div className="overflow-auto w-full">
                        <table className="w-full">
                          <thead>
                            <tr>
                              <th className="px-4 py-2 border-2">Sn.no</th>
                              <th className="px-4 py-2 border-2">Email ID</th>

                              <th className="px-4 py-2 border-2">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data
                              .slice((currentPage - 1) * 10, currentPage * 10)
                              .map((item, index) => (
                                <tr key={item.id} className="">
                                  <td className="border-2 px-4 py-2 text-center">
                                    {(currentPage - 1) * 10 + index + 1}
                                  </td>
                                  <td className="border-2 px-4 py- text-center">
                                    {item.email}
                                  </td>
                                  <td className="border-2 px-4 py-2 text-center">
                                    <button
                                      className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded"
                                      onClick={() => handleDelete(item.id)}
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              ))}

                            {data.length === 0 && (
                              <tr>
                                <td colSpan="3" className="text-center">
                                  No data found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="w-full flex flex-wrap gap-4 py-4 justify-center">
                        <button
                          className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                          onClick={handleBack}
                        >
                          back
                        </button>
                        <button
                          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                          onClick={handleConfirm}
                        >
                          Confirm
                        </button>
                      </div>
                      <Pagination
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                        perPage={10}
                        totalPages={Math.ceil(data.length / 10)}
                        results={data.length}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TestLink;
