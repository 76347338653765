import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Harsh from "../images/Harsh.jpeg";
import Shubham from "../images/Shubham.jpeg";
import Rahul from "../images/Rahul.png";
import "./customCss/landing.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const Sliding = () => {
  return (
    <div className="max-w-screen md:px-24 px-10">
      <Swiper
        spaceBetween={20}
        centeredSlides={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        // navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <div className="px-4 py-16  mx-auto  md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="grid gap-8 lg:grid-cols-3   sm:mx-auto lg:max-w-full">
            <SwiperSlide>
              <div className="flex my-4 items-center  justify-center">
                <div>
                  <div className="mb-2">
                    <p className="inline-block text-2xl font-bold leading-5 text-black transition-colors duration-200 hover:text-blue-600">
                      {" "}
                      Got Content Curator Internship
                    </p>
                  </div>
                  <p className="mb-5  text-gray-700">
                    During the ideation stage of HiremeClub in my second year of
                    engineering, Rithik assisted me in securing the Content
                    Curator Internship
                  </p>
                  <div className=" flex items-center justify-center">
                    <a
                      href="https://www.linkedin.com/in/harsh-shivhare-29b912216/"
                      aria-label="Harsh"
                      title="Harsh"
                      className="mr-3"
                    >
                      <img
                        src={Harsh}
                        alt="Harsh Shivhare"
                        className="object-cover w-24 h-24 rounded-full shadow-sm"
                      />
                    </a>
                    <div>
                      <a
                        href="https://www.linkedin.com/in/harsh-shivhare-29b912216/"
                        aria-label="LinkedIn"
                        title="LinkedIn"
                        className="font-semibold text-gray-800 transition-colors duration-200 hover:text-blue-600"
                      >
                        Harsh Shivhare
                      </a>
                      <p className="text-sm font-medium leading-4 text-gray-600">
                        Intern
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <br></br>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex my-4 items-center justify-center">
                <div>
                  <div className="mb-2">
                    <p className="inline-block text-2xl font-bold leading-5 text-black transition-colors duration-200 hover:text-blue-600">
                      {" "}
                      Got YouTube Manager Internship
                    </p>
                  </div>
                  <p className="mb-5  text-gray-700">
                    In my third year of engineering, with HiremeClub in its
                    ideation stage, Rithik played a crucial role in helping me
                    secure the YouTube Manager Internship
                  </p>
                  <div className=" flex items-center justify-center">
                    <a
                      href="https://www.linkedin.com/in/shubham-bhardwaj-b11675216/"
                      aria-label="Shubham "
                      title="Shubham"
                      className="mr-3"
                    >
                      <img
                        src={Shubham}
                        alt="Shubham Bhardwaj"
                        className="object-cover w-24 h-24 rounded-full shadow-sm"
                      />
                    </a>
                    <div>
                      <a
                        href="https://www.linkedin.com/in/shubham-bhardwaj-b11675216/"
                        aria-label="LinkedIn"
                        title="LinkedIn"
                        className="font-semibold text-gray-800 transition-colors duration-200 hover:text-blue-600"
                      >
                        Shubham Bhardwaj
                      </a>
                      <p className="text-sm font-medium leading-4 text-gray-600">
                        Intern
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex my-4 items-center justify-center">
                <div>
                  <div className="mb-2">
                    <p className="inline-block text-2xl font-bold leading-5 text-black transition-colors duration-200 hover:text-blue-600">
                      {" "}
                      Secured an internship in video editing.
                    </p>
                  </div>
                  <p className="mb-5  text-gray-700">
                    In my first year of engineering, during the initial stages
                    of HiremeClub's development, I received support from
                    HiremeClub in obtaining the Video Editing Internship
                  </p>
                  <div className=" flex items-center justify-center">
                    <a
                      href="https://www.linkedin.com/in/rahul-garg-b59751144/"
                      aria-label="Rahul"
                      title="Rahul"
                      className="mr-3"
                    >
                      <img
                        src={Rahul}
                        alt="Rahul Garg"
                        className="object-cover w-24 h-24 rounded-full shadow-sm"
                      />
                    </a>
                    <div>
                      <a
                        href="https://www.linkedin.com/in/rahul-garg-b59751144/"
                        aria-label="LinkedIn"
                        title="LinkedIn"
                        className="font-semibold text-gray-800 transition-colors duration-200 hover:text-blue-600"
                      >
                        Rahul Garg
                      </a>
                      <p className="text-sm font-medium leading-4 text-gray-600">
                        Intern
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </div>
        </div>
      </Swiper>
    </div>
  );
};

export default Sliding;
