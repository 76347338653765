import React, { useEffect, useState } from "react";

const dummy = [
  {
    id: 1,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="lucide lucide-user"
      >
        <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
        <circle cx="12" cy="7" r="4" />
      </svg>
    ),
    title: "Sign In or Register",
    desc: "Employers need to sign in or create an account to access the platform and begin the hiring process.",
    video:
      "https://video-store-hiremeclub.s3.us-west-2.amazonaws.com/freecompress-gif1.mp4",
  },
  {
    id: 2,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="lucide lucide-atom"
      >
        <circle cx="12" cy="12" r="1" />
        <path d="M20.2 20.2c2.04-2.03.02-7.36-4.5-11.9-4.54-4.52-9.87-6.54-11.9-4.5-2.04 2.03-.02 7.36 4.5 11.9 4.54 4.52 9.87 6.54 11.9 4.5Z" />
        <path d="M15.7 15.7c4.52-4.54 6.54-9.87 4.5-11.9-2.03-2.04-7.36-.02-11.9 4.5-4.52 4.54-6.54 9.87-4.5 11.9 2.03 2.04 7.36.02 11.9-4.5Z" />
      </svg>
    ),
    title: "Generate a Test",
    desc: "Navigate to the Test Link page and fill out the necessary form details to create a new test for your candidates.",
    video: "https://video-store-hiremeclub.s3.us-west-2.amazonaws.com/2.mp4",
  },
  {
    id: 3,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="lucide lucide-scroll-text"
      >
        <path d="M15 12h-5" />
        <path d="M15 8h-5" />
        <path d="M19 17V5a2 2 0 0 0-2-2H4" />
        <path d="M8 21h12a2 2 0 0 0 2-2v-1a1 1 0 0 0-1-1H11a1 1 0 0 0-1 1v1a2 2 0 1 1-4 0V5a2 2 0 1 0-4 0v2a1 1 0 0 0 1 1h3" />
      </svg>
    ),
    title: "Upload Candidate Details",
    desc: "Upload an Excel sheet containing candidate information, and customize your candidate list by selecting or removing individuals as needed.",
    video:
      "https://video-store-hiremeclub.s3.us-west-2.amazonaws.com/freecompress-gif3.mp4",
  },
  {
    id: 4,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="lucide lucide-scan-search"
      >
        <path d="M3 7V5a2 2 0 0 1 2-2h2" />
        <path d="M17 3h2a2 2 0 0 1 2 2v2" />
        <path d="M21 17v2a2 2 0 0 1-2 2h-2" />
        <path d="M7 21H5a2 2 0 0 1-2-2v-2" />
        <circle cx="12" cy="12" r="3" />
        <path d="m16 16-1.9-1.9" />
      </svg>
    ),
    title: "Review Test Results",
    desc: "Check candidate test scores on the Candidates page and proceed to schedule interviews with your top choices.",
    video:
      "https://video-store-hiremeclub.s3.us-west-2.amazonaws.com/freecompress-gif4.mp4",
  },
  {
    id: 5,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="lucide lucide-zap"
      >
        <path d="M4 14a1 1 0 0 1-.78-1.63l9.9-10.2a.5.5 0 0 1 .86.46l-1.92 6.02A1 1 0 0 0 13 10h7a1 1 0 0 1 .78 1.63l-9.9 10.2a.5.5 0 0 1-.86-.46l1.92-6.02A1 1 0 0 0 11 14z" />
      </svg>
    ),
    title: "Evaluate Candidate Proof of Work",
    desc: "View each candidate's proof of work, evaluated based on six key parameters, along with their resumes and test details",
    video:
      "https://video-store-hiremeclub.s3.us-west-2.amazonaws.com/freecompress-gif5.mp4",
  },
];

const DemoTutorial = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [progress, setProgress] = useState(0);
  const [isLaptop, setIsLaptop] = useState(window.innerWidth >= 1024);
  const [carouselStep, setCarouselStep] = useState(0);

  const handleCurrentStepChange = () => {
    setCurrentStep((prev) => (prev === 5 ? 1 : prev + 1));
  };

  const handleChangeStep = (id) => {
    setCurrentStep(id);
    setProgress(0);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsLaptop(window.innerWidth >= 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const videoDurations = [2.75, 4.5, 18, 2.5, 3.5]; // video durations in seconds
    const interval = setInterval(() => {
      setProgress((prev) => {
        const duration = videoDurations[currentStep - 1] * 1000; // convert seconds to milliseconds
        const increment = 100 / (duration / 100); // calculate the increment based on the video duration

        if (prev + increment >= 100) {
          handleCurrentStepChange();
          return 0;
        }

        return prev + increment;
      });
    }, 100);

    return () => clearInterval(interval);
  }, [currentStep]);

  const handleNext = () => {
    setCarouselStep((prev) => (prev === 4 ? 4 : prev + 1));
  };

  const handlePrev = () => {
    setCarouselStep((prev) => (prev === 0 ? 0 : prev - 1));
  };
  return (
    <div className="flex flex-col pt-10 pb-4">
      <div className="text-left md:text-center px-4 flex flex-col md:items-center">
        <div>
          <p className="inline-block px-3 py-px text-sm font-bold tracking-wider text-blue-800 rounded-full bg-blue-100">
            DemoTutorial
          </p>
        </div>
        <h2 className="max-w-lg mb-1 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          Step-by-Step Tutorial
        </h2>
        <p className="text-justify md:text-center text-gray-700 md:text-lg md:w-1/2 mb-6">
          Discover how to effectively navigate and utilize our platform with our
          step-by-step tutorial, ensuring you make the most out of every feature
          we offer
        </p>
      </div>
      <div className="h-full flex flex-row mo-wrap">
        {isLaptop && (
          <div className="flex-1 w-full flex justify-center items-center p-8">
            <ul className="w-full h-full relative list-none">
              {dummy.map((ele) => (
                <li
                  key={ele.id}
                  className={`mb-4 pt-2 hover:cursor-pointer w-[47%] ${
                    currentStep === ele.id ? "bg-blue-50" : ""
                  }`}
                  onClick={() => {
                    handleChangeStep(ele.id);
                  }}
                >
                  <div className="flex flex-nowrap items-center gap-4 mb-2">
                    <div
                      className={`pl-4 ${
                        currentStep === ele.id ? "text-blue-600" : ""
                      }`}
                    >
                      {ele.icon}
                    </div>
                    <div className="text-3xl flex flex-col font-sans font-bold">
                      {ele.title}
                    </div>
                  </div>
                  <p
                    className={`pl-14 pr-4 opacity-0 font-medium transition-all duration-1000 ${
                      currentStep !== ele.id ? "hidden" : "opacity-100 h-fit"
                    } mb-4`}
                  >
                    {ele.desc}
                  </p>
                  <div
                    className={`w-full bg-gray-200 rounded-full h-1 bg-transparent ${
                      currentStep !== ele.id ? "hidden" : ""
                    }`}
                  >
                    <div
                      className="bg-gradient-to-r from-cyan-500 to-blue-500 h-1 rounded-full transition-all ease-in-out delay-0"
                      style={{ width: `${progress}%` }}
                    ></div>
                  </div>
                  <div
                    className={`flex-1 flex justify-center items-center ${
                      ele.id !== currentStep ? "hidden h-0" : ""
                    }`}
                  >
                    <video
                      className="w-1/2 object-cover absolute right-0 top-0 h-full border-t-4 border-l-4 border-b-4 rounded-lg border-black"
                      autoPlay
                      loop
                      muted
                    >
                      <source src={ele.video} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
        {!isLaptop && (
          <div className="w-full flex-1 h-full text-black flex flex-nowrap gap-2 md:gap-4 px-2 md:px-4 items-center">
            <button
              onClick={handlePrev}
              className={`${carouselStep === 0 ? "opacity-0" : ""} w-fit h-fit`}
              disabled={carouselStep === 0}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-circle-chevron-left md:w-8 md:h-8"
              >
                <circle cx="12" cy="12" r="10" />
                <path d="m14 16-4-4 4-4" />
              </svg>
            </button>
            <div className="mb-8 bg-sky-200 p-2 rounded-lg">
              <div>
                <h2 className="text-lg text-blue-500 font-sans font-bold">
                  STEP {dummy[carouselStep].id}:
                </h2>
                <div className="w-full h-[13rem] md:h-[25rem] object-cover rounded-lg overflow-hidden">
                  <video
                    className="w-full object-cover h-full rounded-lg"
                    autoPlay
                    loop
                    muted
                    key={dummy[carouselStep].id}
                  >
                    <source src={dummy[carouselStep].video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
              <h1 className="font-sans mt-2 text-2xl md:text-3xl font-semibold md:font-bold text-blue-500">
                {dummy[carouselStep].title}
              </h1>
              <p className="font-medium font-sans md:text-lg mb-2">
                {dummy[carouselStep].desc}
              </p>
            </div>
            <button
              onClick={handleNext}
              className={`${
                carouselStep === dummy.length - 1 ? "opacity-0" : ""
              } w-fit h-fit`}
              disabled={carouselStep === dummy.length - 1}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-circle-chevron-right md:w-8 md:h-8"
              >
                <circle cx="12" cy="12" r="10" />
                <path d="m10 8 4 4-4 4" />
              </svg>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DemoTutorial;
