// PrivateRoute.js
import React from "react";
import { useAuth } from "./AuthProvider";
import { Outlet } from "react-router-dom";
import MagnifyingGlassComponent from "../Loader/MagnifyingGlass";
import { MagnifyingGlass } from "react-loader-spinner";

function PrivateRoute() {
  const { loading, isLoggedIn, user } = useAuth();
  // //console.log(isLoggedIn);
  if (loading) {
    // You can render a loading spinner or a placeholder while authentication is in progress
    return (
      <div className="magnifying-glass-wrapper flex items-center justify-center h-screen">
        <MagnifyingGlass
          visible={true}
          height="160"
          width="160"
          ariaLabel="MagnifyingGlass-loading"
          wrapperStyle={{}}
          wrapperClass="MagnifyingGlass-wrapper"
          glassColor="#c0efff"
          color="#2979FF"
        />
      </div>
    );
  }

  return isLoggedIn && user === "userLoggedIn" ? (
    <Outlet />
  ) : (
    <MagnifyingGlassComponent destination="/login" />
  );
}

export default PrivateRoute;
