import React, { useEffect, useState, useRef } from "react";
import { uploadFile } from "../service/mlAPI";
import { resume } from "../service/api";
import { getSignedUrlResume } from "../service/resumeAPI";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import resumeIcon from "../images/cv.png";
import TestingNavbar from "../components/NavBar/TestingNavbar";

export default function Stage1() {
  const navigate = useNavigate();
  const [url, setUrl] = useState("");
  const [file, setFile] = useState("");
  const newurl = useRef("");
  const getCookieAndRole = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="));
    if (cookieValue) {
      const tokenWithPrefix = cookieValue.split("=")[1];
      if (tokenWithPrefix.length > 0) {
        const role = tokenWithPrefix[0];
        const token = tokenWithPrefix.slice(1); // Remove the prefix
        return { role, token };
      }
    }
    return { role: null, token: null };
  };

  useEffect(() => {
    const getData = async () => {
      let token = getCookieAndRole();
      const response = await getSignedUrlResume(token.token);
      setUrl(response.url);
    };
    getData();
  }, [file]);

  useEffect(() => {
    const getData = async () => {
      try {
        newurl.current = url.split("?")[0];
        // console.log("Frontend me Stage 1:", newurl.current);
        await uploadFile(url, file);
        toast.success("File uploaded successfully");
        // let token = getCookieAndRole();
        // await resume(token.token, newurl);
        navigate("/resume-data", {
          state: { isUploaded: true, newurl: newurl.current },
        });
      } catch (error) {
        // console.error("Error uploading file:", error);
        navigate("/resume-data", { state: { isUploaded: false } });
      }
    };
    file && getData();
  }, [file, url, navigate]);

  const handleResumeClick = () => {
    navigate("/resume-data", { state: { isUploaded: false } });
  };
  const handleFileDrop = (e) => {
    e.preventDefault();
    const selectedFile = e.dataTransfer.files[0];
    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile);
    } else {
      // Display an error message or clear the selected file
      alert("Please select a PDF file.");
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div className="bg-gray-50">
      <TestingNavbar
        showAvatar={true}
        handleLogout={() => navigate("/login")}
      />
      {/*Submit Resume through browse feature */}
      <div className="bg-gray-50 min-h-screen flex items-center justify-center">
        <link
          rel="stylesheet"
          href="https://unpkg.com/flowbite@1.4.4/dist/flowbite.min.css"
        />

        <div className="max-w-2xl mx-auto p-2">
          <div className="flex items-center justify-center w-full">
            <label
              htmlFor="dropzone-file"
              className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-100 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-200 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 p-4"
            >
              <div
                className="flex flex-col items-center justify-center pt-5 pb-6"
                onDrop={handleFileDrop}
                onDragOver={handleDragOver}
              >
                <svg
                  className="w-10 h-10 mb-3 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  ></path>
                </svg>
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">Click to upload</span> or drag
                  and drop
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  PDF format Resume (Max. 5 Mb)
                </p>
              </div>
              <input
                id="dropzone-file"
                type="file"
                className="hidden"
                onChange={(e) => {
                  const selectedFile = e.target.files[0];
                  if (selectedFile && selectedFile.type === "application/pdf") {
                    setFile(selectedFile);
                  } else {
                    // Display an error message or clear the selected file
                    alert("Please select a PDF file.");
                    e.target.value = null; // Clear the file input
                  }
                }}
                accept=".pdf"
              />
            </label>
          </div>

          <p className="mt-5 text-center">
            Kindly utilize the provided DropZone to submit your resume, ensuring
            it is in <span className="font-bold">PDF format </span>with a{" "}
            <span className="font-bold italic">(.pdf) extension.</span>
          </p>
          <div className="flex items-center justify-center mt-4 p-4">
            <div className="w-full flex items-center">
              <div className="flex-grow border-2 border-gray-300 border-dashed mr-4"></div>
              <span className="text-xl text-gray-500 font-semibold">OR</span>
              <div className="flex-grow border-2 border-gray-300 border-dashed ml-4"></div>
            </div>
          </div>

          <div className="flex items-center justify-center">
            <div className="w-full h-56  p-4 rounded-md text-center">
              <div className="rounded-md text-center flex items-center justify-center p-4">
                <img
                  src={resumeIcon}
                  alt="Resume"
                  className="mx-4 mb-2"
                  style={{ width: "40px" }}
                />
                <p className="text-gray-600 mb-4 py-1">Don't Have a Resume ?</p>
              </div>
              <button
                onClick={handleResumeClick}
                className="bg-gray-700 text-white px-4 py-2 rounded-md"
              >
                Build your own resume
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
